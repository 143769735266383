import moment from 'moment-timezone';

export const startOfMonth = (date: Date) => moment.utc(date.toISOString()).startOf('month').toDate();
export const addMonths = (date: Date, months: number) => moment.utc(date.toISOString()).add(months, 'months').toDate();
export const subDays = (date: Date, days: number) => moment.utc(date.toISOString()).subtract(days, 'days').toDate();
export const subYears = (date: Date, years: number) => moment.utc(date.toISOString()).subtract(years, 'years').toDate();
export const isSameDay = (date1: Date, date2: Date | string) => moment.utc(date1.toISOString()).isSame(date2, 'day');
export const startOfWeek = (date: Date) => moment.utc(date.toISOString()).startOf('isoWeek').toDate();
export const addDays = (date: Date, days: number) => moment.utc(date.toISOString()).add(days, 'days').toDate();
export const isSameMonth = (date1: Date, date2: Date) => moment.utc(date1.toISOString()).isSame(date2, 'month');
export const isAfter = (date1: Date, date2: Date | string) => moment.utc(date1.toISOString()).isAfter(date2, 'day');
export const isBefore = (date1: Date, date2: Date) => moment.utc(date1.toISOString()).isBefore(date2, 'day');
export const isSameOrBefore = (date1: Date, date2: Date) =>
  moment.utc(date1.toISOString()).isSameOrBefore(date2, 'day');
export const isSameOrAfter = (date1: Date, date2: Date) => moment.utc(date1.toISOString()).isSameOrAfter(date2, 'day');
export const subMonths = (date: Date, months: number) =>
  moment.utc(date.toISOString()).subtract(months, 'months').toDate();
export const setMonth = (date: Date, month: number) => moment.utc(date.toISOString()).month(month).toDate();
export const setYear = (date: Date, year: number) => moment.utc(date.toISOString()).year(year).toDate();
