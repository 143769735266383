<div class="sensitive-data group flex items-center" *transloco="let t">
  <div matTooltip="{{ tooltip }}">
    <a
      routerLink="/product-center/product360"
      [queryParams]="{ asin: asin }"
      queryParamsHandling="merge"
      target="_blank"
    >
      {{ asin }}
    </a>
  </div>
  <IButton
    class="ml-2 opacity-0 group-hover:opacity-100"
    size="xs"
    color="gray"
    variant="ghost"
    [icon]="ICON_COPY_O"
    (onClick)="copyToClipboard(asin); $event.stopPropagation()"
    tooltipValue="{{ t('asin-link.copy_asin_to_clipboard') }}"
  />
</div>
