import { Currency } from '@front/m19-api-client';

export class CurrencyEx {
  public readonly currencySymbol!: string;
  public readonly currencyRateToEuro!: number;
}

type CurrencyDefinitions = {
  [key in Currency]: CurrencyEx;
};

export const Currencies: CurrencyDefinitions = {
  AED: { currencySymbol: 'د.إ', currencyRateToEuro: 0.23 },
  AUD: { currencySymbol: '$', currencyRateToEuro: 0.64 },
  BRL: { currencySymbol: 'R$', currencyRateToEuro: 0.16 },
  CAD: { currencySymbol: '$', currencyRateToEuro: 0.7 },
  EGP: { currencySymbol: 'E£', currencyRateToEuro: 0.05 },
  EUR: { currencySymbol: '€', currencyRateToEuro: 1 },
  GBP: { currencySymbol: '£', currencyRateToEuro: 1.16 },
  INR: { currencySymbol: '₹', currencyRateToEuro: 0.011 },
  JPY: { currencySymbol: '¥', currencyRateToEuro: 0.0075 },
  MXN: { currencySymbol: '$', currencyRateToEuro: 0.042 },
  PLN: { currencySymbol: 'zł', currencyRateToEuro: 0.22 },
  USD: { currencySymbol: '$', currencyRateToEuro: 0.96 },
  SAR: { currencySymbol: 'ر.س', currencyRateToEuro: 0.23 },
  SEK: { currencySymbol: 'kr', currencyRateToEuro: 0.1 },
  SGD: { currencySymbol: 'S$', currencyRateToEuro: 0.64 },
  TRY: { currencySymbol: '₺', currencyRateToEuro: 0.09 },
  ZAR: { currencySymbol: 'R', currencyRateToEuro: 0.057 },
};
