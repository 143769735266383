<app-stats-overlay>
  <div class="metrics-header mb-4">
    <app-metric-selector
      [data]="globalData"
      [previousPeriodData]="previousPeriodGlobalData"
      (chartMetricsChanges)="selectMetrics($event)"
      [pageMetrics]="$any(CHART_METRICS)"
      [localStorageKey]="localStorageKey"
    ></app-metric-selector>
  </div>

  @if (chartDisplayed) {
    <ICard class="mb-3">
      <ng-template #header>
        <div class="flex min-h-12 items-center justify-between" *transloco="let t">
          <div class="flex flex-1 items-center justify-start gap-2">
            @if (chartDisplayMode === "lineChart") {
              <mat-slide-toggle
                color="primary"
                [checked]="displayEventAnnotation$ | async"
                (change)="toggleEventAnnotation($event)"
                [disabled]="disableEventAnnotation"
                ><span class="events-toggle">{{ t("dashboard.activity_events") }}</span></mat-slide-toggle
              >
              <activity-event-filters
                class="flex-1"
                [displayEventAnnotation]="(displayEventAnnotation$ | async) ?? false"
                [disableEventAnnotation]="disableEventAnnotation"
                [allEventAnnotationTypes]="allEventAnnotationTypes"
                [allUsers]="(allUsers$ | async) ?? []"
                [allStrategies]="(allStrategies$ | async) ?? []"
              />
            }
          </div>

          <div class="flex items-center justify-end gap-x-3">
            @if (chartDisplayMode === "lineChart" && (dateAggregation$ | async)) {
              <app-date-aggreation-switch-button
                class="mx-3"
                [selected]="(dateAggregation$ | async)!"
                (dateAggSelected)="selectAggregation($event)"
                [displayTitle]="false"
              ></app-date-aggreation-switch-button>
            }
            <app-switch-button
              [type]="SwitchButtonType.ICON"
              [options]="chartDisplayModeOptions"
              [buttonIcons]="['icon-[mdi--chart-arc]', 'icon-[mdi--chart-line]']"
              [selected]="chartDisplayMode"
              [buttonTooltips]="[t('overview.display_donut_charts'), t('overview.display_line_chart')]"
              (buttonClicked)="toggleChartDisplayMode($event)"
            />
            <IButton
              variant="ghost"
              color="gray"
              tooltipValue="{{ t('overview.hide_charts') }}"
              (onClick)="toggleChartDisplay(false)"
              [icon]="ICON_CLOSE"
            />
          </div>
        </div>
      </ng-template>
      <ng-template #body>
        <div class="flex h-[25vw] bg-white p-2" *transloco="let t">
          @if (chartDisplayMode === "donuts") {
            <div class="flex flex-1 flex-col items-center justify-center">
              <span class="text-xl font-medium">{{
                t("overview.by_campaign_types", [t("metrics." + mainMetric.id + "_title")])
              }}</span>
              <canvas
                baseChart
                class="max-h-[90%] max-w-[90%]"
                [datasets]="campaignTypeDonutDataSet.chartDataSet"
                [labels]="campaignTypeDonutDataSet.chartLabels"
                [options]="
                  mainMetric.type === RATIO
                    ? campaignTypeDonutDataSet.barChartOptions
                    : campaignTypeDonutDataSet.donutChartOptions
                "
              ></canvas>
            </div>

            <div class="flex flex-1 flex-col items-center justify-center">
              <span class="text-xl font-medium">{{
                t("overview.by_strategy", [t("metrics." + mainMetric.id + "_title")])
              }}</span>
              <canvas
                baseChart
                class="max-h-[90%] max-w-[90%]"
                [datasets]="strategyDonutDataSet.chartDataSet"
                [labels]="strategyDonutDataSet.chartLabels"
                [options]="
                  mainMetric.type === RATIO
                    ? strategyDonutDataSet.barChartOptions
                    : strategyDonutDataSet.donutChartOptions
                "
              ></canvas>
            </div>
          } @else if (chartDisplayMode === "lineChart") {
            <div class="flex h-full w-full justify-center align-middle">
              <canvas
                baseChart
                class="chart"
                [datasets]="globalDataset.chartDataSet"
                type="line"
                [labels]="globalDataset.labels"
                [options]="globalDataset.lineChartOptions"
              ></canvas>
            </div>
          }
        </div>
      </ng-template>
    </ICard>
  }

  <ICard class="group">
    <ng-template #body>
      <div class="invisible mb-2 flex w-full items-center justify-end gap-1 group-hover:visible" *transloco="let t">
        <IButton
          tooltipValue="{{ t('common.restore_default_columns') }}"
          label="{{ t('common.restore_columns') }}"
          color="white"
          (onClick)="restoreDefaultColumns()"
        />
        <app-export-button (export)="exportGridCsv()" tooltipValue="{{ t('common.export_as_csv') }}" />
        @if (!chartDisplayed) {
          <IButton
            color="white"
            tooltipValue="{{ t('common.show_charts') }}"
            (onClick)="toggleChartDisplay(true)"
            [icon]="ICON_CHART_LINE"
            [square]="true"
          />
        }
      </div>
      <app-advertising-stats-grid [gridKey]="STRATEGY_GRID_KEY" />
    </ng-template>
  </ICard>
</app-stats-overlay>
