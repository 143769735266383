import { Injectable } from "@angular/core";
import { AddKeywordTrackingModalComponent } from "@m19-board/keyword-tracker/add-keyword-tracking-modal/add-keyword-tracking-modal.component";
import { AddProductTrackingModalComponent } from "@m19-board/product-tracker/add-product-tracking-modal/add-product-tracking-modal.component";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, catchError, combineLatest, forkJoin, map, Observable, of, switchMap, tap } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AsinRanksEx } from "../keyword-tracker/keyword-tracking-timeline/keyword-tracking-timeline.component";
import moment from "moment-timezone";
import { PALETTE } from "../models/Metric";
import {
  AccountMarketplace,
  KeywordTrackerConfig,
  KeywordTrackingFrequency,
  Marketplace,
  Product,
  ProductTrackerConfig,
  SearchTermAsinRank,
  SearchTermRank,
} from "@front/m19-api-client";
import { Utils, UtilsFractile } from "@front/m19-utils";
import { Catalog, Marketplaces } from "@front/m19-models";
import {
  AccountSelectionService,
  AsinService,
  BrandAnalyticsService,
  KeywordTrackingService,
  RankOption,
  SearchTermAsinRanks,
  UserSelectionService,
} from "@front/m19-services";
import { TranslocoService } from "@jsverse/transloco";

type BucketizedRanking = {
  rank: number;
  bucket: number | undefined;
};

type RankingDataForDate = {
  organic: BucketizedRanking;
  sponsored: BucketizedRanking;
  global: BucketizedRanking;
};

type RankingDataRow = {
  asin: string;
  timeline: {
    [date: string]: RankingDataForDate;
  };
  inCatalog?: boolean;
  childrenRow?: RankingDataRow[];
  parent?: boolean;
};

type KeywordTrackerRow = {
  searchTerm: string;
  sfr: number;
  top10: number;
  top25: number;
  frequency: KeywordTrackingFrequency;
  top10Asins: string[];
  strategyIds: number[];
};

export enum AsinQuickFilter {
  MY_PRODUCT,
  COMPETITORS,
  TOP_10,
}

export interface AsinSelectionFilter {
  quickFilter?: Set<AsinQuickFilter>;
  searchAsin?: string;
}

type KeywordTrackerConfigWithSfr = KeywordTrackerConfig & {
  sfr: number;
};

type AddProductMessage = {
  type: "info" | "success" | "danger";
  message: string;
};

@UntilDestroy()
@Injectable()
export class KeywordTrackerService {
  private rankOption = new BehaviorSubject<RankOption>(RankOption.GLOBAL);
  rankOption$ = this.rankOption.asObservable();

  private selectedSearchTerm = new BehaviorSubject<KeywordTrackerRow | undefined>(undefined);
  selectedSearchTerm$ = this.selectedSearchTerm.asObservable();

  // Search terms data
  keywordTrackerData$ = new BehaviorSubject<KeywordTrackerRow[]>([]);
  private keywordTrackerData?: KeywordTrackerRow[];

  // Mode line chart/table
  private tableView = new BehaviorSubject<boolean>(true);
  tableView$ = this.tableView.asObservable();

  private asinsRank = new BehaviorSubject<Map<string, AsinRanksEx> | undefined>(undefined);
  asinsRank$ = this.asinsRank.asObservable();

  asinRanksFiltered$: Observable<AsinRanksEx[]>;
  asinRankRowData$: Observable<RankingDataRow[]>;

  private dates = new BehaviorSubject<string[]>([]);
  dates$ = this.dates.asObservable();

  private asinSelectionFilter = new BehaviorSubject<AsinSelectionFilter | undefined>(undefined);
  asinSelectionFilter$ = this.asinSelectionFilter.asObservable();

  private graphAsin = new BehaviorSubject<string | undefined>(undefined);
  graphAsin$ = this.graphAsin.asObservable();

  private hiddenAsins = new BehaviorSubject<Set<string>>(new Set<string>());
  hiddenAsins$ = this.hiddenAsins.asObservable();

  private groupByParent = new BehaviorSubject<boolean>(false);
  groupByParent$ = this.groupByParent.asObservable();

  private addProductMessage = new BehaviorSubject<AddProductMessage | undefined>(undefined);
  addProductMessage$ = this.addProductMessage.asObservable();

  private searchTermDataLoading = new BehaviorSubject<boolean>(false);
  searchTermDataLoading$ = this.searchTermDataLoading.asObservable();

  private asinColors = new BehaviorSubject<Map<string, string>>(new Map());
  asinColors$ = this.asinColors.asObservable();

  private accountId?: string;
  private marketplace?: Marketplace;
  private timeZone?: string;
  private minDate?: number;
  private maxDate?: number;
  private catalog?: Catalog;
  private productLastUpdate = new Map<string, Date | undefined>();

  constructor(
    private modalService: BsModalService,
    private keywordTrackingService: KeywordTrackingService,
    private toastrService: ToastrService,
    private asinService: AsinService,
    private accountSelec: AccountSelectionService,
    private brandAnalyticsService: BrandAnalyticsService,
    private userSelecService: UserSelectionService,
    private translocoService: TranslocoService,
  ) {
    this.searchTermDataLoading.next(true);
    // retrieve all search terms data
    this.accountSelec.singleAccountMarketplaceSelection$
      .pipe(
        tap(() => this.searchTermDataLoading.next(true)),
        untilDestroyed(this),
        tap((am: AccountMarketplace) => {
          this.timeZone = Marketplaces[am.marketplace].timeZone;
          this.marketplace = am.marketplace;
          this.accountId = am.accountId;
        }),
        switchMap((am: AccountMarketplace) =>
          combineLatest<[Catalog, KeywordTrackerConfigWithSfr[]]>([
            this.asinService.getCatalog(am.accountId, am.marketplace),
            this.keywordTrackingService
              .getKeywordTrackerConfig(am.accountId, am.marketplace, am.resourceOrganizationId!)
              .pipe(
                switchMap((kwTrackerConfig: KeywordTrackerConfig[]) => {
                  return this.brandAnalyticsService
                    .getLastSearchFrequencyRank(
                      am.marketplace,
                      kwTrackerConfig.map((s) => s.searchTerm!),
                    )
                    .pipe(
                      map((sfr: SearchTermRank[]) => {
                        const sfrMap = new Map<string, number>();
                        for (const r of sfr) {
                          sfrMap.set(r.searchTerm!, r.searchFrequencyRank!);
                        }
                        return kwTrackerConfig.map(
                          (ktc) =>
                            ({
                              ...ktc,
                              sfr: sfrMap.get(ktc.searchTerm!),
                            }) as KeywordTrackerConfigWithSfr,
                        );
                      }),
                    );
                }),
              ),
          ]),
        ),
      )
      .subscribe(([catalog, kwTrackerConfig]) => {
        this.catalog = catalog;
        const keywordData = kwTrackerConfig
          .map((c) => this.buildKeywordTrackerRow(c, catalog))
          .sort((c1, c2) => (c1.sfr ?? Infinity) - (c2.sfr ?? Infinity));
        this.tableView.next(true);
        this.keywordTrackerData$.next(keywordData);
        if (!this.selectedSearchTerm.value) this.selectedSearchTerm.next(keywordData[0]);
      });

    // build asin ranks each time we switch searchterm
    combineLatest([
      this.selectedSearchTerm$,
      this.accountSelec.singleAccountMarketplaceSelection$,
      this.accountSelec.singleAccountMarketplaceSelection$.pipe(
        switchMap((am: AccountMarketplace) => this.asinService.getCatalog(am.accountId, am.marketplace)),
      ),
      this.accountSelec.singleAccountMarketplaceSelection$.pipe(
        switchMap<AccountMarketplace, Observable<Product & { tracked?: boolean }[]>>((am) =>
          this.keywordTrackingService.getProductTrackerConfig(am.accountId, am.marketplace).pipe(
            tap((config: ProductTrackerConfig[]) => {
              config.forEach((c) =>
                this.productLastUpdate.set(c.asin!, c.lastUpdate ? new Date(c.lastUpdate) : undefined),
              );
            }),
            switchMap((productTrackerConfig: ProductTrackerConfig[]) => {
              if (productTrackerConfig.length == 0) {
                return of([]);
              }
              const marketplace = productTrackerConfig[0].marketplace;
              const products: Observable<Product & { tracked?: boolean }[]>[] = productTrackerConfig.map((c) =>
                this.asinService.getProductWithMarketplace(c.asin!, am.marketplace).pipe(
                  switchMap((p: Product) => {
                    if (p.childAsins && p.childAsins.length > 0) {
                      return forkJoin<Product & { tracked?: boolean }[]>(
                        p.childAsins.map((child) => this.asinService.getProductWithMarketplace(child, marketplace!)),
                      );
                    }
                    return of([{ ...p, tracked: true }]);
                  }),
                ),
              );
              if (products.length == 0) {
                return of([]);
              }
              return forkJoin(products);
            }),
            map((products) => products.flat()),
          ),
        ),
      ),
      this.userSelecService.dateRange$,
    ])
      .pipe(
        untilDestroyed(this),
        switchMap(
          ([searchTerm, am, catalog, trackedProducts, dateRange]: [
            KeywordTrackerRow | undefined,
            AccountMarketplace,
            Catalog,
            (Product & { tracked?: boolean | undefined })[],
            string[],
          ]) => {
            return this.keywordTrackingService
              .getKeywordTrackingTimeline(searchTerm?.searchTerm ?? "", am.marketplace)
              .pipe(
                map((timeline: SearchTermAsinRanks) => {
                  const minDate: Date = new Date(dateRange[0]);
                  minDate.setHours(0, 0, 0);
                  const maxDate: Date = new Date(dateRange[1]);
                  maxDate.setHours(23, 59, 59);
                  const minDateTs = minDate.getTime() / 1000;
                  const maxDateTs = maxDate.getTime() / 1000;
                  const asinRanks = new Map<string, AsinRanksEx>();
                  const trackedAsins = new Set(trackedProducts.map((c) => c.asin));
                  const top10Asins = searchTerm?.top10Asins;
                  // take only ASIN in catalog or tracked ASIN or ASINs in top 10
                  // also only take asins with data
                  for (const asin of timeline.asinRanks.keys()) {
                    if (catalog.contains(asin)) {
                      const asinTimeline = timeline
                        .asinRanks!.get(asin)!
                        .filter((rank) => rank.timestamp! >= minDateTs && rank.timestamp! <= maxDateTs);
                      if (asinTimeline.length == 0) {
                        continue;
                      }
                      const parentAsin = catalog.getParentAsin(asin) ?? asin;
                      asinRanks.set(asin, {
                        asin: asin,
                        ranks: asinTimeline,
                        hasParent: parentAsin != asin,
                        inCatalog: true,
                        isTop10: !!top10Asins?.includes(asin),
                        childAsins: [],
                      });
                      // set data for the parent ASIN
                      if (parentAsin != asin) {
                        if (!asinRanks.has(parentAsin)) {
                          asinRanks.set(parentAsin, {
                            asin: parentAsin,
                            ranks: [...asinTimeline],
                            hasParent: false,
                            inCatalog: true,
                            isTop10: !!top10Asins?.includes(asin),
                            childAsins: [asin],
                          });
                        } else {
                          asinRanks.get(parentAsin)?.ranks?.push(...asinTimeline);
                          asinRanks.get(parentAsin)?.ranks?.sort((a, b) => a.timestamp! - b.timestamp!);
                          asinRanks.get(parentAsin)?.childAsins.push(asin);
                          asinRanks.get(parentAsin)!.isTop10! ||= !!top10Asins?.includes(asin);
                        }
                      }
                    } else if (trackedAsins.has(asin)) {
                      const asinTimeline = timeline
                        .asinRanks!.get(asin)!
                        .filter((rank) => rank.timestamp! >= minDateTs && rank.timestamp! <= maxDateTs);
                      if (asinTimeline.length == 0) {
                        continue;
                      }
                      const product = trackedProducts.find((p) => p.asin == asin);
                      const parentAsin =
                        product?.parentAsins && product.parentAsins.length > 0 ? product.parentAsins[0] : asin;
                      asinRanks.set(asin, {
                        asin: asin,
                        ranks: asinTimeline,
                        hasParent: parentAsin != asin,
                        inCatalog: false,
                        isTop10: !!top10Asins?.includes(asin),
                        childAsins: [],
                      });
                      if (parentAsin != asin) {
                        if (!asinRanks.has(parentAsin)) {
                          asinRanks.set(parentAsin, {
                            asin: parentAsin,
                            ranks: [...asinTimeline.map((a) => ({ ...a }))],
                            hasParent: false,
                            inCatalog: false,
                            isTop10: !!top10Asins?.includes(asin),
                            childAsins: [asin],
                          });
                        } else {
                          // asinRanks.get(parentAsin).ranks.push(...asinTimeline);
                          // add the asin timeline to the parent timeline
                          // on duplicated timestamps, take the minimum ranks
                          const parentTimeline = asinRanks.get(parentAsin)?.ranks;
                          for (const r of asinTimeline) {
                            const existingRank = parentTimeline?.find((x) => x.timestamp == r.timestamp);
                            if (existingRank) {
                              // take the min ranks
                              existingRank.organic = this.minRank(r.organic!, existingRank.organic);
                              existingRank.sp = this.minRank(r.sp!, existingRank.sp);
                              existingRank.global = this.minRank(r.global!, existingRank.global);
                            } else {
                              parentTimeline?.push({ ...r });
                            }
                          }

                          asinRanks.get(parentAsin)?.ranks?.sort((a, b) => a.timestamp! - b.timestamp!);
                          asinRanks.get(parentAsin)?.childAsins.push(asin);
                        }
                      }
                    } else if (top10Asins?.includes(asin)) {
                      const asinTimeline = timeline
                        .asinRanks!.get(asin)!
                        .filter((rank) => rank.timestamp! >= minDateTs && rank.timestamp! <= maxDateTs);
                      if (asinTimeline.length == 0) {
                        continue;
                      }
                      asinRanks.set(asin, {
                        asin: asin,
                        ranks: asinTimeline,
                        hasParent: false,
                        inCatalog: false,
                        isTop10: true,
                        childAsins: [],
                      });
                    }
                  }
                  return asinRanks;
                }),
                catchError((e) => {
                  this.toastrService.error(
                    this.translocoService.translate("keyword-tracker.fail_to_load_keyword_timeline", [
                      searchTerm?.searchTerm,
                      e,
                    ]),
                  );
                  return of(new Map<string, AsinRanksEx>());
                }),
              );
          },
        ),
      )
      .subscribe((asinRanks: Map<string, AsinRanksEx>) => {
        this.searchTermDataLoading.next(false);

        // if the selected graph ASIN is not in the new asin rank list, reset
        this.asinsRank.next(asinRanks);
      });

    // update asin ranks on filter
    this.asinRanksFiltered$ = combineLatest([this.asinsRank$, this.asinSelectionFilter$]).pipe(
      untilDestroyed(this),
      map(([ranks, filter]) => {
        const res: Set<AsinRanksEx> = new Set();
        if (ranks) {
          Array.from(ranks?.values()).forEach((r, _i, array) => {
            if (!filter || filter.quickFilter?.size === 0) return true;

            let passFilter = false;

            if (filter?.quickFilter?.has(AsinQuickFilter.MY_PRODUCT)) passFilter ||= r.inCatalog;
            if (filter?.quickFilter?.has(AsinQuickFilter.COMPETITORS)) passFilter ||= !r.inCatalog;
            if (filter?.quickFilter?.has(AsinQuickFilter.TOP_10)) passFilter ||= r.isTop10;

            if (filter?.searchAsin && filter.searchAsin.length)
              passFilter &&= r.asin?.search(new RegExp(filter.searchAsin, "i")) !== -1;

            if (passFilter) {
              if (r.hasParent) {
                const parent = this.catalog?.getParentAsin(r.asin!);
                const parentData = array.find((r) => r.asin === parent);
                if (parentData) res.add(parentData);
              }
              res.add(r);
            }
            return false;
          });
        }
        return [...res].sort(
          (a, b) =>
            (a.ranks![a.ranks!.length - 1].global ?? Infinity) - (b.ranks![b.ranks!.length - 1].global ?? Infinity),
        );
      }),
      tap((ranks) => {
        const displayedAsins = ranks.map((r) => r.asin);
        if (displayedAsins?.indexOf(this.graphAsin.value) === -1) this.graphAsin.next(undefined);
      }),
    );

    // compute table data here to keep the asin order in the asin selection component
    this.asinRankRowData$ = this.asinRanksFiltered$.pipe(
      untilDestroyed(this),
      switchMap((ranks: AsinRanksEx[]) => {
        this.minDate = undefined;
        this.maxDate = undefined;
        const data: RankingDataRow[] = [];

        // all ranks to compute buckets
        const organicRanks: number[] = [];
        const sponsoredRanks: number[] = [];
        const globalRanks: number[] = [];

        for (const r of ranks) {
          // if product doest not have parent ASIN
          if (!r.hasParent) {
            const parent: RankingDataRow = { asin: r.asin!, timeline: {}, parent: true, childrenRow: [] };
            this.buildRowTimeline(parent, r.ranks!, organicRanks, sponsoredRanks, globalRanks);
            // if it does not have children, it is not a parent ASIN
            let children = r.childAsins;
            if (r.childAsins?.length === 0) {
              children = [r.asin!];
            }

            for (const c of children) {
              const childData = ranks.filter((r) => r.asin === c)[0];
              if (!childData) continue;
              const childRow = { asin: c, timeline: {}, parent: false };
              this.buildRowTimeline(childRow, childData.ranks!, organicRanks, sponsoredRanks, globalRanks);
              parent.childrenRow?.push(childRow);
            }
            data.push(parent);
          }
        }
        this.dates.next(Utils.getDateRange(this.minDate! * 1000, this.maxDate! * 1000, this.timeZone));

        const organicDeciles = UtilsFractile.getFractileArray(organicRanks, 10);
        const sponsoredDeciles = UtilsFractile.getFractileArray(sponsoredRanks, 10);
        const globalDeciles = UtilsFractile.getFractileArray(globalRanks, 10);
        for (const row of data) {
          this.computeRankBucket(row, organicDeciles, sponsoredDeciles, globalDeciles);
          for (const c of row.childrenRow ?? []) {
            this.computeRankBucket(c, organicDeciles, sponsoredDeciles, globalDeciles);
          }
        }
        this.computeAsinColors(data);
        return of(data);
      }),
    );
  }

  computeAsinColors(data: RankingDataRow[]) {
    let i = 0;
    const colors = new Map<string, string>();

    for (const a of data) {
      colors.set(a.asin, PALETTE[i]);
      i++;
      for (const c of a?.childrenRow ?? []) {
        colors.set(c.asin, PALETTE[i]);
        i++;
      }
    }
    this.asinColors.next(colors);
  }

  computeRankBucket(r: RankingDataRow, orgDeciles: number[], spDeciles: number[], gDeciles: number[]) {
    for (const [, rank] of Object.entries(r.timeline)) {
      rank.organic.bucket = 9 - UtilsFractile.getFractileBucket(orgDeciles, rank.organic.rank)!;
      rank.sponsored.bucket = 9 - UtilsFractile.getFractileBucket(spDeciles, rank.sponsored.rank)!;
      rank.global.bucket = 9 - UtilsFractile.getFractileBucket(gDeciles, rank.global.rank)!;
    }
  }

  toggleHiddenAsins(asin: string) {
    const hiddenAsins = this.hiddenAsins.value;
    if (hiddenAsins.has(asin)) hiddenAsins.delete(asin);
    else hiddenAsins.add(asin);
    this.hiddenAsins.next(hiddenAsins);
  }

  setGroupByParent(groupByParent: boolean) {
    if (groupByParent === undefined) this.groupByParent.next(!this.groupByParent.value);
    else this.groupByParent.next(groupByParent);
  }

  setAsinSelectionFilter(filter: AsinSelectionFilter) {
    if (!filter?.quickFilter || !filter?.quickFilter.size) localStorage.removeItem("KeywordTrackerFilter");
    localStorage.setItem("KeywordTrackerFilter", JSON.stringify([...(filter?.quickFilter?.values() ?? [])]));

    this.asinSelectionFilter.next(filter);
  }

  toggleGraphAsin(asin: string, force = false) {
    if (this.hiddenAsins.value.has(asin)) return; // Prevent toggle hidden ASIN
    if (force) this.graphAsin.next(asin);
    else if (this.graphAsin.value === asin) this.graphAsin.next(undefined);
    else this.graphAsin.next(asin);
  }

  setTableView(set: boolean) {
    this.tableView.next(set);
  }

  setSelectedSearchTerm(searchTerm: KeywordTrackerRow) {
    this.selectedSearchTerm.next(searchTerm);
  }

  setKeywordTrackerData(data: KeywordTrackerRow[]) {
    this.keywordTrackerData = data;
  }

  setRankOption(rank: RankOption) {
    this.rankOption.next(rank);
  }

  private buildRowTimeline(
    row: RankingDataRow,
    ranks: SearchTermAsinRank[],
    organicRanks: number[],
    sponsoredRanks: number[],
    globalRanks: number[],
  ) {
    const organicRankPerDay = new Map<string, number[]>();
    const sponsoredRankPerDay = new Map<string, number[]>();
    const globalRankPerDay = new Map<string, number[]>();
    for (const rank of ranks) {
      if (this.minDate === undefined || rank.timestamp! < this.minDate) this.minDate = rank.timestamp;
      if (this.maxDate === undefined || rank.timestamp! > this.maxDate) this.maxDate = rank.timestamp;
      const day = moment
        .utc(rank.timestamp! * 1000)
        .tz(this.timeZone!)
        .startOf("day")
        .format();
      if (!organicRankPerDay.has(day)) {
        organicRankPerDay.set(day, []);
        sponsoredRankPerDay.set(day, []);
        globalRankPerDay.set(day, []);
      }
      if (rank.organic! > 0) {
        organicRankPerDay.get(day)?.push(rank.organic!);
      }
      if (rank.sp! > 0) {
        sponsoredRankPerDay.get(day)?.push(rank.sp!);
      }
      if (rank.global! > 0) {
        globalRankPerDay.get(day)?.push(rank.global!);
      }
    }
    // aggregate the median per day
    for (const day of organicRankPerDay.keys()) {
      const organicMedian = Utils.median(organicRankPerDay.get(day)!, (x) => x)!;
      const sponsoredMedian = Utils.median(sponsoredRankPerDay.get(day)!, (x) => x)!;
      const globalMedian = Utils.median(globalRankPerDay.get(day)!, (x) => x)!;
      if (organicMedian > 0) {
        organicRanks.push(organicMedian);
      }
      if (sponsoredMedian > 0) {
        sponsoredRanks.push(sponsoredMedian);
      }
      if (globalMedian > 0) {
        globalRanks.push(globalMedian);
      }
      row.timeline[day] = {
        organic: { rank: organicMedian, bucket: undefined },
        sponsored: { rank: sponsoredMedian, bucket: undefined },
        global: { rank: globalMedian, bucket: undefined },
      };
    }
  }

  private buildKeywordTrackerRow(kwTrackerConfig: KeywordTrackerConfigWithSfr, catalog: Catalog): KeywordTrackerRow {
    let i = 1;
    let top10 = 0;
    let top25 = 0;
    const top10Asins = [];
    for (const topAsin of kwTrackerConfig.topAsins ?? []) {
      if (i <= 10) {
        top10Asins.push(topAsin);
      }
      if (catalog.childAsins.has(topAsin)) {
        if (i <= 10) {
          top10++;
        }
        if (i <= 25) {
          top25++;
        }
      }
      i++;
      if (i > 25) {
        break;
      }
    }
    return {
      searchTerm: kwTrackerConfig.searchTerm!,
      top10,
      top25,
      sfr: kwTrackerConfig.sfr,
      frequency: kwTrackerConfig.frequency!,
      strategyIds: kwTrackerConfig.strategyIds!,
      top10Asins,
    };
  }

  trackNewProduct(asinToTrack: string): void {
    if (this.catalog?.contains(asinToTrack)) {
      // - product is in catalog and has data
      if (this.asinsRank.value?.get(asinToTrack)) {
        this.addProductMessage.next({
          message: `Your product ${asinToTrack} is already tracked for this search term and available in the ASIN list`,
          type: "success",
        });
      } else {
        // - product is in catalog but have not been detected yet
        this.addProductMessage.next({
          message: `Your product ${asinToTrack} is already tracked but has not been detected for this search term. It will be automatically displayed when it will be detected.`,
          type: "info",
        });
      }
    } else if (this.asinsRank.value?.get(asinToTrack)) {
      this.addProductMessage.next({
        message: `This competitor ${asinToTrack} is already tracked and available in the asin list below`,
        type: "info",
      });
    }
    // competitor product
    else if (this.productLastUpdate.has(asinToTrack)) {
      const today = new Date();
      const productTrackedDay = this.productLastUpdate.get(asinToTrack);
      const oneDayTime = 24 * 60 * 60 * 1000;

      // - competitor not detected for this search term
      if (!productTrackedDay || today.getTime() - productTrackedDay.getTime() > oneDayTime) {
        this.addProductMessage.next({
          message: `The competitor ${asinToTrack} product is already tracked but has not been detected for this search term. It will be automatically displayed when it will be detected.`,
          type: "info",
        });
      } else {
        // - competitor recently added
        this.addProductMessage.next({
          message: `The competitor ${asinToTrack} product was added less than a day ago. It will be available soon.`,
          type: "info",
        });
      }
    } else {
      // - new competitor tracked
      const productConfig: ProductTrackerConfig[] = [
        {
          accountId: this.accountId,
          marketplace: this.marketplace,
          asin: asinToTrack,
        },
      ];

      this.keywordTrackingService
        .addProductTrackerConfig(this.accountId!, this.marketplace!, productConfig)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (c: ProductTrackerConfig[]) => {
            this.addProductMessage.next({
              message: `The competitor ${asinToTrack} product has just been added. It will be available within than 24h.`,
              type: "success",
            });
          },
          error: (e) => {
            this.toastrService.error(
              this.translocoService.translate("keyword-tracker.error_when_setting_product_tracking", [e]),
              this.translocoService.translate("keyword-tracker.tracking_new_products"),
            );
          },
        });
    }
  }

  getKeywordTrackerData() {
    return this.keywordTrackerData;
  }

  openAddKwTrackingModal(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    keywords: string[] = [],
    onSuccessMessage = "",
  ) {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId,
        marketplace,
        organizationId,
        keywords,
      },
      class: "modal-xl",
    };
    const modalRef = this.modalService.show(AddKeywordTrackingModalComponent, modalOptions);
    const subscription = modalRef.content?.save
      .pipe(
        switchMap((config) =>
          this.keywordTrackingService.addKeywordTrackerConfig(accountId, marketplace, organizationId, config),
        ),
      )
      .subscribe({
        next: (c: KeywordTrackerConfig[]) => {
          const message = this.translocoService.translate("keyword-tracker.start_tracking_onsuccessmessage", [
            c.length,
            onSuccessMessage,
          ]);

          this.toastrService.success(message, this.translocoService.translate("keyword-tracker.new_tracked_keywords"));
          subscription?.unsubscribe();
        },
        error: (e) => {
          this.toastrService.error(
            this.translocoService.translate("keyword-tracker.error_when_setting_keyword_tracking_e", [e]),
            this.translocoService.translate("keyword-tracker.keyword_tracking_error"),
          );
          subscription?.unsubscribe();
        },
      });
  }

  openAddProductTrackingModal(
    accountId: string,
    marketplace: Marketplace,
    catalog: Catalog,
    productTrackerConfig: ProductTrackerConfig[],
    products: string[] = [],
  ) {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: accountId,
        marketplace: marketplace,
        catalog: catalog,
        productTrackerConfig,
        products,
      },
      class: "modal-xl",
    };
    const modalRef = this.modalService.show(AddProductTrackingModalComponent, modalOptions);
    const subscription = modalRef.content?.save
      .pipe(switchMap((config) => this.keywordTrackingService.addProductTrackerConfig(accountId, marketplace, config)))
      .subscribe({
        next: (c: ProductTrackerConfig[]) => {
          this.toastrService.success(
            this.translocoService.translate("keyword-tracker.start_tracking_new_products", [c.length]),
            this.translocoService.translate("keyword-tracker.new_products_tracked"),
          );
          subscription?.unsubscribe();
        },
        error: (e) => {
          this.toastrService.error(
            this.translocoService.translate("keyword-tracker.error_when_setting_product_tracking", [e]),
            this.translocoService.translate("keyword-tracker.product_tracking_error"),
          );
          subscription?.unsubscribe();
        },
      });
  }

  private minRank(r1: number | undefined, r2: number | undefined) {
    if (!r1) {
      return r2;
    }
    if (!r2) {
      return r1;
    }
    return r1 < r2 ? r1 : r2;
  }
}
