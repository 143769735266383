<ng-container *transloco="let t">
  <div class="w-[30vw]">
    <algo-mode-selection
      [selectedAlgoModeConfig]="algoModeConfig()"
      [targetOnlyMode]="true"
      [disabled]="!!data.readonly"
      [strategyId]="data.strategy.strategyId"
      (algoModeConfig)="algoModeConfig.set($event)"
      (onDailyBudgetDeleted)="deleteDailyBudget()"
    />

    <div class="flex justify-between pt-4">
      <IButton label="{{ t('common.cancel') }}" color="white" (onClick)="ref.close()" [disabled]="updateLoading" />
      <IButton
        testid="btn-confirm"
        class="w-2/4"
        label="{{ t('common.update') }}"
        type="submit"
        [loading]="updateLoading"
        [disabled]="disabled()"
        (onClick)="update()"
        [block]="true"
      />
    </div>
  </div>
</ng-container>
