import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { CommonModule } from "@angular/common";
import { Component, inject, Input, signal } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AccountMarketplace, CampaignType } from "@front/m19-api-client";
import { AccountSelectionService, TacosStrategiesService } from "@front/m19-services";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { switchMap } from "rxjs";
import { CAMPAIGN_TYPE_COLOR, CAMPAIGN_TYPE_NAME } from "../../insights/advertising-stats/advertising-stats.component";

@UntilDestroy()
@Component({
  selector: "app-campaign-type-badge",
  standalone: true,
  imports: [CommonModule, MatTooltipModule, TranslocoRootModule],
  template: ` <ng-container *transloco="let t">
    <div class="inline-flex h-full w-full items-center justify-center gap-2">
      @if (!useTacosBadge) {
        @for (ct of campaignTypes(); track ct) {
          <span
            [matTooltip]="t(CAMPAIGN_TYPE_NAME[ct])"
            [ngStyle]="{ 'background-color': CAMPAIGN_TYPE_COLOR[ct] }"
            class="badge rounded-full text-white"
            >{{ ct }}
          </span>
        }
      } @else {
        <span class="badge rounded-full bg-orange-500 text-white">TACOS </span>
      }
    </div>
  </ng-container>`,
  styleUrls: ["./campaign-type-badge.component.scss"],
})
export class CampaignTypeBadgeComponent implements ICellRendererAngularComp {
  private readonly tacosService = inject(TacosStrategiesService);
  private readonly accountService = inject(AccountSelectionService);

  @Input() set campaignType(campaignType: CampaignType | undefined) {
    if (campaignType) this.campaignTypes.set([campaignType]);
  }

  @Input() tacosSpStrategyId: number | undefined;
  @Input() useTacosBadge = false;
  campaignTypes = signal<CampaignType[] | undefined>(undefined);

  protected readonly CAMPAIGN_TYPE_NAME = CAMPAIGN_TYPE_NAME;
  protected readonly CAMPAIGN_TYPE_COLOR = CAMPAIGN_TYPE_COLOR;

  agInit(
    params: ICellRendererParams & { campaignType: string; tacosSpStrategyId?: number; useTacosBadge?: boolean },
  ): void {
    this.campaignTypes.set([params.campaignType as CampaignType]);
    this.tacosSpStrategyId = params.tacosSpStrategyId;
    this.useTacosBadge = !!params.useTacosBadge;
    if (this.tacosSpStrategyId && !this.useTacosBadge) {
      this.accountService.singleAccountMarketplaceSelection$
        .pipe(
          switchMap((am: AccountMarketplace) => this.tacosService.getTacosStrategyIndex(am.accountId, am.marketplace)),
          untilDestroyed(this),
        )
        .subscribe((tacosStrategyIndex) => {
          const tacosStrategy = Array.from(tacosStrategyIndex.values()).find(
            (t) => t.spStrategyId === this.tacosSpStrategyId,
          );
          this.campaignTypes.set([]);
          if (tacosStrategy) {
            if (tacosStrategy.spStrategyId) this.campaignTypes.update((ct) => [...ct!, CampaignType.SP]);
            if (tacosStrategy.sbStrategyId) this.campaignTypes.update((ct) => [...ct!, CampaignType.SB]);
            if (tacosStrategy.sdStrategyId) this.campaignTypes.update((ct) => [...ct!, CampaignType.SD]);
          }
        });
    }
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }
}
