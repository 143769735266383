import { AdStatsEx, InventoryStats, OrderStats } from "@front/m19-models";

import {
  AD_SALES,
  COST,
  Metric,
  MetricCategory,
  MetricType,
  percent,
  RatioMetric,
  RegisteredMetric,
  SummableMetric,
  SupportedAccountType,
} from "@front/m19-metrics";

import { AccountType, AmcSponsoredAdsAndDspOverlap } from "@front/m19-api-client";
import { AdStatsWithTargetHistory, StrategyTargetHistory } from "@front/m19-services";
import { AggregationFunction, composeAggregationFunctions } from "@front/m19-utils";
import { PALETTE, printCurrency, StrategyStats, SumMetric } from "./Metric";

export const TARGET_TACOS = new (class extends RegisteredMetric<StrategyTargetHistory> {
  public value(d: StrategyTargetHistory) {
    return d?.tacosTarget;
  }

  public valueForCsv(d: StrategyTargetHistory): string {
    const value = this.value(d);
    if (value && isFinite(value)) {
      return value.toFixed(2);
    }
    return "-";
  }

  public format(d: StrategyTargetHistory | number, locale: string | undefined) {
    const data = typeof d === "number" ? d : d?.tacosTarget === null ? undefined : d?.tacosTarget;
    return percent(data, locale, "1.0-0");
  }

  public formatSmall(d: number | StrategyTargetHistory, locale?: string) {
    return this.format(d, locale);
  }

  public compare(d1: StrategyTargetHistory, d2: StrategyTargetHistory) {
    return d2.tacosTarget! - d1.tacosTarget!;
  }
})({
  id: "TARGET_TACOS",
  title: "Target TACOS",
  titleSmall: "Target TACOS",
  category: MetricCategory.STRATEGY_CONFIG,
  type: MetricType.RATIO,
  color: "rgba(255, 150, 150, 1)",
  graphTension: 0,
  graphBorderDash: [10, 5],
  tooltip: "Target TACOS",
});

export const TARGET_ACOS = new (class extends RegisteredMetric<AdStatsWithTargetHistory> {
  public value(d: AdStatsWithTargetHistory) {
    return d?.acosTarget;
  }

  public valueForCsv(d: AdStatsWithTargetHistory): string {
    const value = this.value(d);
    if (value && isFinite(value)) {
      return value.toFixed(2);
    }
    return "-";
  }

  public format(d: AdStatsWithTargetHistory | number, locale: string | undefined) {
    const data = typeof d === "number" ? d : d?.acosTarget === null ? undefined : d?.acosTarget;
    return percent(data, locale, "1.0-0");
  }

  public formatSmall(d: number | AdStatsWithTargetHistory, locale?: string) {
    return this.format(d, locale);
  }

  public compare(d1: AdStatsWithTargetHistory, d2: AdStatsWithTargetHistory) {
    return d2.acosTarget! - d1.acosTarget!;
  }
})({
  id: "TARGET_ACOS",
  title: "Target ACOS",
  titleSmall: "Target ACOS",
  category: MetricCategory.STRATEGY_CONFIG,
  type: MetricType.RATIO,
  color: "rgba(255, 150, 150, 1)",
  graphTension: 0,
  graphBorderDash: [10, 5],
  tooltip: "Target Advertising Cost of Sales",
});

export const MIN_DAILY_SPEND = new (class extends RegisteredMetric<AdStatsWithTargetHistory> {
  public value(d: AdStatsWithTargetHistory) {
    return d?.minDailySpend;
  }

  public valueForCsv(d: StrategyStats): string {
    const value = this.value(d);
    if (value && isFinite(value)) {
      return value.toFixed(2);
    }
    return "-";
  }

  public format(d: AdStatsWithTargetHistory | number, locale: string | undefined, currency: string | undefined) {
    const data = typeof d === "number" ? d : d?.minDailySpend === null ? undefined : d?.minDailySpend;
    return printCurrency(data, locale ?? "", currency ?? "", "1.0-2");
  }

  public formatSmall(d: number | StrategyStats, locale?: string, currency?: string) {
    return this.format(d, locale, currency);
  }

  public compare(d1: AdStatsWithTargetHistory, d2: AdStatsWithTargetHistory) {
    return d2.minDailySpend! - d1.minDailySpend!;
  }
})({
  id: "MIN_DAILY_SPEND",
  title: "Min Daily Spend",
  titleSmall: "Min Daily Spend",
  category: MetricCategory.STRATEGY_CONFIG,
  type: MetricType.SUMMABLE,
  color: "rgba(0, 91, 141, 1)",
  graphTension: 0,
  graphBorderDash: [10, 5],
  tickDisplay: false,
  tooltip: "Minimum daily spend",
}) as unknown as Metric<AdStatsWithTargetHistory>;

export const DAILY_BUDGET = new (class extends RegisteredMetric<AdStatsWithTargetHistory> {
  public value(d: AdStatsWithTargetHistory) {
    return d?.dailyBudget;
  }

  public valueForCsv(d: AdStatsWithTargetHistory): string {
    const value = this.value(d);
    if (value && isFinite(value)) {
      return value.toFixed(2);
    }
    return "-";
  }

  public format(d: AdStatsWithTargetHistory | number, locale: string | undefined, currency: string | undefined) {
    const data = typeof d === "number" ? d : d?.dailyBudget;
    return printCurrency(data, locale, currency, "1.0-2");
  }

  public formatSmall(d: number | AdStatsWithTargetHistory, locale?: string, currency?: string) {
    return this.format(d, locale, currency);
  }

  public compare(d1: AdStatsWithTargetHistory, d2: AdStatsWithTargetHistory) {
    return d2.dailyBudget! - d1.dailyBudget!;
  }
})({
  id: "DAILY_BUDGET",
  title: "Daily Budget",
  titleSmall: "Daily Budget",
  category: MetricCategory.STRATEGY_CONFIG,
  type: MetricType.SUMMABLE,
  color: "rgba(195, 97, 99, 1)",
  graphTension: 0,
  graphBorderDash: [10, 5],
  tickDisplay: false,
  tooltip: "Daily budget",
});

export const MONTHLY_BUDGET = new (class extends RegisteredMetric<AdStatsWithTargetHistory> {
  public value(d: AdStatsWithTargetHistory) {
    return d?.monthlyBudget ? d?.computedDailyBudget : undefined;
  }

  public valueForCsv(d: AdStatsWithTargetHistory): string {
    const value = this.value(d);
    if (value && isFinite(value)) {
      return value.toFixed(2);
    }
    return "-";
  }

  public format(d: AdStatsWithTargetHistory | number, locale: string | undefined, currency: string | undefined) {
    const data =
      typeof d === "number" ? d : d?.monthlyBudget && d?.computedDailyBudget ? d.computedDailyBudget : undefined;
    return printCurrency(data, locale, currency, "1.0-2");
  }

  public formatSmall(d: number | AdStatsWithTargetHistory, locale?: string, currency?: string) {
    return this.format(d, locale, currency);
  }

  public compare(d1: AdStatsWithTargetHistory, d2: AdStatsWithTargetHistory) {
    return d2.computedDailyBudget! - d1.computedDailyBudget!;
  }
})({
  id: "MONTHLY_BUDGET",
  title: "Monthly Budget (per day)",
  titleSmall: "Monthly Budget (per day)",
  category: MetricCategory.STRATEGY_CONFIG,
  type: MetricType.SUMMABLE,
  color: "rgba(23, 190, 187, 1)",
  graphTension: 0,
  graphBorderDash: [10, 5],
  tickDisplay: false,
  tooltip: "Monthly budget",
});

export const TOTAL_SALES: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "TOTAL_SALES",
  field: "allSalesExTax",
  title: "Sales",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[8],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: true,
  requireSellingPartnerAccess: true,
  tooltip: "Sum of organic and ad sales",
});

export const TOTAL_ORDERS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "TOTAL_ORDERS",
  field: "allOrderedUnits",
  title: "Units Sold",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[10],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  requireSellingPartnerAccess: true,
  tooltip: "Number of items sold through organic and ad sales",
});
export const VENDOR_SHIPPED_COGS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "VENDOR_SHIPPED_COGS",
  field: "vendorShippedCogs",
  title: "Shipped Cogs",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[16],
  supportedAccountType: SupportedAccountType.VENDOR,
  currency: true,
  requireSellingPartnerAccess: true,
  tooltip: "Sum of shipped cogs",
  mustApplyEvolutionStyle: false,
});

export const VENDOR_CUSTOMER_RETURNS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "VENDOR_CUSTOMER_RETURNS",
  field: "vendorCustomerReturns",
  title: "Customer Returns",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[18],
  supportedAccountType: SupportedAccountType.VENDOR,
  currency: false,
  requireSellingPartnerAccess: true,
  tooltip: "Sum of customer returns",
  higherIsBetter: false,
});
export const ORDERS_7D: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "ORDERS_7D",
  field: "orders7d",
  title: "Orders Last 7 Days",
  category: MetricCategory.INVENTORY,
  color: PALETTE[0],
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Orders 7d",
  tooltip: "Orders over the last 7 days",
  requireSellingPartnerAccess: true,
});
export const ORDERS_30D: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "ORDERS_30D",
  field: "orders30d",
  title: "Orders Last 30 Days",
  category: MetricCategory.INVENTORY,
  color: PALETTE[1],
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Orders 30d",
  tooltip: "Orders over the last 30 days",
  requireSellingPartnerAccess: true,
});
export const FULFILLABLE_STOCK: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "FULFILLABLE_STOCK",
  field: "fulfillableQuantity",
  title: "FBA Available Inventory",
  category: MetricCategory.INVENTORY,
  color: PALETTE[0],
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Avail.",
  tooltip: "Available inventory fulfilled by Amazon",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});
export const FULFILLABLE_STOCK_VALUE: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "FULFILLABLE_STOCK_VALUE",
  field: "fulfillableQuantityValue",
  title: "FBA Available Inventory Value",
  category: MetricCategory.INVENTORY,
  color: PALETTE[0],
  currency: true,
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Avail.",
  tooltip: "Available inventory value fulfilled by Amazon",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});

export const FBM_STOCK: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "FBM_STOCK",
  field: "fbmStock",
  title: "Available Inventory Via Merchant",
  category: MetricCategory.INVENTORY,
  color: PALETTE[0],
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Avail. FBM",
  tooltip: "Stock available on Amazon and ready to be sold by merchant",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});

export const FBM_STOCK_VALUE: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "FBM_STOCK_VALUE",
  field: "fbmStockValue",
  title: "Available Inventory Value Via Merchant",
  category: MetricCategory.INVENTORY,
  color: PALETTE[0],
  currency: true,
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Avail. FBM",
  tooltip: "Stock value available on Amazon and ready to be sold by merchant",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});

export const INBOUND_STOCK: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "INBOUND_STOCK",
  field: "inboundQuantity",
  title: "Inbound Stock",
  category: MetricCategory.INVENTORY,
  color: PALETTE[1],
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "In.",
  tooltip: "Inventory shipped but it but has not yet been received",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});
export const INBOUND_STOCK_VALUE: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "INBOUND_STOCK_VALUE",
  field: "inboundQuantityValue",
  title: "Inbound Stock Value",
  category: MetricCategory.INVENTORY,
  color: PALETTE[1],
  currency: true,
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "In.",
  tooltip: "Value of inventory shipped but not yet received",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});
export const RESERVED_STOCK: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "RESERVED_STOCK",
  field: "reservedQuantity",
  title: "Reserved Stock",
  category: MetricCategory.INVENTORY,
  color: PALETTE[2],
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Res.",
  tooltip: "Inventory already sold but hasn't been shipped to the customer yet",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});
export const RESERVED_STOCK_VALUE: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "RESERVED_STOCK_VALUE",
  field: "reservedQuantityValue",
  title: "Reserved Stock Value",
  category: MetricCategory.INVENTORY,
  color: PALETTE[2],
  currency: true,
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Res.",
  tooltip: "Value of inventory already sold but hasn't been shipped to the customer yet",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});
export const UNFULFILLABLE_STOCK: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "UNFULLFILLABLE_STOCK",
  field: "unsellableQuantity",
  title: "Unfulfillable Stock",
  category: MetricCategory.INVENTORY,
  color: PALETTE[3],
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Unful.",
  tooltip: "Damaged or expired items that cannot be sold",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});
export const UNFULFILLABLE_STOCK_VALUE: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "UNFULFILLABLE_STOCK_VALUE",
  field: "unsellableQuantityValue",
  title: "Unfulfillable Stock Value",
  category: MetricCategory.INVENTORY,
  color: PALETTE[3],
  currency: true,
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Unful.",
  tooltip: "Value of damaged or expired items that cannot be sold",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});

export const NET_PPM: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "NET_PPM",
  field: "netPureProductMargin",
  title: "Net PPM",
  category: MetricCategory.HIDDEN,
  color: PALETTE[10],
  supportedAccountType: SupportedAccountType.VENDOR,
  tooltip: "Net Pure Product Margin",
});
export const NET_PURE_PRODUCT_MARGIN: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "NET_PURE_PRODUCT_MARGIN",
  numerator: NET_PPM,
  denominator: undefined,
  title: "Net PPM",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[10],
  graphTension: 1,
  stepped: true,
  keepLastValue: true,
  supportedAccountType: SupportedAccountType.VENDOR,
  requireSellingPartnerAccess: true,
  tooltip: "last Net Pure Product Margin",
  isPercent: true,
  precision: "1.0-0",
});
export const VENDOR_SHIPPED_COGS_RATIO: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "SHIPPED_COGS_RATIO",
  numerator: VENDOR_SHIPPED_COGS,
  denominator: TOTAL_SALES,
  title: "Shipped Cogs Ratio",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[3],
  isPercent: true,
  inverseColors: true,
  tooltip: "Ratio of Shipped Cogs to Shipped Sales",
  mustApplyEvolutionStyle: false,
  supportedAccountType: SupportedAccountType.VENDOR,
});

export const TACOS: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "TACOS",
  numerator: COST,
  denominator: TOTAL_SALES,
  title: "TACOS",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[9],
  isPercent: true,
  tooltip: "Spending as a percentage of total sales",
  requireSellingPartnerAccess: true,
  higherIsBetter: false,
  mustApplyEvolutionStyle: false,
});

export const SPONSORED_SALES_SHARE: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "SPONSORED_SALES_SHARE",
  numerator: AD_SALES,
  denominator: TOTAL_SALES,
  title: "Ad Sales Share",
  category: MetricCategory.AD_STATS,
  color: PALETTE[12],
  isPercent: true,
  precision: "1.0-2",
  titleSmall: "Sp. Sales %",
  tooltip: "Percentage of sales generated through advertising",
  requireSellingPartnerAccess: true,
});

// Traffic metrics
export const WEB_SESSIONS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "WEB_SESSIONS",
  field: "browserSessions",
  title: "Web Sessions",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[13],
  titleSmall: "Web Sess.",
  requireSellingPartnerAccess: true,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Number of unique visits to your Amazon page by Web users within a 24-hour period",
});
export const MOBILE_SESSIONS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "MOBILE_SESSIONS",
  field: "mobileAppSessions",
  title: "Mobile Sessions",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[14],
  titleSmall: "Mobile Sess.",
  requireSellingPartnerAccess: true,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Number of unique visits to your Amazon page by mobile users within a 24-hour period",
});

export const WEB_PAGE_VIEWS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "WEB_PAGE_VIEWS",
  field: "browserPageViews",
  title: "Web Page Views",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[15],
  titleSmall: "Web pv.",
  requireSellingPartnerAccess: true,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Number of times a product detail page is viewed by Web users",
});
export const MOBILE_PAGE_VIEWS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "MOBILE_PAGE_VIEWS",
  field: "mobileAppPageViews",
  title: "Mobile Page Views",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[16],
  titleSmall: "Mobile pv.",
  requireSellingPartnerAccess: true,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Number of times a product detail page is viewed by mobile users",
});
export const BUY_BOX_PAGE_VIEW: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "BUY_BOX_PAGE_VIEW",
  field: "buyBoxPageViews",
  title: "Buy Box Page Views",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[17],
  titleSmall: "Bbox pv.",
  requireSellingPartnerAccess: true,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Number of times a product detail page is viewed with the Buy Box",
});

export const SESSIONS: Metric<AdStatsEx> = new SumMetric<AdStatsEx>({
  id: "SESSIONS",
  metric1: WEB_SESSIONS,
  metric2: MOBILE_SESSIONS,
  title: "Sessions",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[18],
  titleSmall: "Sess.",
  supportedAccountType: SupportedAccountType.SELLER,
  requireSellingPartnerAccess: true,
  tooltip: "Number of unique visits to your Amazon page by a user within a 24-hour period",
});

export const PAGE_VIEWS: Metric<AdStatsEx> = new SumMetric<AdStatsEx>({
  id: "PAGE_VIEWS",
  metric1: WEB_PAGE_VIEWS,
  metric2: MOBILE_PAGE_VIEWS,
  title: "Page Views",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[19],
  titleSmall: "pv.",
  requireSellingPartnerAccess: true,
  tooltip: "Number of times a product detail page is viewed",
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
});

export const APP_RATIO: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "APP_RATIO",
  numerator: MOBILE_SESSIONS,
  denominator: SESSIONS,
  title: "Mobile Shares",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[20],
  isPercent: true,
  precision: "1.0-0",
  titleSmall: "Mobile %",
  supportedAccountType: SupportedAccountType.SELLER,
  requireSellingPartnerAccess: true,
  tooltip: "Share of the total session from mobile",
});

export const BUY_BOX_RATE: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "BUY_BOX_RATE",
  numerator: BUY_BOX_PAGE_VIEW,
  denominator: PAGE_VIEWS,
  title: "Buy Box Win Rate",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[21],
  isPercent: true,
  precision: "1.0-0",
  titleSmall: "Bbox %",
  supportedAccountType: SupportedAccountType.SELLER,
  requireSellingPartnerAccess: true,
  tooltip: "Percentage of time a seller's product listing wins the Buy Box",
});

export const TOTAL_CONVERSION_RATE: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "TOTAL_CONVERSION_RATE",
  numerator: TOTAL_ORDERS,
  denominator: SESSIONS,
  title: "TCR",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[22],
  isPercent: true,
  tooltip: "Total conversion rate = all units sold / sessions",
  supportedAccountType: SupportedAccountType.SELLER,
  requireSellingPartnerAccess: true,
});

export const ASINS_WITH_TOP_OF_SEARCH_RANKINGS_FIRED: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "ASINS_WITH_TOP_OF_SEARCH_RANKINGS_FIRED",
  field: "nbAsinsWithRuleFired",
  title: "ASINs with T.O.S.R. fired",
  category: MetricCategory.OTHER,
  color: "rgba(255, 150, 150, 1)",
  graphTension: 0,
  graphBorderDash: [10, 5],
  currency: false,
  tooltip: "Number of ASINs with Top of Search Rankings fired",
});

// VENDOR INVENTORY METRICS
export const VENDOR_SELLABLE_UNITS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "SELLABLE_UNITS",
  childField: "inventorySellableUnits",
  field: "vendorInventoryAdStats",
  title: "Sellable Units",
  category: MetricCategory.INVENTORY,
  color: PALETTE[1],
  titleSmall: "Sellable Units",
  tooltip: "Number of sellable units",
  mustApplyEvolutionStyle: false,
  supportedAccountType: SupportedAccountType.VENDOR,
});

export const VENDOR_SELLABLE_COST: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "SELLABLE_COST",
  field: "vendorInventoryAdStats",
  childField: "inventorySellableCost",
  title: "Sellable Cost",
  currency: true,
  category: MetricCategory.INVENTORY,
  color: PALETTE[3],
  titleSmall: "Sellable Cost",
  tooltip: "Sum of inventory sellable cost",
  mustApplyEvolutionStyle: false,
  supportedAccountType: SupportedAccountType.VENDOR,
});
export const VENDOR_UNSELLABLE_UNITS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "UNSELLABLE_UNITS",
  field: "vendorInventoryAdStats",
  childField: "inventoryUnsellableUnits",
  title: "Unsellable Units",
  category: MetricCategory.INVENTORY,
  color: PALETTE[5],
  titleSmall: "Unsellable Units",
  tooltip: "Number of unsellable units",
  supportedAccountType: SupportedAccountType.VENDOR,
  higherIsBetter: false,
});

export const VENDOR_UNSELLABLE_COST: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "UNSELLABLE_COST",
  field: "vendorInventoryAdStats",
  childField: "inventoryUnsellableCost",
  title: "Unsellable Cost",
  currency: true,
  category: MetricCategory.INVENTORY,
  color: PALETTE[7],
  titleSmall: "Unsellable Cost",
  tooltip: "Sum of unsellable cost",
  supportedAccountType: SupportedAccountType.VENDOR,
  higherIsBetter: false,
});

export const VENDOR_OPEN_PURCHASE_ORDER_UNITS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "OPEN_PURCHASE_ORDER_UNITS",
  field: "vendorInventoryAdStats",
  childField: "inventoryOpenPurchaseOrderUnits",
  title: "Open Purchase Order Units",
  category: MetricCategory.INVENTORY,
  color: PALETTE[9],
  titleSmall: "Open Purchase Units",
  tooltip: "Number of open purchase order units",
  supportedAccountType: SupportedAccountType.VENDOR,
  mustApplyEvolutionStyle: false,
});

export const VENDOR_AGED90P_SELLABLE_UNITS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "AGED90P_SELLABLE_UNITS",
  field: "vendorInventoryAdStats",
  childField: "inventoryAged90pSellableUnits",
  title: "Aged 90 Plus Days Sellable Units",
  category: MetricCategory.INVENTORY,
  color: PALETTE[11],
  titleSmall: "Old Sellable Units",
  tooltip: "Number of aged 90 plus days sellable inventory units",
  supportedAccountType: SupportedAccountType.VENDOR,
});

export const VENDOR_AGED90P_SELLABLE_COST: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "AGED90P_SELLABLE_COST",
  field: "vendorInventoryAdStats",
  childField: "inventoryAged90pSellableCost",
  title: "Aged 90 Plus Days Sellable Cost",
  category: MetricCategory.INVENTORY,
  currency: true,
  color: PALETTE[13],
  titleSmall: "Old Sellable Cost",
  tooltip: "Number of aged 90 plus days sellable inventory cost",
  supportedAccountType: SupportedAccountType.VENDOR,
  higherIsBetter: false,
});

// see https://github.com/m19-dev/main-repo/issues/6387
export const VENDOR_OUT_OF_STOCK_VIEWS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "VENDOR_OUT_OF_STOCK_VIEWS",
  field: "outOfStockGlanceViews",
  title: "Out of Stock Glance Views",
  category: MetricCategory.INVENTORY,
  color: PALETTE[15],
  tooltip:
    "Number of times products are shown as unavailable for purchase on the product detail page, even though they can be sourced from a 1P vendor. Factors that affect this metric include temporary sales suppression due to repeat customer complaints or safety concerns (Andon Cords), manual buyer suppressions, and lack of inventory.",
  supportedAccountType: SupportedAccountType.VENDOR,
  higherIsBetter: false,
});

// see https://github.com/m19-dev/main-repo/issues/6387
export const VENDOR_SOROOS: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "SoROOS",
  numerator: VENDOR_OUT_OF_STOCK_VIEWS,
  denominator: PAGE_VIEWS,
  isPercent: true,
  precision: "1.2-2",
  title: "SoROOS",
  category: MetricCategory.INVENTORY,
  color: PALETTE[16],
  tooltip:
    "Sourceable Replenishment Out of Stock. This metric measures the instances when products are shown as unavailable for purchase on the product detail page, even though they can be sourced from a 1P vendor. Factors that affect SoROOS include temporary sales suppression due to repeat customer complaints or safety concerns (Andon Cords), manual buyer suppressions, and lack of inventory.",
  supportedAccountType: SupportedAccountType.VENDOR,
  higherIsBetter: false,
});

// Global order stats metrics

function buildSummableOrderMetric(
  id: string,
  field: keyof OrderStats,
  title: string,
  color: string,
  tooltip?: string,
  titleSmall?: string,
  category?: MetricCategory,
) {
  return new SummableMetric<OrderStats>({
    id,
    field,
    title,
    category: category ?? MetricCategory.PROFIT_STATS,
    color,
    currency: true,
    titleSmall,
    supportedAccountType: SupportedAccountType.SELLER,
    requireSellingPartnerAccess: true,
    tooltip: tooltip,
  });
}

export const ORDER_GLOBAL_NET_SALES: Metric<OrderStats> = buildSummableOrderMetric(
  "ORDER_GLOBAL_NET_SALES",
  "netSales",
  "Net sales",
  PALETTE[0],
  "Net sales",
  "Net sales",
  MetricCategory.PROFIT_STATS,
);

export const ORDER_GLOBAL_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "ORDER_GLOBAL_FEE",
  "fee",
  "Fees",
  PALETTE[4],
  "Fees",
  undefined,
  MetricCategory.PROFIT_STATS,
);

export const ORDER_GLOBAL_ADV: Metric<OrderStats> = buildSummableOrderMetric(
  "ORDER_GLOBAL_ADV",
  "advertising",
  "Advertising",
  PALETTE[5],
  "Amount spent on advertising on a given period",
  "Adv.",
);

// Order metrics

// Sales
export const GROSS_SALES: Metric<OrderStats> = buildSummableOrderMetric(
  "ORDER_SALES", // do not rename id persisted in DB + localStorage + en.json
  "grossSales",
  "Gross Sales",
  PALETTE[0],
  "Gross Sales",
);
export const REFUNDED_SALES: Metric<OrderStats> = buildSummableOrderMetric(
  "REFUNDED_SALES",
  "refundedSales",
  "Refunded Sales",
  PALETTE[0],
  "Refunds issued to customers",
);
export const FBA_GLOBAL_REIMBURSEMENT: Metric<OrderStats> = buildSummableOrderMetric(
  "FBA_GLOBAL_REIMBURSEMENT",
  "fbaGlobalReimbursement",
  "FBA Reimbursement",
  PALETTE[0],
  "FBA Reimbursement",
);
export const FBA_REVERSAL_REIMBURSEMENT: Metric<OrderStats> = buildSummableOrderMetric(
  "FBA_REVERSAL_REIMBURSEMENT",
  "fbaReversalReimbursement",
  "FBA Reversal Reimbursement",
  PALETTE[1],
  "FBA Reversal Reimbursement",
);
export const FBA_REIMBURSEMENT_CLAWBACK: Metric<OrderStats> = buildSummableOrderMetric(
  "FBA_REIMBURSEMENT_CLAWBACK",
  "fbaReimbursementClawback",
  "FBA Reimbursement Clawback",
  PALETTE[0],
  "FBA Reimbursement Clawback",
);
export const FBA_OTHER_REIMBURSEMENT: Metric<OrderStats> = buildSummableOrderMetric(
  "FBA_OTHER_REIMBURSEMENT",
  "fbaOtherReimbursement",
  "FBA Other Reimbursement",
  PALETTE[0],
  "FBA Other Reimbursement",
);
export const QUANTITY: Metric<OrderStats> = new SummableMetric<OrderStats>({
  id: "QUANTITY",
  field: "quantity",
  title: "Quantity",
  category: MetricCategory.PROFIT_STATS,
  color: PALETTE[0],
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Number of units sold",
});
export const SELLABLE_RETURNS: Metric<OrderStats> = new SummableMetric<OrderStats>({
  id: "SELLABLE_RETURNS",
  field: "sellableReturnUnits",
  title: "Sellable Returns",
  category: MetricCategory.PROFIT_STATS,
  color: PALETTE[0],
  higherIsBetter: false,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Sellable Returns",
});
// Promotion
export const PROMOTION: Metric<OrderStats> = buildSummableOrderMetric(
  "PROMOTION",
  "promotion",
  "Promotions",
  PALETTE[2],
  "Promotions",
  "Promo.",
  MetricCategory.PROFIT_STATS,
);

export const ORDER_SALES_DETAILS_WITH_QUANTITIES: Metric<OrderStats>[] = [
  GROSS_SALES,
  QUANTITY, // order units
  PROMOTION,
  REFUNDED_SALES,
  SELLABLE_RETURNS, // unit
];

export const ORDER_SALES_DETAILS: Metric<OrderStats>[] = [GROSS_SALES, PROMOTION, REFUNDED_SALES];

// Fee
export const REFERRAL_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "REFERRAL_FEE",
  "referralFee",
  "Referral",
  PALETTE[4],
  undefined,
  "A fee Amazon charges for each item sold on their platform",
);

export const FBA_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "FBA_FEE",
  "fbaFee",
  "FBA",
  PALETTE[4],
  undefined,
  "Amazon's fulfillment services (storage, picking, packing, shipping, and customer service)",
);
export const GIFT_WRAP_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "GIFT_WRAP_FEE",
  "giftWrapFee",
  "Gift Wrap",
  PALETTE[4],
  "Gift Wrap",
);
export const OTHER_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "OTHER_FEE",
  "otherFee",
  "Other",
  PALETTE[4],
  "Other Fees",
);
export const FBA_STORAGE_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "FBA_STORAGE_FEE",
  "fbaStorageFee",
  "Storage",
  PALETTE[4],
  undefined,
  "The costs for storing inventory on Amazon warehouses",
);
export const FBA_LONG_TERM_STORAGE_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "FBA_LONG_TERM_STORAGE_FEE",
  "longTermStorageFee",
  "Long Term Storage Fee",
  PALETTE[4],
  undefined,
  "Long Term Storage Fee",
);

export const FEE_ADJUSTMENT: Metric<OrderStats> = buildSummableOrderMetric(
  "FEE_ADJUSTMENT",
  "feeAdjustment",
  "Adjustment",
  PALETTE[4],
  "Adjustment",
);
export const RETURN_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "RETURN_FEE",
  "returnFees",
  "Return Fees",
  PALETTE[4],
  "Return Fees",
);
export const LIQUIDATIONS_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "LIQUIDATIONS_FEE",
  "liquidations",
  "Liquidations",
  PALETTE[4],
  "Liquidations",
);
export const INTERNATIONAL_FREIGHT_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "INTERNATIONAL_FREIGHT_FEE",
  "internationalFreight",
  "International Freight",
  PALETTE[4],
  "International Freight",
);

export const CHARGE_EXPORT: Metric<OrderStats> = buildSummableOrderMetric(
  "CHARGE_EXPORT",
  "chargeExport" as keyof OrderStats,
  "Export",
  PALETTE[6],
  "Export",
);

export const ORDER_FEE_DETAILS: Metric<OrderStats>[] = [
  REFERRAL_FEE,
  FBA_FEE,
  FBA_STORAGE_FEE,
  FBA_LONG_TERM_STORAGE_FEE,
  GIFT_WRAP_FEE,
  FEE_ADJUSTMENT,
  RETURN_FEE,
  CHARGE_EXPORT,
  INTERNATIONAL_FREIGHT_FEE,
  LIQUIDATIONS_FEE,
  OTHER_FEE,
];

// Advertising
export const SP_ADVERTISING: Metric<OrderStats> = buildSummableOrderMetric(
  "SP_ADVERTISING",
  "spAdvertising",
  "SP",
  PALETTE[5],
  "Sponsored Products Advertising",
);
export const SB_ADVERTISING: Metric<OrderStats> = buildSummableOrderMetric(
  "SB_ADVERTISING",
  "sbAdvertising",
  "SB",
  PALETTE[5],
  "Sponsored Brand Advertising",
);
export const SD_ADVERTISING: Metric<OrderStats> = buildSummableOrderMetric(
  "SD_ADVERTISING",
  "sdAdvertising",
  "SD",
  PALETTE[5],
  "Sponsored Display Advertising",
);

export const ORDER_ADVERTISING_DETAILS: Metric<OrderStats>[] = [SP_ADVERTISING, SB_ADVERTISING, SD_ADVERTISING];

// Profit
export const COST_OF_GOODS: Metric<OrderStats> = buildSummableOrderMetric(
  "COST_OF_GOODS",
  "costOfGoods",
  "Cost Of Goods",
  PALETTE[8],
  "Total cost of producing a product",
  undefined,
  MetricCategory.PROFIT_STATS,
);

// Profit
export const PROFIT: Metric<OrderStats> = buildSummableOrderMetric(
  "PROFIT",
  "profit",
  "Profit",
  "#4287f5",
  "Net profit after deducting COGS, taxes, and other expenses (excluding global fees like long-term storage/international freight)",
  undefined,
  MetricCategory.PROFIT_STATS,
);

export const PROFIT_WITH_GLOBAL_FEES: Metric<OrderStats> = buildSummableOrderMetric(
  "PROFIT_WITH_GLOBAL_FEES",
  "profit",
  "Profit",
  "#4287f5",
  "Net profit after deducting COGS, taxes, and other expenses (including global fees like long-term storage/international freight)",
  undefined,
  MetricCategory.GLOBAL_PROFIT_STATS,
);

// Margin
export const ORDER_GLOBAL_MARGIN: Metric<OrderStats> = new RatioMetric<OrderStats>({
  id: "ORDER_GLOBAL_MARGIN",
  numerator: PROFIT,
  denominator: ORDER_GLOBAL_NET_SALES,
  title: "Margin",
  isPercent: true,
  category: MetricCategory.PROFIT_STATS,
  color: "#4287f5",
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Percentage of remaining as profit after all expenses",
});

export const ROI: Metric<OrderStats> = new RatioMetric<OrderStats>({
  id: "ROI",
  numerator: PROFIT,
  denominator: COST_OF_GOODS,
  title: "ROI",
  isPercent: true,
  category: MetricCategory.PROFIT_STATS,
  color: "#4287f5",
  coeff: -1,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Return on investment",
});

export const TOTAL_PRODUCT_SALES: Metric<AmcSponsoredAdsAndDspOverlap> =
  new SummableMetric<AmcSponsoredAdsAndDspOverlap>({
    id: "TOTAL_PRODUCT_SALES",
    field: "totalProductSales",
    title: "Total product sales",
    category: MetricCategory.AMC,
    color: PALETTE[9],
    supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
    currency: true,
    requireSellingPartnerAccess: true,
  });

export const PROFIT_DETAILS: Metric<OrderStats>[] = [PROFIT, ROI];

export function isMetricSupportedForAccountType(metric: Metric<AdStatsEx>, accountType: AccountType) {
  switch (metric.supportedAccountType) {
    case SupportedAccountType.VENDOR:
      return accountType == AccountType.VENDOR;
    case SupportedAccountType.SELLER:
      return accountType == AccountType.SELLER;
    case SupportedAccountType.VENDOR_AND_SELLER:
      return true;
  }
}

// used by profit-details.component.ts
export function getOrderMetricDetailsWithQuantities(metric: Metric<OrderStats>): Metric<OrderStats>[] {
  switch (metric) {
    case ORDER_GLOBAL_NET_SALES:
      return ORDER_SALES_DETAILS_WITH_QUANTITIES;
    case FBA_GLOBAL_REIMBURSEMENT:
      return [FBA_REVERSAL_REIMBURSEMENT, FBA_REIMBURSEMENT_CLAWBACK, FBA_OTHER_REIMBURSEMENT];
    case ORDER_GLOBAL_FEE:
      return ORDER_FEE_DETAILS;
    case ORDER_GLOBAL_ADV:
      return ORDER_ADVERTISING_DETAILS;
    case PROFIT:
      return PROFIT_DETAILS;
    case COST_OF_GOODS:
      return [COST_OF_GOODS];
    case ORDER_GLOBAL_MARGIN:
      return [ORDER_GLOBAL_MARGIN];
  }
  return [];
}

// used by 360.service.ts
export function getOrderMetricDetails(metric: Metric<OrderStats>): Metric<OrderStats>[] {
  switch (metric) {
    case ORDER_GLOBAL_NET_SALES:
      return ORDER_SALES_DETAILS;
    case FBA_GLOBAL_REIMBURSEMENT:
      return [FBA_REVERSAL_REIMBURSEMENT, FBA_REIMBURSEMENT_CLAWBACK, FBA_OTHER_REIMBURSEMENT];
    case ORDER_GLOBAL_FEE:
      return ORDER_FEE_DETAILS;
    case ORDER_GLOBAL_ADV:
      return ORDER_ADVERTISING_DETAILS;
    case PROFIT:
      return [PROFIT];
    case COST_OF_GOODS:
      return [COST_OF_GOODS];
    case ORDER_GLOBAL_MARGIN:
      return [ORDER_GLOBAL_MARGIN];
  }
  return [];
}

// used by profit-and-loss.component.ts
export function getProfitAndLossDetails(metric: Metric<OrderStats>): Metric<OrderStats>[] {
  switch (metric) {
    case ORDER_GLOBAL_NET_SALES:
      return ORDER_SALES_DETAILS;
    case FBA_GLOBAL_REIMBURSEMENT:
      return [FBA_REVERSAL_REIMBURSEMENT, FBA_REIMBURSEMENT_CLAWBACK, FBA_OTHER_REIMBURSEMENT];
    case ORDER_GLOBAL_FEE:
      return ORDER_FEE_DETAILS;
    case ORDER_GLOBAL_ADV:
      return ORDER_ADVERTISING_DETAILS;
    default:
      return [];
  }
}

export function groupByCategory<T>(metrics: Metric<T>[]): Map<MetricCategory, Metric<T>[]> {
  const groupedPageMetrics = new Map<MetricCategory, Metric<T>[]>();
  for (const m of metrics) {
    if (!groupedPageMetrics.has(m.category)) {
      groupedPageMetrics.set(m.category, [m]);
    } else {
      groupedPageMetrics.get(m.category)!.push(m);
    }
  }
  for (const k of groupedPageMetrics.keys()) {
    groupedPageMetrics.get(k)!.sort((a, b) => (a.title > b.title ? 1 : a.title === b.title ? 0 : -1));
  }
  return groupedPageMetrics;
}

export function getAggregationFunction(metricTypes: Set<MetricCategory>): AggregationFunction[] {
  const aggFunctions: AggregationFunction[] = [];

  if (metricTypes.has(MetricCategory.PRODUCT)) {
    aggFunctions.push(AggregationFunction.combineProducts);
  }
  if (metricTypes.has(MetricCategory.AD_STATS) || metricTypes.has(MetricCategory.SALES_STATS)) {
    aggFunctions.push(AggregationFunction.sumAdStats);
  }
  if (metricTypes.has(MetricCategory.PROFIT_STATS)) {
    aggFunctions.push(AggregationFunction.sumOrderStat);
  }

  return aggFunctions;
}

export function generateAggregationFunction(metricTypes: Set<MetricCategory>) {
  return composeAggregationFunctions(getAggregationFunction(metricTypes));
}
