<button
  class="focus-visible:ring-main-500 dark:focus-visible:ring-main-400 relative inline-flex rounded-full border-2 border-transparent p-0 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50 dark:focus-visible:ring-offset-gray-900"
  [class]="containerClass()"
  tabindex="0"
  type="button"
  role="switch"
  [attr.aria-checked]="checkedValue()"
  [disabled]="disabled() || loading()"
  (click)="check()"
>
  <span
    class="pointer-events-none relative inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out dark:bg-gray-900"
    [ngClass]="{
      'h-3 w-3': size() === 'sm',
      'h-4 w-4': size() === 'md',
      'h-5 w-5': size() === 'lg',
    }"
    [class]="innerClass()"
  >
    @if (loading()) {
      <span
        class="absolute inset-0 flex h-full w-full items-center justify-center opacity-100 transition-opacity duration-200 ease-in"
        aria-hidden="true"
        ><span class="icon-[mdi--circle-arrows] h-3 w-3 animate-spin text-gray-500 dark:text-gray-400"></span
      ></span>
    }
  </span>
</button>
