import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IButtonComponent } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { ICON_EXPORT } from "@m19-board/utils/iconsLabels";
@Component({
  selector: "app-export-button",
  template: ` <IButton
    *transloco="let t"
    size="sm"
    [tooltipValue]="tooltipValue"
    [icon]="ICON_EXPORT"
    (onClick)="handleClick($event)"
    color="white"
    label="{{ t('common.export') }}"
  />`,
  standalone: true,
  imports: [IButtonComponent, TranslocoRootModule],
})
export class ExportButtonComponent implements OnInit {
  readonly ICON_EXPORT = ICON_EXPORT;

  @Input() tooltipValue: string = "";
  @Output() export = new EventEmitter<Event>();
  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    if (this.tooltipValue === undefined) {
      this.tooltipValue = this.translocoService.translate("common.export_as_csv");
    }
  }

  handleClick(event: MouseEvent) {
    this.export.emit(event);
  }
}
