<ng-container *transloco="let t">
  @if (campaignType === "TacosStrategy" && isVendor()) {
    <IAlert type="warning" class="mt-4">
      {{ t("tacos-strategy.no_tacos_vendor") }}
    </IAlert>
  } @else {
    @if (!accountMarketplace?.hasAccessToAdvertising) {
      <div class="alert alert-warning mt-4">
        {{ t("strategy-list.issue_connecting") }}
      </div>
    }
    @if (!loading && !accountMarketplace?.activated) {
      <div class="alert alert-warning mt-4">
        {{ t("billing-account-selection.unable_to_automate") }}
        <a href="https://advertising.amazon.com/" target="_blank">{{ t("common.amazon_advertising_console") }}</a
        >.
      </div>
    }
    <app-sb-creative-warning></app-sb-creative-warning>
    @switch (getSBStrategyLimitWarning()) {
      @case ("REACHED") {
        <div class="alert alert-warning mt-4">
          {{ t("strategy-list.maximum_reached_150_active_sponsored") }}
        </div>
      }
      @case ("SOON") {
        <div class="alert alert-warning mt-4">
          {{ t("strategy-list.reached_maximum_num", [liveStrategies().length]) }}
        </div>
      }
    }
    <div class="position-absolute flex w-full justify-between">
      <div class="mr-2 flex items-center justify-start">
        <input
          [class.disabled]="loading || !bidderOn || !accountMarketplace?.activated"
          type="text"
          class="form-control simple-input position-sticky"
          placeholder="{{ t('strategy-list.search_by_strategy_name_or_asin') }}"
          [(ngModel)]="userSearch"
          (ngModelChange)="onSearchInputChange()"
          data-testid="strategy-search-input"
        />
        <div class="ml-4" style="width: 25rem">
          <ISelect
            [options]="strategyStatusOptions"
            [selected]="strategyFilter"
            (selectedChange)="OnStatusFilterChange($event)"
          />
        </div>
      </div>
      <div class="flex items-center justify-end">
        <IButton
          class="mr-3"
          [icon]="ICON_ADD"
          label="{{ t('sd-strategy-creation.create_strategy') }}"
          [disabled]="isReadOnly"
          (onClick)="createStrategy()"
          testid="create-strategy-button"
        />

        <div [class.disabled]="!bidderOn || !accountMarketplace?.activated || campaignType === 'SB'" class="flex gap-2">
          <IButton
            [disabled]="isReadOnly"
            tooltipValue="{{ t('product-group.upload_strategy_data') }}"
            [icon]="ICON_IMPORT"
            (onClick)="uploadStrategyData()"
            color="white"
            label="{{ t('common.import') }}"
          />
          <app-export-button
            (export)="exportStrategyData()"
            tooltipValue="{{ t('strategy-list.export_strategy_list_as_csv') }}"
          ></app-export-button>
        </div>
      </div>
    </div>

    @if (accountMarketplace?.activated) {
      <div [class.disabled]="!bidderOn">
        <app-strategy-grid
          [campaignType]="campaignType"
          [externalSearchFilter]="externalSearchFilter"
          [externalStatusFilter]="externalStatusFilter"
        ></app-strategy-grid>
      </div>
    }
  }
  <app-spinner [display]="loading" [main]="true"></app-spinner>
</ng-container>
