<IDatePicker
  class="z-[60]"
  [dateFormat]="dateFormat()"
  [selectedDateRange]="selectedDateRange()"
  (selectedDateRangeChange)="selectDateRange($event)"
  *transloco="let t"
>
  <span class="text-gray-400">|</span>
  <span>{{ t("date-range-selection.compare_to") }} :</span>
  <ISelect
    popperWidth="10"
    selectedAppearance="color"
    [options]="comparisonPeriodOptions()"
    (selectedChange)="selectPeriodComparison($event.value)"
    [selected]="selectedComparisonPeriod()"
  >
    <ng-template #labelSlot #optionSlot let-o
      ><span class="inline-flex flex-1 justify-between gap-1 truncate"
        ><span>{{ o?.label }}</span
        ><span class="text-[0.8rem] text-gray-500">{{ o?.formattedRange }}</span></span
      >
    </ng-template>
  </ISelect>
  @if (selectedComparisonPeriod()?.value === Comparison.Custom) {
    <span>From : </span>
    <IDatePicker
      [dateFormat]="dateFormat()"
      [double]="false"
      [range]="false"
      [maxDate]="selectedDateRange().start"
      (selectedDateRangeChange)="selectCustomComparisonDate($event)"
    />
  }
</IDatePicker>
