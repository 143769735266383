<div *transloco="let t">
  <div class="mb-1 flex w-full items-center justify-between">
    @if (showPreviousMonthButton()) {
      <button
        class="flex flex-row items-center gap-x-2 rounded-md border border-gray-300 bg-white p-1.5 text-gray-700 shadow-sm duration-100 hover:bg-gray-100"
        [disabled]="canNavigateToPreviousMonth()"
        [class.cursor-not-allowed]="canNavigateToPreviousMonth()"
        (click)="goToPreviousMonth.emit()"
      >
        <span class="icon-[mdi--chevron-left]"></span>
      </button>
    } @else {
      <div></div>
    }
    <div class="flex items-center justify-center gap-2">
      <select class="cursor-pointer bg-white outline-none" name="month" id="month" (change)="onMonthChange($event)">
        @for (month of availableMonths(); track $index) {
          <option [value]="$index" [selected]="currentDate().getMonth() === $index" [disabled]="month.disabled">
            {{ month.label }}
          </option>
        }
      </select>
      <select class="cursor-pointer bg-white outline-none" name="year" id="year" (change)="onYearChange($event)">
        @for (year of availableYears(); track $index) {
          <option [value]="year" [selected]="currentDate().getFullYear() === year">
            {{ year }}
          </option>
        }
      </select>
    </div>
    @if (showNextMonthButton()) {
      <button
        class="flex flex-row items-center gap-x-2 rounded-md border border-gray-300 bg-white p-1.5 text-gray-700 shadow-sm duration-100 hover:bg-gray-100"
        (click)="goToNextMonth.emit()"
        [disabled]="canNavigateToNextMonth()"
        [class.cursor-not-allowed]="canNavigateToNextMonth()"
      >
        <span class="icon-[mdi--chevron-right]"></span>
      </button>
    } @else {
      <div></div>
    }
  </div>
  <table>
    <thead>
      <tr class="w-full">
        @for (day of WEEK_DAYS; track $index) {
          <th class="inline-flex h-8 w-8 items-center justify-center font-light text-gray-500">
            {{ t(day) }}
          </th>
        }
      </tr>
    </thead>
    <tbody>
      @for (week of monthWeeks(); track $index) {
        <tr>
          @for (day of week; track $index) {
            <td
              class="my-0.5 inline-flex h-8 w-8 cursor-pointer select-none items-center justify-center p-0 font-light duration-100"
              [ngClass]="{
                'bg-main-500/10': day.isInCurrentMonth && (day.isStartDate || day.isEndDate || day.isHovered),
                'rounded-l-md': day.isStartDate,
                'rounded-r-md': day.isEndDate || day.isHovered,
              }"
            >
              <button
                [disabled]="day.isDisabled"
                class="inline-flex h-full w-full items-center justify-center rounded-md disabled:cursor-not-allowed disabled:text-gray-300"
                [ngClass]="{
                  'text-gray-300': !day.isInCurrentMonth,
                  'text-gray-800': day.isInCurrentMonth,
                  'bg-main-500 text-white': day.isInCurrentMonth && (day.isStartDate || day.isEndDate || day.isHovered),
                  'hover:bg-gray-100':
                    !day.isStartDate && !day.isEndDate && !day.isInRange && !day.isDisabled && !day.isHovered,
                  'bg-main-500/10 rounded-none': day.isInCurrentMonth && day.isInRange,
                  'text-main-500': day.isToday && !day.isStartDate && !day.isEndDate,
                }"
                (click)="selectDate.emit(day.date)"
                (mouseover)="cellHover.emit(day.date)"
              >
                {{ day.date.getDate() }}
              </button>
            </td>
          }
        </tr>
      }
    </tbody>
  </table>
</div>
