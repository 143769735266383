import { CommonModule } from "@angular/common";
import { Component, computed, inject, input, OnInit, signal } from "@angular/core";
import { MatSlideToggleChange, MatSlideToggleModule } from "@angular/material/slide-toggle";
import { Currency } from "@front/m19-api-client";
import { ACOS, COST, M19_METRICS, Metric } from "@front/m19-metrics";
import { AdStatsEx, CurrencyStat } from "@front/m19-models";
import {
  DataSet,
  DataSetEventAnnotation,
  MetricsSelectorLocalStorageKey,
  UserSelectionService,
} from "@front/m19-services";
import { Option, SpinnerComponent } from "@front/m19-ui";
import { DateAggregation } from "@front/m19-utils";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { ActivityEventType, ActivityService } from "@m19-board/activities/activity.service";
import { ActivityEventFiltersComponent } from "@m19-board/activity-event-filters.component";
import { MetricSelectorComponent } from "@m19-board/insights/metric-selector/metric-selector.component";
import { DAILY_BUDGET, MIN_DAILY_SPEND, MONTHLY_BUDGET, TARGET_ACOS } from "@m19-board/models/MetricsDef";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BaseChartDirective } from "ng2-charts";
import { BehaviorSubject, combineLatest, map, Observable, of, shareReplay, startWith, Subject, switchMap } from "rxjs";
import { DateAggreationComponent } from "../switch-button/date-aggregation-switch-button.component";
import { ChartData, DataSetConfiguration } from "./chart-modal.component";
import { DateRangeSelectorComponent } from "@m19-board/date-range-selector/date-range-selector.component";

@Component({
  selector: "chart-renderer",
  template: ` <div *transloco="let t">
    @if (loading()) {
      <div class="flex w-full items-center justify-center">
        <ISpinner [display]="true"></ISpinner>
      </div>
    } @else {
      <div class="mb-2 flex flex-row justify-end">
        <app-date-range-selector />
      </div>
      <div>
        <!-- TODO: review the displayMode toggle inputs in metric-selector -->
        <app-metric-selector
          [data]="(totalData() | async)!"
          [previousPeriodData]="(totalPreviousData() | async)!"
          [pageMetrics]="$any(metrics())"
          (chartMetricsChanges)="selectMetrics($event)"
          [hasManagedToggle]="displayManagedUnmanagedMetricsToggle()"
          [displayMode]="(this.managedUnmanagedDisplayMode$ | async) ? 'Managed/Unmanaged' : 'Total'"
          (displayModeChange)="selectManagedUnmanagedDisplayMode($any($event))"
          [localStorageKey]="localStorageKey()"
        ></app-metric-selector>
      </div>
      <div class="my-3 flex flex-row justify-between">
        <div class="flex flex-auto items-center gap-2">
          @if (annotations$()) {
            <div class="flex flex-auto items-center">
              <mat-slide-toggle
                color="primary"
                [checked]="displayEventAnnotation$ | async"
                (change)="toggleEventAnnotation($event)"
                [disabled]="disableEventAnnotation$ | async"
                class="mr-4"
                ><span class="events-toggle">{{ t("chart-renderer.events") }}</span></mat-slide-toggle
              >

              <activity-event-filters
                class="flex-1"
                [displayEventAnnotation]="(displayEventAnnotation$ | async) ?? false"
                [disableEventAnnotation]="(disableEventAnnotation$ | async) ?? true"
                [allEventAnnotationTypes]="allEventAnnotationTypes"
                [allUsers]="(allUsers | async) ?? []"
              />
            </div>
          }
          <div class="flex items-center justify-end">
            <app-date-aggreation-switch-button
              [selected]="(dateAggregation | async)!"
              (dateAggSelected)="selectAggregation($event)"
              [displayTitle]="false"
            ></app-date-aggreation-switch-button>
          </div>
        </div>
      </div>
      @if (dataSet) {
        <canvas
          baseChart
          style="height: 100px"
          class="chart"
          [datasets]="dataSet.chartDataSet"
          type="line"
          [labels]="dataSet.labels"
          [options]="dataSet.lineChartOptions"
        >
        </canvas>
      }
    }
  </div>`,
  standalone: true,
  imports: [
    CommonModule,
    DateAggreationComponent,
    BaseChartDirective,
    MetricSelectorComponent,
    SpinnerComponent,
    MatSlideToggleModule,
    ActivityEventFiltersComponent,
    TranslocoDirective,
    DateRangeSelectorComponent,
  ],
})
@UntilDestroy()
export class ChartRendererComponent<T extends CurrencyStat> implements OnInit {
  private readonly translocoService = inject(TranslocoService);
  private readonly activityService = inject(ActivityService);
  private readonly userSelectionService = inject(UserSelectionService);

  readonly locale = input.required<string>();
  readonly currency = input.required<Currency>();
  readonly annotations$ = input<Observable<DataSetEventAnnotation[]>>();
  readonly dataSetConfiguration = input.required<DataSetConfiguration<T>>();
  readonly chartData = input.required<Observable<ChartData<T>>>();
  readonly selectedMetrics = input.required<Metric<T>[]>();
  readonly metrics = input.required<Metric<T>[]>();
  readonly displayManagedUnmanagedMetricsToggle = input<boolean>(false);
  readonly displayManagedUnmanagedMetrics = input<boolean>(false);
  readonly localStorageKey = input.required<MetricsSelectorLocalStorageKey>();
  readonly strategyTargetHistory = input<boolean>(false);

  readonly totalData = computed(() =>
    this.chartData().pipe(
      map((data) => data.totalData),
      startWith({} as T),
    ),
  );
  readonly totalPreviousData = computed(() =>
    this.chartData().pipe(
      map((data) => data.totalPreviousData),
      startWith({} as T),
    ),
  );
  readonly loading = signal(true);
  readonly dateAggregation = new BehaviorSubject(DateAggregation.daily);
  readonly selectedMetrics$ = new Subject<Metric<T>[]>();
  readonly displayEventAnnotation$ = new BehaviorSubject(false);
  readonly disableEventAnnotation$ = this.dateAggregation.pipe(map((agg) => agg !== DateAggregation.daily));
  readonly allEventAnnotationTypes: Option<ActivityEventType>[] = this.activityService.allActivityEventTypesOptions;
  readonly allUsers: Observable<Option<string>[]> = this.activityService.allUsersOptions$;
  readonly managedUnmanagedDisplayMode$ = new Subject<boolean>();

  dataSet: DataSet<T> | undefined;

  ngOnInit(): void {
    const dataSetConfiguration = this.dataSetConfiguration();
    this.dataSet = new DataSet<T>(
      dataSetConfiguration.aspectRatio ?? 3,
      this.selectedMetrics(),
      [...dataSetConfiguration.aggregationFunction],
      this.translocoService,
    );
    this.dataSet.locale = this.locale();
    this.dataSet.currency = this.currency();
    this.dataSet.metricsOnSameScale = dataSetConfiguration.metricsOnSameScale ?? [];
    combineLatest([
      this.chartData().pipe(
        map((chartData) => {
          if (this.strategyTargetHistory()) {
            let acosTarget = false;
            let monthlyBudget = false;
            let dailyBudget = false;
            let minDailySpend = false;
            for (const data of chartData.data) {
              if (!isStrategyHistoryConfig(data)) {
                continue;
              }
              if (data.acosTarget) {
                acosTarget = true;
              }
              if (data.monthlyBudget) {
                monthlyBudget = true;
              }
              if (data.dailyBudget) {
                dailyBudget = true;
              }
              if (data.minDailySpend) {
                minDailySpend = true;
              }
            }
            return { ...chartData, acosTarget, monthlyBudget, dailyBudget, minDailySpend };
          }
          return chartData as ChartData<T> & {
            acosTarget?: boolean;
            monthlyBudget?: boolean;
            dailyBudget?: boolean;
            minDailySpend?: boolean;
          };
        }),
        shareReplay(1),
      ),
      this.dateAggregation,
      this.selectedMetrics$,
      this.userSelectionService.dateRange$,
      this.userSelectionService.periodComparison$,
      this.displayEventAnnotation$.pipe(switchMap((d) => (d ? this.annotations$() : undefined) ?? of([]))),
      this.managedUnmanagedDisplayMode$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([
          chartData,
          dateAggregation,
          selectedMetrics,
          dateRange,
          periodComparison,
          annotations,
          managedUnmanagedDisplayMode,
        ]) => {
          this.loading.set(false);
          const metrics: Metric<T>[] = this.extractMetrics(managedUnmanagedDisplayMode, selectedMetrics, chartData);

          this.dataSet!.buildDataSet(
            chartData.data,
            metrics,
            dateAggregation,
            {
              minDate: dateRange[0],
              maxDate: dateRange[1],
            },
            periodComparison?.period
              ? {
                  data: chartData.previousData ?? [],
                  period: periodComparison?.period,
                }
              : undefined,
            annotations,
          );
        },
      );
    this.managedUnmanagedDisplayMode$.next(this.displayManagedUnmanagedMetrics());
    this.selectedMetrics$.next(this.selectedMetrics());
  }

  private extractMetrics(
    managedUnmanagedDisplayMode: boolean,
    selectedMetrics: Metric<T>[],
    chartData: ChartData<T> & {
      acosTarget?: boolean;
      monthlyBudget?: boolean;
      dailyBudget?: boolean;
      minDailySpend?: boolean;
    },
  ) {
    const metrics: Metric<T>[] = [];
    if (managedUnmanagedDisplayMode) {
      for (const metric of selectedMetrics) {
        const splitMetrics = M19_METRICS.find((m) => m.key === metric);
        if (splitMetrics) {
          metrics.push(splitMetrics.op);
          metrics.push(splitMetrics.un);
        } else {
          metrics.push(metric);
        }
      }
    } else {
      metrics.push(...selectedMetrics);
    }
    if (this.strategyTargetHistory()) {
      if (metrics.includes(ACOS) && chartData.acosTarget == true) {
        metrics.push(TARGET_ACOS);
      }
      if (metrics.includes(COST)) {
        if (chartData.minDailySpend == true) {
          metrics.push(MIN_DAILY_SPEND);
        }
        if (chartData.dailyBudget == true) {
          metrics.push(DAILY_BUDGET);
        }
        if (chartData.monthlyBudget == true) {
          metrics.push(MONTHLY_BUDGET);
        }
      }
    }
    return metrics;
  }

  selectMetrics(metrics: Metric<T>[]): void {
    this.selectedMetrics$.next(metrics);
  }

  selectAggregation(dateAggregation: DateAggregation): void {
    this.dateAggregation.next(dateAggregation);
  }

  toggleEventAnnotation(change: MatSlideToggleChange): void {
    this.displayEventAnnotation$.next(change.checked);
  }

  selectManagedUnmanagedDisplayMode(displayMode: "Total" | "Managed/Unmanaged") {
    if (!this.displayManagedUnmanagedMetricsToggle()) {
      return;
    }
    this.managedUnmanagedDisplayMode$.next(displayMode === "Managed/Unmanaged");
  }
}

type StrategyConfigHistory = {
  acosTarget?: number;
  monthlyBudget?: number;
  dailyBudget?: number;
  minDailySpend?: number;
};

function isStrategyHistoryConfig(arg: unknown): arg is StrategyConfigHistory {
  return (
    (arg as StrategyConfigHistory).acosTarget !== undefined ||
    (arg as StrategyConfigHistory).monthlyBudget !== undefined ||
    (arg as StrategyConfigHistory).dailyBudget !== undefined ||
    (arg as StrategyConfigHistory).minDailySpend !== undefined
  );
}
