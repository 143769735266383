import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, ContentChild, EventEmitter, Input, input, Output, TemplateRef } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'ICheckbox',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgTemplateOutlet],
  templateUrl: './icheckbox.component.html',
})
export class ICheckboxComponent {
  inputId = input<string>();
  inputName = input<string>();
  label = input<string>();
  disabled = input(false);
  tooltipValue = input<string>();
  control = input<FormControl<boolean>>();
  testid = input<string>();

  indeterminate = input(false);

  @Input() value = false;
  @Output() valueChange = new EventEmitter<boolean>();

  @ContentChild('labelSlot', { static: false }) labelSlot: TemplateRef<any> | undefined;

  idValue = computed(() => {
    return this.inputId() ?? Math.random().toString(36).substring(2, 15);
  });

  updateValue(event: Event) {
    const val = (event.target as HTMLInputElement).checked;
    this.valueChange.emit(val);
  }
}
