import { NgClass } from '@angular/common';
import { Component, computed, ElementRef, input, model, OnInit, output, viewChildren } from '@angular/core';

export interface Itab<T = any> {
  label: string;
  icon?: string;
  value?: T;
  disabled?: boolean;
  badge?: string;
}

@Component({
  selector: 'ITabs',
  standalone: true,
  imports: [NgClass],
  templateUrl: './itabs.component.html',
})
export class ITabsComponent<T = any> implements OnInit {
  tabRefs = viewChildren<ElementRef>('tab');

  tabs = input<Itab<T>[]>([]);
  activeTab = model<number>(0);

  activeTabChange = output<number>();

  underlineWidth = computed(() => {
    const currentTab = this.tabRefs()[this.activeTab()];
    return currentTab ? currentTab.nativeElement.offsetWidth : 0;
  });

  underlineLeft = computed(() => {
    const currentTab = this.tabRefs()[this.activeTab()];
    return currentTab ? currentTab.nativeElement.offsetLeft : 0;
  });

  ngOnInit() {
    this.activeTabChange.emit(this.activeTab());
  }

  onTabClick(index: number) {
    this.activeTab.set(index);
    this.activeTabChange.emit(index);
  }
}
