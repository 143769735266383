// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Marketplace {
    AE = 'AE',
    AU = 'AU',
    BE = 'BE',
    BR = 'BR',
    CA = 'CA',
    DE = 'DE',
    EG = 'EG',
    ES = 'ES',
    FR = 'FR',
    IT = 'IT',
    IN = 'IN',
    JP = 'JP',
    MX = 'MX',
    NL = 'NL',
    PL = 'PL',
    SA = 'SA',
    SE = 'SE',
    SG = 'SG',
    TR = 'TR',
    UK = 'UK',
    US = 'US',
    ZA = 'ZA'
}

