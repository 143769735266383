import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";
import { AlgoMode, Currency } from "@front/m19-api-client";
import { currencyRateToEuro } from "@front/m19-utils";
import { AlgoModeForm } from "@m19-board/shared/algo-mode-selection/algo-mode-selection.component";

type AlgoModeValidatorErrors = {
  [key in keyof AlgoModeForm]?: string | null;
};

export function dailyBudgetLowerBound(currencyCode: string): number {
  return Math.ceil(1 / currencyRateToEuro(currencyCode as Currency));
}

export function isDailyBudgetLowerInvalid(currencyCode: string, dailyBudget: number, suggestedBid: number) {
  if (dailyBudget < dailyBudgetLowerBound(currencyCode)) {
    return { dailyBudgetBound: true };
  } else if (dailyBudget < 5 * suggestedBid) {
    return { fiveTimesGreater: true };
  }
  return null;
}

// validate the target depending on the selected algorithm
export function algorithmValidator(currencyCode: string, minAllowedBid: number, targetOnlyMode = false): ValidatorFn {
  const res = (form: FormGroup<AlgoModeForm>): AlgoModeValidatorErrors | null => {
    const errors: AlgoModeValidatorErrors = {};
    const dailyBudgetError = isDailyBudgetLowerInvalid(
      currencyCode,
      form.controls.dailyBudget.value!,
      form.controls.suggestedBid.value!,
    );
    const algoMode = form.controls.algoMode.value;

    if (algoMode === AlgoMode.ACOS_TARGET) {
      const toReset = [form.controls.monthlyBudget, form.controls.suggestedBid];
      if (!targetOnlyMode) {
        toReset.push(form.controls.dailyBudget);
      } else {
        if (form.controls.dailyBudget.value && dailyBudgetError?.dailyBudgetBound) {
          errors.dailyBudget = "algo-mode-selection.min_daily_budget";
        }
        form.controls.dailyBudget.setErrors(errors.dailyBudget ? errors : null);
      }
      resetFormControls(toReset);

      const acosTargetControl = form.controls.acosTarget;
      if (!acosTargetControl.value || isNaN(acosTargetControl.value)) {
        errors.acosTarget = "algo-mode-selection.acos_target_required";
      } else if (acosTargetControl.value <= 0 || acosTargetControl.value > 200) {
        errors.acosTarget = "algo-mode-selection.acos_range";
      }

      acosTargetControl.setErrors(errors.acosTarget ? errors : null);

      return errors;
    } else if (algoMode === AlgoMode.MONTHLY_BUDGET_TARGET) {
      resetFormControls([form.controls.acosTarget, form.controls.dailyBudget, form.controls.suggestedBid]);

      const monthlyBudget = form.controls.monthlyBudget;
      if (!monthlyBudget.value || isNaN(monthlyBudget.value)) {
        errors.monthlyBudget = "algo-mode-selection.monthly_budget_target_required";
      } else if (monthlyBudget.value <= 0) {
        errors.monthlyBudget = "switch-target-algo-modal.positive_budget";
      }
      monthlyBudget.setErrors(errors.monthlyBudget ? errors : null);
      return errors;
    } else if (algoMode === AlgoMode.PRODUCT_LAUNCH) {
      resetFormControls([form.controls.acosTarget, form.controls.monthlyBudget]);

      const dailyBudget = form.controls.dailyBudget;
      const suggestedBid = form.controls.suggestedBid;

      if (dailyBudgetError?.dailyBudgetBound) {
        errors.dailyBudget = "algo-mode-selection.min_daily_budget";
      } else if (dailyBudgetError?.fiveTimesGreater) {
        errors.dailyBudget = "algo-mode-selection.5_time_greater";
      }
      dailyBudget.setErrors(errors.dailyBudget ? errors : null);

      if (suggestedBid.value! < minAllowedBid) {
        errors.suggestedBid = "algo-mode-selection.greater_than_min";
      }
      suggestedBid.setErrors(errors.suggestedBid ? errors : null);
      return errors;
    }

    return null;
  };

  return res as ValidatorFn;
}

export function resetFormControls(controls: AbstractControl[]) {
  for (const c of controls) {
    c.setErrors(null);
    c.setValue(undefined, { emitEvent: false, onlySelf: true });
  }
}
