import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { Currency } from "@front/m19-api-client";
import { Metric } from "@front/m19-metrics";
import { CurrencyStat } from "@front/m19-models";
import { DataSetEventAnnotation, MetricsSelectorLocalStorageKey } from "@front/m19-services";
import { MODAL_DATA, ModalRef } from "@front/m19-ui";
import { AggregationFunction } from "@front/m19-utils";
import { Observable } from "rxjs";
import { ChartRendererComponent } from "./chart-renderer.component";

@Component({
  template: `<div class="min-h-[50vh] min-w-[80vw]">
    <chart-renderer
      [locale]="modalData.locale"
      [currency]="modalData.currency"
      [annotations$]="modalData.annotations$"
      [dataSetConfiguration]="modalData.dataSetConfiguration"
      [chartData]="modalData.chartData$"
      [selectedMetrics]="modalData.selectedMetrics"
      [metrics]="modalData.metrics"
      [displayManagedUnmanagedMetricsToggle]="modalData.displayManagedUnmanagedMetricsToggle ?? false"
      [displayManagedUnmanagedMetrics]="modalData.displayManagedUnmanagedMetrics ?? false"
      [localStorageKey]="modalData.localStorageKey"
      [strategyTargetHistory]="modalData.strategyTargetHistory ?? false"
    ></chart-renderer>
  </div>`,
  standalone: true,
  imports: [CommonModule, ChartRendererComponent],
})
export class ChartModalComponent<T extends CurrencyStat> {
  readonly modalData = inject<ChartModalInputData<T>>(MODAL_DATA);
}

export interface ChartModalInputData<T> {
  locale: string;
  currency: Currency;
  annotations$?: Observable<DataSetEventAnnotation[]>;
  dataSetConfiguration: DataSetConfiguration<T>;
  chartData$: Observable<ChartData<T>>;
  selectedMetrics: Metric<T>[];
  metrics: Metric<T>[];
  displayManagedUnmanagedMetricsToggle?: boolean;
  displayManagedUnmanagedMetrics?: boolean;
  localStorageKey: MetricsSelectorLocalStorageKey;
  strategyTargetHistory?: boolean;
}

export interface DataSetConfiguration<T> {
  aggregationFunction: AggregationFunction[];
  aspectRatio?: number;
  metricsOnSameScale?: Metric<T>[][];
}

export type ChartData<T> = {
  data: T[];
  previousData?: T[];
  totalData: T;
  totalPreviousData?: T;
};
