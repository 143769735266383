<div [class]="alertClasses()" [@bounce] [@.disabled]="!animated()">
  <div class="flex gap-3">
    <span [class]="icon()" class="h-5 w-5 flex-shrink-0"></span>

    <div class="flex flex-1 flex-col justify-between" data-testid="alert">
      <div class="flex w-full items-center justify-between">
        <span class="text-sm font-medium" [innerHTML]="title()"></span>
        @if (collapsable()) {
          <IButton
            icon="icon-[mdi--chevron-down]"
            class="duration-75"
            [ngClass]="{ '-rotate-90': !isOpen() }"
            [color]="color()"
            variant="ghost"
            size="xs"
            (onClick)="isOpen.set(!isOpen())"
          />
        }
      </div>
      <IAccordion [isOpen]="isOpen()">
        <ng-content />
      </IAccordion>
    </div>
  </div>
</div>
