import { Component, input } from '@angular/core';

@Component({
  selector: 'ISpinner',
  template: `@if (display()) {
    <span
      class="border-main-500 box-border inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-b-transparent border-opacity-100"
    ></span>
  }`,
  styleUrls: ['./spinner.component.css'],
  standalone: true,
  imports: [],
})
export class SpinnerComponent {
  display = input(true);
}
