import { Injectable } from '@angular/core';
import { Marketplace } from '@front/m19-api-client';
import { InventoryStats } from '@front/m19-models/InventoryStats';
import { AccountMarketplaceLazyReadOnlyCache } from '@front/m19-utils';
import { Observable, of } from 'rxjs';
import { AsinService, StatsService, UserSelectionService } from '.';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  private inventorySellerStatsCache: AccountMarketplaceLazyReadOnlyCache<Observable<InventoryStats[]>>;

  constructor(
    private asinService: AsinService,
    private statsService: StatsService,
    private userSelectionService: UserSelectionService,
  ) {
    this.inventorySellerStatsCache = new AccountMarketplaceLazyReadOnlyCache((accountId, marketplace) => {
      return accountId.startsWith('ENTITY')
        ? of(<InventoryStats[]>[])
        : this.asinService.getInventoryStats(
            accountId,
            marketplace,
            this.statsService.getSellerLastOrders(accountId, marketplace),
            this.userSelectionService.selectedCurrency$,
          );
    });
  }

  public getSellerInventoryStats(accountId: string, marketplace: Marketplace): Observable<InventoryStats[]> {
    return this.inventorySellerStatsCache.getValue(accountId, marketplace);
  }
}
