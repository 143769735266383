// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AsinRank
 */
export interface AsinRank {
    /**
     * @type {string}
     * @memberof AsinRank
     */
    asin?: string;
    /**
     * @type {string}
     * @memberof AsinRank
     */
    type?: AsinRankTypeEnum;
    /**
     * @type {number}
     * @memberof AsinRank
     */
    reviewScoreTenth?: number;
    /**
     * @type {number}
     * @memberof AsinRank
     */
    reviews?: number;
    /**
     * @type {number}
     * @memberof AsinRank
     */
    priceInCent?: number;
    /**
     * @type {number}
     * @memberof AsinRank
     */
    basePriceInCent?: number;
    /**
     * @type {string}
     * @memberof AsinRank
     */
    brand?: string;
    /**
     * @type {string}
     * @memberof AsinRank
     */
    badge?: string;
    /**
     * @type {boolean}
     * @memberof AsinRank
     */
    isPrime?: boolean;
    /**
     * @type {boolean}
     * @memberof AsinRank
     */
    hasCoupon?: boolean;
    /**
     * @type {boolean}
     * @memberof AsinRank
     */
    hasDeal?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum AsinRankTypeEnum {
    ORGANIC = 'ORGANIC',
    SP = 'SP',
    SB = 'SB'
}

