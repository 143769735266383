import { NgClass } from '@angular/common';
import { Component, computed, EventEmitter, HostBinding, input, Output } from '@angular/core';

@Component({
  selector: 'IToggle',
  standalone: true,
  imports: [NgClass],
  templateUrl: './itoggle.component.html',
})
export class IToggleComponent {
  checked = input<boolean | undefined>();
  size = input<'sm' | 'md' | 'lg'>('md');
  loading = input<boolean>(false);
  disabled = input<boolean>(false);

  @HostBinding('class') get HeadingClass() {
    return 'inline-flex';
  }

  @Output() checkedChange = new EventEmitter<boolean | undefined>();

  checkedValue = computed(() => !!this.checked());

  containerClass = computed(() => {
    const bgClass = this.checkedValue() ? 'bg-main-500 dark:bg-main-400' : 'bg-gray-200 dark:bg-gray-700';

    let sizeClass = '';
    switch (this.size()) {
      case 'sm':
        sizeClass = `h-4 w-7`;
        break;
      case 'md':
        sizeClass = `h-5 w-9`;
        break;
      case 'lg':
        sizeClass = `h-6 w-11`;
        break;
    }

    return `${bgClass} ${sizeClass}`;
  });

  innerClass = computed(() => {
    let translateFactorClass = '';
    switch (this.size()) {
      case 'sm':
        translateFactorClass = 'translate-x-3';
        break;
      case 'md':
        translateFactorClass = 'translate-x-4';
        break;
      case 'lg':
        translateFactorClass = 'translate-x-5';
        break;
    }

    return this.checkedValue() ? translateFactorClass : 'translate-x-0';
  });

  check() {
    this.checkedChange.emit(!this.checkedValue());
  }
}
