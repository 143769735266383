<div class="flex flex-wrap gap-2" *transloco="let t">
  <IDropdown
    [showSubContent]="!!filterBeingAdded()"
    [showContent]="!filterBeingAdded()"
    popperWidth="15"
    (dropdownClosed)="backToFiltersMenu()"
  >
    <div class="relative">
      <IButton
        [label]="t('filter-tags.add_filters')"
        [rounded]="true"
        color="white"
        icon="icon-[mdi--filter-variant]"
        size="sm"
      />
    </div>
    <div ngProjectAs="content">
      <ul class="mb-0 border-b border-neutral-200 p-1 last:border-b-0">
        @for (filter of notSelectedFilters(); track filter.label) {
          <li
            (click)="selectFilter(filter, $event)"
            class="flex cursor-pointer items-center justify-between gap-2 rounded-md px-2 py-1.5 hover:bg-neutral-100"
          >
            <span class="truncate">{{ filter.label }} </span>
          </li>
        }
      </ul>
    </div>
    <div ngProjectAs="sub-content">
      <IMultiSelect
        [options]="filterBeingAdded()?.options() ?? []"
        [selected]="filterBeingAdded()?.selectedOptions ?? []"
        [withSelectAll]="!filterBeingAdded()?.singleValue"
        (selectedChange)="filterBeingAdded() && selectNewFilterValue($event)"
        [placeholder]="t('filter-tags.select_filter', [filterBeingAdded()?.label])"
        [floating]="false"
        by="value"
        searchable
        rounded
      >
        <ng-template #headerSlot>
          <div class="flex items-center justify-between py-1">
            <IButton
              size="xs"
              color="white"
              variant="link"
              [label]="t('common.back')"
              icon="icon-[material-symbols--chevron-left-rounded]"
              (onClick)="backToFiltersMenu($event)"
            ></IButton>
            <span class="mr-2">{{ filterBeingAdded()?.label }}</span>
          </div>
          @if (filterBeingAdded() && filterBeingAdded()?.excludeModeAllowed) {
            <div class="border-t px-1 py-2">
              <mat-radio-group
                [value]="filterBeingAdded()!.exclude"
                (change)="excludeFilter(filterBeingAdded()!, $event.value)"
                class="flex w-full gap-3 [&_.mdc-radio]:!p-0 [&_label]:m-0 [&_label]:ml-2"
              >
                <mat-radio-button disableRipple color="primary" class="radio-button-center" [value]="false">
                  <div class="text-sm">{{ t("filter-tags.include") }}</div>
                </mat-radio-button>
                <mat-radio-button disableRipple color="primary" class="radio-button-center" [value]="true">
                  <div class="text-sm">{{ t("filter-tags.exclude") }}</div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          }
        </ng-template>
      </IMultiSelect>
      <div class="flex justify-end border-t p-1">
        <IButton
          size="xs"
          [disabled]="!filterBeingAdded()?.selectedOptions?.length"
          [label]="t('common.confirm')"
          (onClick)="addFilter()"
        ></IButton>
      </div>
    </div>
  </IDropdown>
  @for (filter of selectedFilters(); track filter.label) {
    <IMultiSelect
      [options]="filter.options()"
      [selected]="filter.selectedOptions"
      [withSelectAll]="!filter.singleValue"
      (selectedChange)="selectFilterValue(filter, $event)"
      [placeholder]="t('filter-tags.select_filter', [filter.label])"
      popperWidth="250"
      by="value"
      searchable
      rounded
    >
      @if (filter.excludeModeAllowed) {
        <ng-template #headerSlot>
          <div class="px-1 pb-2 pt-1">
            <mat-radio-group
              [value]="filter.exclude"
              (change)="excludeFilter(filter, $event.value)"
              class="flex w-full gap-3 [&_.mdc-radio]:!p-0 [&_label]:m-0 [&_label]:ml-2"
            >
              <mat-radio-button disableRipple color="primary" class="radio-button-center" [value]="false">
                <div class="text-sm">{{ t("filter-tags.include") }}</div>
              </mat-radio-button>
              <mat-radio-button disableRipple color="primary" class="radio-button-center" [value]="true">
                <div class="text-sm">{{ t("filter-tags.exclude") }}</div>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </ng-template>
      }
      <ng-template #leadingSlot>
        <button
          class="flex items-center rounded-full bg-transparent p-[0.1rem] hover:bg-gray-200"
          matTooltip="{{ t('filter-tags.clear_filter') }}"
          (click)="clearFilter(filter); $event.stopPropagation()"
        >
          <span [class]="ICON_CLOSE + ' size-4 text-gray-500'"></span>
        </button>
        @if (filter.exclude) {
          <span class="text-sm font-medium uppercase text-red-500">{{ t("filter-tags.not") }}</span>
        }
      </ng-template>
      <ng-template #labelSlot>
        <span class="font-medium text-gray-500">
          {{ filter.label }}
        </span>
        <span> {{ t("filter-tags.selected_filter", { count: filter.selectedOptions.length }) }}</span>
      </ng-template>
    </IMultiSelect>
  }
</div>
