import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  ElementRef,
  EnvironmentInjector,
  inject,
  Injectable,
  InjectionToken,
  Injector,
  TemplateRef,
  Type,
  ViewRef,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IModalComponent } from '../components/imodal/imodal.component';
import { DOCUMENT } from '@angular/common';

export type ComponentType<T> = {
  new (...args: any[]): T;
};

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private readonly appRef = inject(ApplicationRef);
  private readonly injector = inject(EnvironmentInjector);
  private readonly container = inject(DOCUMENT).body;

  openModal<InputDataType, ResultType, T = any>(
    template: TemplateRef<any>,
    modalOptions: ModalOptions<InputDataType>,
  ): ModalRef<ResultType, TemplateRef<any>>;

  openModal<InputDataType, ResultType, T = any>(
    component: ComponentType<T>,
    modalOptions: ModalOptions<InputDataType>,
  ): ModalRef<ResultType, ComponentType<T>>;

  openModal<InputDataType, ResultType, T = any>(
    componentOrTemplate: ComponentType<T> | TemplateRef<any>,
    modalOptions: ModalOptions<InputDataType>,
  ): ModalRef<ResultType, TemplateRef<any> | ComponentType<T>> {
    const onclose = () => {
      if (modal.location.nativeElement.parentElement) {
        modal.location.nativeElement.parentElement.removeChild(modal.location.nativeElement);
        this.appRef.detachView(modal.hostView);
      }

      modal.destroy();
      view.destroy();
    };

    const modalRef = new ModalRef<ResultType, ComponentType<T> | TemplateRef<any>>(componentOrTemplate, onclose);

    let attachOptions: AttachOptions;
    if (isTemplate(componentOrTemplate)) {
      attachOptions = this.openTemplate(componentOrTemplate, modalRef);
    } else {
      attachOptions = this.openComponent(componentOrTemplate, modalRef, modalOptions);
    }
    const view = attachOptions.view;
    const modal = this.createModal(modalRef, attachOptions, modalOptions);

    this.attachModal(modal, view);

    return modalRef;
  }

  private attachModal(modal: ComponentRef<IModalComponent>, view: ViewRef) {
    const container = getNativeElement(this.container);
    container.appendChild(modal.location.nativeElement);
    this.appRef.attachView(modal.hostView);
    this.appRef.attachView(view);
  }

  private openComponent<ResultType, T, InputDataType>(
    Component: Type<T>,
    modalRef: ModalRef<ResultType, ComponentType<T> | TemplateRef<any>>,
    modalOptions: ModalOptions<InputDataType>,
  ): AttachOptions {
    const componentRef = createComponent(Component, {
      elementInjector: Injector.create({
        providers: [
          {
            provide: ModalRef,
            useValue: modalRef,
          },
          {
            provide: MODAL_DATA,
            useValue: modalOptions.data,
          },
        ],
        parent: this.injector,
      }),
      environmentInjector: this.injector,
    });

    return {
      view: componentRef.hostView,
      projectableNodes: [[componentRef.location.nativeElement]],
    };
  }

  private openTemplate<ResultType>(template: TemplateRef<any>, modalRef: ModalRef<ResultType>): AttachOptions {
    const context = {
      $implicit: modalRef,
    };

    const view = template.createEmbeddedView(context);

    return {
      view,
      projectableNodes: [view.rootNodes],
    };
  }

  private createModal<T>(
    modalRef: ModalRef<any>,
    attachOptions: AttachOptions,
    modalOptions: ModalOptions<T>,
  ): ComponentRef<IModalComponent> {
    const modal = createComponent(IModalComponent, {
      elementInjector: Injector.create({
        providers: [
          {
            provide: ModalRef,
            useValue: modalRef,
          },
        ],
        parent: this.injector,
      }),
      environmentInjector: this.injector,
      projectableNodes: attachOptions.projectableNodes,
    });

    modal.instance.title = modalOptions.modalTitle;
    modal.instance.titleIcon = modalOptions.modalTitleIcon;
    modal.instance.fullScreen = !!modalOptions.fullScreen;
    modal.instance.closeable = modalOptions.closeable != false;
    modal.instance.position = modalOptions.position ?? 'center';

    return modal;
  }
}

function isTemplate(
  componentOrTemplate: TemplateRef<any> | ComponentType<any>,
): componentOrTemplate is TemplateRef<any> {
  return componentOrTemplate instanceof TemplateRef;
}

function getNativeElement(element: Element | ElementRef): Element {
  return element instanceof ElementRef ? element.nativeElement : element;
}

export class ModalRef<ResultType, Ref extends Type<any> | TemplateRef<any> = Type<any> | TemplateRef<any>> {
  public ref: Ref;

  private afterClosedSubject = new Subject<ResultType>();
  private afterCancelledSubject = new Subject<void>();

  private readonly onClose: () => void;

  constructor(ref: Ref, onClose: () => void) {
    this.ref = ref;
    this.onClose = onClose;
  }

  close(result?: ResultType): void {
    this.onClose();

    if (result !== undefined) {
      this.closeWithResult(result);
    } else {
      this.closeWithCancel();
    }
    this.afterClosedSubject.complete();
    this.afterCancelledSubject.complete();
  }

  private closeWithResult(result: ResultType): void {
    this.afterClosedSubject.next(result);
  }

  private closeWithCancel(): void {
    this.afterCancelledSubject.next();
  }

  afterClosed(): Observable<ResultType> {
    return this.afterClosedSubject.asObservable();
  }

  afterCancelled(): Observable<void> {
    return this.afterCancelledSubject.asObservable();
  }
}

export const MODAL_DATA = new InjectionToken('MODAL_DATA');

export interface ModalOptions<T> {
  modalTitle?: string;
  modalTitleIcon?: string;
  fullScreen?: boolean;
  adaptiveWidth?: boolean;
  closeable?: boolean;
  data?: T;
  position?: ModalPosition;
}

export type ModalPosition = 'center' | 'center-top';

export interface AttachOptions {
  view: ViewRef;
  projectableNodes: any[][];
}
