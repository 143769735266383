import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, DestroyRef, EventEmitter, inject, input, model, OnInit, Output } from '@angular/core';
import { Option, SelectPopperComponent } from '../popper/selectpopper.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

/**
 * ISelect is a wrapper around IMultiSelectComponent
 * It is used to select a single option
 */
@Component({
  selector: 'ISelect',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, TranslocoDirective],
  templateUrl: './iselect.component.html',
})
export class ISelectComponent<T> extends SelectPopperComponent<T> implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  @Output() selectedChange = new EventEmitter<Option<T>>();

  // @deprecated: use selectedValue instead
  selected = model<Option<T> | undefined | null>(undefined);

  selectedValue = input<T | undefined | null>(undefined);

  // TODO(joseph|hugo): remove when selected input is no longer used
  selectedValue$ = toObservable(this.selectedValue);

  selectedAppearance = input<'tick' | 'color'>('tick');

  label = computed(() => {
    return this.selected()?.[this.optionAttribute()];
  });

  hasSelectedOptions = computed(() => {
    return this.selected() !== undefined;
  });

  ngOnInit() {
    this.selectedValue$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      const optionWithValue = this.options().find((option) => option.value === value);
      if (optionWithValue) this.selected.set(optionWithValue);
    });
  }

  protected override handleKeyDown(event: KeyboardEvent): void {
    super.handleKeyDown(event, this.filteredOptionsWithCategories());
    if (event.key === 'Enter') {
      event.preventDefault();
      this.selectOption(this.filteredOptionsWithCategories()[this.activeIndex().i].options[this.activeIndex().j]);
    }
  }

  isSelected(option: Option<T>): boolean {
    return this.selected()?.[this._by()] === option[this._by()];
  }

  selectOption(option: Option<T>) {
    if (option.disabled) return;
    this.selected.set(option);
    this.selectedChange.emit(option);
    this.hidePopper();
  }
}
