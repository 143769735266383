// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';

export interface GetAmcReportsRequest {
    accountId: string;
    marketplace: string;
    dspAdvertiserId?: string;
}

/**
 * no description
 */
export class AmcReportsApi extends BaseAPI {

    /**
     * Get available AMC Reports dates
     */
    getAmcReports({ accountId, marketplace, dspAdvertiserId }: GetAmcReportsRequest): Observable<Array<string>>
    getAmcReports({ accountId, marketplace, dspAdvertiserId }: GetAmcReportsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<string>>>
    getAmcReports({ accountId, marketplace, dspAdvertiserId }: GetAmcReportsRequest, opts?: OperationOpts): Observable<Array<string> | AjaxResponse<Array<string>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getAmcReports');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getAmcReports');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'marketplace': marketplace,
        };

        if (dspAdvertiserId != null) { query['dspAdvertiserId'] = dspAdvertiserId; }

        return this.request<Array<string>>({
            url: '/amcReports',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

}
