import { Injectable } from "@angular/core";
import { AccountMarketplace, CampaignType, Currency, Marketplace, Order } from "@front/m19-api-client";
import { AdStatsEx, OrderStats } from "@front/m19-models";
import {
  AccountSelectionService,
  AsinService,
  convertAdStatsToCurrency,
  FbaStorageFeeService,
  OrderService,
  StatsService,
  UserSelectionService,
} from "@front/m19-services";
import { CogsByAsin, DateAggregation, emptyOrderStat, marketplaceToCurrencyRate, Utils } from "@front/m19-utils";
import { combineLatest, map, Observable, shareReplay, switchMap, take, tap, zip } from "rxjs";
import { of } from "rxjs/internal/observable/of";
import { AggregatedOrderStats, buildOrderStats } from "../../../../../libs/m19-services/src/lib/utils/build-order-stat";

@Injectable({
  providedIn: "root",
})
export class ProfitService {
  readonly LAST_TWELVE_WEEKS_IN_DAYS = -84;

  private marketplace?: Marketplace;
  private dateRanges?: Date[]; // TODO: use moment instead of Date

  // public observables
  public orderStatsByAsin$: Observable<Map<string, OrderStats>>;
  public orderStatsByDate$: Observable<Map<string, OrderStats>>;
  public orderStatsByAsinDate$: Observable<Map<string, Map<string, OrderStats>>>;
  public orderStatsByAsinDateWithDailyAsinStats$: Observable<{
    orderStatsByAsinDate: Map<string, Map<string, OrderStats>>;
    previousOrderStatsByAsinDate: Map<string, Map<string, OrderStats>>;
    dailyAsinStats: AdStatsEx[];
    previousDailyAsinStats: AdStatsEx[];
  }>;
  public previousOrderStatsByAsin$: Observable<Map<string, OrderStats>>;
  public previousOrderStatsByDate$: Observable<Map<string, OrderStats>>;
  public previousOrderStatsByAsinDate$: Observable<Map<string, Map<string, OrderStats>>>;

  public last12weeksAsinOrderStats$: Observable<OrderStats[]>;

  constructor(
    accountSelection: AccountSelectionService,
    userSelectionService: UserSelectionService,
    statsService: StatsService,
    asinService: AsinService,
    orderService: OrderService,
    fbaStorageFeeService: FbaStorageFeeService,
  ) {
    const allStats$ = combineLatest([
      accountSelection.singleAccountMarketplaceSelection$,
      userSelectionService.selectedDateRange$,
      userSelectionService.selectedCurrency$,
      userSelectionService.periodComparison$,
    ]).pipe(
      tap(([am, dr, _]) => {
        this.marketplace = am.marketplace;
        this.dateRanges = dr.map((d) => d.toDate());
      }),
      switchMap(([am, dr, currency, periodComparison]) =>
        zip([
          statsService.getDailyAsinStats(
            am.accountId,
            am.marketplace,
            Utils.formatMomentDate(dr[0]),
            Utils.formatMomentDate(dr[1]),
            am.useSourcingMetrics,
          ),
          periodComparison?.period
            ? statsService.getDailyAsinStats(
                am.accountId,
                am.marketplace,
                periodComparison.period[0],
                periodComparison.period[1],
                am.useSourcingMetrics,
              )
            : of([]),
          asinService.getCostOfGoods(am.accountId, am.marketplace),
          orderService.globalDataByDates$,
          fbaStorageFeeService.globalDataByDates$,
          orderService.previousGlobalDataByDates$,
          fbaStorageFeeService.previousGlobalDataByDates$,
          of(currency),
        ]),
      ),
    );

    const orderStats$ = allStats$.pipe(
      map(
        ([
          dailyAsinStats,
          previousDailyAsinStats,
          cogsByAsin,
          orders,
          fbaStorageFeesByDates,
          previousOrders,
          previousFbaStorageFeesByDates,
          currency,
        ]) => {
          return this.buildAllOrderStats(
            dailyAsinStats,
            previousDailyAsinStats,
            cogsByAsin,
            orders,
            fbaStorageFeesByDates,
            currency,
            this.dateRanges!,
            previousOrders,
            previousFbaStorageFeesByDates,
          );
        },
      ),
    );

    const orderStatsWithDailyAsinStats$ = allStats$.pipe(
      map(
        ([
          dailyAsinStats,
          previousDailyAsinStats,
          cogsByAsin,
          orders,
          fbaStorageFeesByDates,
          previousOrders,
          previousFbaStorageFeesByDates,
          currency,
        ]) => {
          const orderStats = this.buildAllOrderStats(
            dailyAsinStats,
            previousDailyAsinStats,
            cogsByAsin,
            orders,
            fbaStorageFeesByDates,
            currency,
            this.dateRanges!,
            previousOrders,
            previousFbaStorageFeesByDates,
          );

          return {
            ...orderStats,
            dailyAsinStats,
            previousDailyAsinStats,
          };
        },
      ),
    );

    this.orderStatsByAsin$ = orderStats$.pipe(
      map((x) => x.orderStats.orderStatsByAsin),
      shareReplay(1),
    );
    this.orderStatsByDate$ = orderStats$.pipe(
      map((x) => x.orderStats.orderStatsByDate),
      shareReplay(1),
    );
    this.orderStatsByAsinDate$ = orderStats$.pipe(
      map((x) => x.orderStats.orderStatsByAsinDate),
      shareReplay(1),
    );
    this.orderStatsByAsinDateWithDailyAsinStats$ = orderStatsWithDailyAsinStats$.pipe(
      map((stats) => ({
        orderStatsByAsinDate: stats.orderStats.orderStatsByAsinDate,
        previousOrderStatsByAsinDate: stats.previousOrderStats.orderStatsByAsinDate,
        dailyAsinStats: stats.dailyAsinStats,
        previousDailyAsinStats: stats.previousDailyAsinStats,
      })),
    );

    this.previousOrderStatsByAsin$ = orderStats$.pipe(
      map((x) => x.previousOrderStats.orderStatsByAsin),
      shareReplay(1),
    );
    this.previousOrderStatsByDate$ = orderStats$.pipe(
      map((x) => x.previousOrderStats.orderStatsByDate),
      shareReplay(1),
    );
    this.previousOrderStatsByAsinDate$ = orderStats$.pipe(
      map((x) => x.previousOrderStats.orderStatsByAsinDate),
      shareReplay(1),
    );

    const lastWeekStats = accountSelection.singleAccountMarketplaceSelection$.pipe(
      switchMap((am: AccountMarketplace) =>
        combineLatest<[AdStatsEx[], CogsByAsin, Order[], Map<string, Map<string, number>>, Currency]>([
          statsService
            .getLast12WeeksAsinStats(am.accountId, am.marketplace, !!am.useSourcingMetrics)
            .pipe(convertAdStatsToCurrency(userSelectionService.selectedCurrency$), take(1)),
          asinService.getCostOfGoods(am.accountId, am.marketplace),
          orderService.getOrders(
            am.accountId,
            am.marketplace,
            Utils.formatDateForApiFromToday(this.LAST_TWELVE_WEEKS_IN_DAYS),
            Utils.formatDateForApiFromToday(-1),
          ),
          fbaStorageFeeService.getFees(
            am.accountId,
            am.marketplace,
            Utils.formatDateForApiFromToday(this.LAST_TWELVE_WEEKS_IN_DAYS),
            Utils.formatDateForApiFromToday(-1),
          ),
          userSelectionService.selectedCurrency$,
        ]),
      ),
      map(([asinStats, cogsByAsin, orders, fbaStorageFeesByDates, currency]) => {
        const minDate = new Date(Utils.formatDateForApiFromToday(this.LAST_TWELVE_WEEKS_IN_DAYS));
        const maxDate = new Date(Utils.formatDateForApiFromToday(-1));

        return this.buildAllOrderStats(
          asinStats,
          [],
          cogsByAsin,
          orders,
          fbaStorageFeesByDates,
          currency,
          [minDate, maxDate],
          [],
          new Map(),
        );
      }),
      shareReplay(1),
    );
    this.last12weeksAsinOrderStats$ = lastWeekStats.pipe(
      map((x) => {
        const statsByAsinDate: OrderStats[] = [];
        for (const [asin, data] of x.orderStats.orderStatsByAsinDate) {
          for (const [date, stats] of data) {
            stats.asin = asin;
            statsByAsinDate.push(stats);
          }
        }
        return statsByAsinDate;
      }),
    );
  }

  private buildAllOrderStats(
    dailyAsinStats: AdStatsEx[],
    previousDailyAsinStats: AdStatsEx[],
    cogsByAsin: CogsByAsin,
    orders: Order[],
    fbaStorageFeesByDates: Map<string, Map<string, number>>,
    currency: Currency,
    dateRange: Date[],
    previousOrders: Order[],
    previousFbaStorageFeesByDates: Map<string, Map<string, number>>,
  ): {
    orderStats: AggregatedOrderStats;
    previousOrderStats: AggregatedOrderStats;
  } {
    const orderStats = buildOrderStats(cogsByAsin, orders, currency, this.marketplace!);
    const previousOrderStats = buildOrderStats(cogsByAsin, previousOrders, currency, this.marketplace!);

    const currencyRate = marketplaceToCurrencyRate(this.marketplace!, currency);

    this.setStorageFeesAndAdvertisingStats(
      fbaStorageFeesByDates,
      orderStats,
      currency,
      currencyRate,
      dateRange,
      dailyAsinStats,
    );
    this.setStorageFeesAndAdvertisingStats(
      previousFbaStorageFeesByDates,
      previousOrderStats,
      currency,
      currencyRate,
      dateRange,
      previousDailyAsinStats,
    );

    return {
      orderStats,
      previousOrderStats,
    };
  }

  private setStorageFeesAndAdvertisingStats(
    fbaStorageFeesByDates: Map<string, Map<string, number>>,
    orderStats: {
      orderStatsByAsin: Map<string, OrderStats>;
      orderStatsByDate: Map<string, OrderStats>;
      orderStatsByAsinDate: Map<string, Map<string, OrderStats>>;
    },
    currency: Currency,
    currencyRate: number,
    dateRange: Date[],
    asinStats: AdStatsEx[],
  ) {
    this.setFbaStorageFee(
      fbaStorageFeesByDates,
      orderStats.orderStatsByAsin,
      orderStats.orderStatsByDate,
      orderStats.orderStatsByAsinDate,
      currency,
      currencyRate,
      dateRange,
    );
    this.setAdvertisingStats(
      asinStats,
      orderStats.orderStatsByAsin,
      orderStats.orderStatsByDate,
      orderStats.orderStatsByAsinDate,
      currency,
      currencyRate,
    );
  }

  private setFbaStorageFee(
    fbaStorageFee: Map<string, Map<string, number>>,
    orderStatsByAsin: Map<string, OrderStats>,
    orderStatsByDate: Map<string, OrderStats>,
    orderStatsByAsinDate: Map<string, Map<string, OrderStats>>,
    currency: Currency,
    currencyRate: number,
    dateRange: Date[],
  ): void {
    const selectedStartDate = dateRange[0];
    const selectedEndDate = dateRange[1];
    const currencyRate_ = currencyRate;

    for (let date = selectedStartDate; date <= selectedEndDate; date = Utils.incDate(date, DateAggregation.daily, 1)!) {
      const currentDate = Utils.formatDateForApi(date);
      const asinStorageFee = fbaStorageFee.get(currentDate);
      if (!asinStorageFee) {
        continue;
      }
      asinStorageFee.forEach((value: number, key: string) => {
        const asin = key;
        const storageFee = value * currencyRate_ || 0;
        if (storageFee == 0) return;
        if (!orderStatsByAsin.has(asin)) orderStatsByAsin.set(asin, emptyOrderStat(asin, currentDate, currency));

        const newOrderStatByAsin = orderStatsByAsin.get(asin)!;
        newOrderStatByAsin.fee += storageFee;
        newOrderStatByAsin.profit! += storageFee;
        newOrderStatByAsin.fbaStorageFee! += storageFee;

        if (!orderStatsByDate.has(currentDate))
          orderStatsByDate.set(currentDate, emptyOrderStat(asin, currentDate, currency));

        const newOrderStatByDate = orderStatsByDate.get(currentDate)!;
        newOrderStatByDate.fee += storageFee;
        newOrderStatByDate.profit! += storageFee;
        newOrderStatByDate.fbaStorageFee! += storageFee;

        if (!orderStatsByAsinDate.has(asin)) {
          orderStatsByAsinDate.set(asin, new Map());
        }
        const orderStatByAsinDateInMap = orderStatsByAsinDate.get(asin)!.get(currentDate);
        if (orderStatByAsinDateInMap) {
          orderStatByAsinDateInMap.fee += storageFee;
          orderStatByAsinDateInMap.profit! += storageFee;
          orderStatByAsinDateInMap.fbaStorageFee! += storageFee;
        } else {
          const newOrderStat = emptyOrderStat(asin, currentDate, currency);
          newOrderStat.fee += storageFee;
          newOrderStat.profit! += storageFee;
          newOrderStat.fbaStorageFee! += storageFee;
          orderStatsByAsinDate.get(asin)!.set(currentDate, newOrderStat);
        }
      });
    }
  }

  private setAdvertisingStats(
    asinStats: AdStatsEx[],
    orderStatsByAsin: Map<string, OrderStats>,
    orderStatsByDate: Map<string, OrderStats>,
    orderStatsByAsinDate: Map<string, Map<string, OrderStats>>,
    currency: Currency,
    currencyRate: number,
  ): void {
    asinStats.forEach((adStat) => {
      const cost = adStat.currency == currency ? (adStat.cost ?? 0) : (adStat.cost ?? 0) * currencyRate;

      const newOrderStatByAsin =
        orderStatsByAsin.get(adStat.asin!) ?? emptyOrderStat(adStat.asin!, adStat.date!, currency);
      const newOrderStatByDate =
        orderStatsByDate.get(adStat.date!) ?? emptyOrderStat(adStat.asin!, adStat.date!, currency);

      // Set profit for order stat by asin/date
      // We don't need the advertising campaign type, as we only want to calculate profit and total ad cost
      if (!orderStatsByAsinDate.has(adStat.asin!)) orderStatsByAsinDate.set(adStat.asin!, new Map());

      let orderStatByAsinDate = orderStatsByAsinDate.get(adStat.asin!)!.get(adStat.date!);
      if (!orderStatByAsinDate) {
        const newOrderStat = emptyOrderStat(adStat.asin!, adStat.date!, currency);
        orderStatsByAsinDate.get(adStat.asin!)!.set(adStat.date!, newOrderStat);
        orderStatByAsinDate = newOrderStat;
      }
      orderStatByAsinDate.advertising -= cost;
      orderStatByAsinDate.profit! -= cost;

      switch (adStat.campaignType) {
        case CampaignType.SP:
          newOrderStatByAsin.spAdvertising! -= cost;
          newOrderStatByDate.spAdvertising! -= cost;
          orderStatByAsinDate.spAdvertising! -= cost;
          break;
        case CampaignType.SD:
        case CampaignType.SDR:
          newOrderStatByAsin.sdAdvertising! -= cost;
          newOrderStatByDate.sdAdvertising! -= cost;
          orderStatByAsinDate.sdAdvertising! -= cost;
          break;
        default:
          newOrderStatByAsin.sbAdvertising! -= cost;
          newOrderStatByDate.sbAdvertising! -= cost;
          orderStatByAsinDate.sbAdvertising! -= cost;
      }

      newOrderStatByAsin.advertising -= cost;
      newOrderStatByAsin.profit! -= cost;
      orderStatsByAsin.set(adStat.asin!, newOrderStatByAsin);

      newOrderStatByDate.advertising -= cost;
      newOrderStatByDate.profit! -= cost;
      orderStatsByDate.set(adStat.date!, newOrderStatByDate);
    });
  }
}
