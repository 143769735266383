import { Component, computed, inject, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerDay } from './idatepicker.component';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'IDatePickerCalendar',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './idatepicker-calendar.component.html',
})
export class IDatepickerCalendarComponent {
  private readonly translocoService = inject(TranslocoService);

  showPreviousMonthButton = input<boolean>(false);
  showNextMonthButton = input<boolean>(false);

  canNavigateToPreviousMonth = input<boolean>(true);
  canNavigateToNextMonth = input<boolean>(true);

  currentDate = input.required<Date>();
  minDate = input<Date | undefined>();
  maxDate = input<Date | undefined>();
  monthWeeks = input<DatePickerDay[][]>([]);

  availableMonths = computed<CalendarMonth[]>(() => {
    const maxDate = this.maxDate();
    const minDate = this.minDate();
    const isAfterMaxDate = (index: number) => maxDate && index > maxDate.getMonth();
    const isBeforeMinDate = (index: number) => minDate && index < minDate.getMonth();

    return this.MONTHS.map((_, index) => {
      const isDisabled =
        (maxDate?.getFullYear() === this.currentDate().getFullYear() && isAfterMaxDate(index)) ||
        (minDate?.getFullYear() === this.currentDate().getFullYear() && isBeforeMinDate(index));
      return {
        label: this.translocoService.translate(this.MONTHS[index]),
        disabled: !!isDisabled,
      };
    });
  });
  availableYears = input<number[]>([]);

  goToPreviousMonth = output();
  goToNextMonth = output();

  monthChange = output<number>();
  yearChange = output<number>();

  selectDate = output<Date>();
  cellHover = output<Date>();

  onMonthChange(event: Event) {
    const month: number = +(event.target as HTMLSelectElement).value + 1;
    this.monthChange.emit(month);
  }

  onYearChange(event: Event): void {
    const year: number = +(event.target as HTMLSelectElement).value;
    this.yearChange.emit(year);
  }

  private readonly MONTHS = [
    'date-selection.january',
    'date-selection.february',
    'date-selection.march',
    'date-selection.april',
    'date-selection.may',
    'date-selection.june',
    'date-selection.july',
    'date-selection.august',
    'date-selection.september',
    'date-selection.october',
    'date-selection.november',
    'date-selection.december',
  ];
  readonly WEEK_DAYS = [
    'date-selection.monday_short',
    'date-selection.tuesday_short',
    'date-selection.wednesday_short',
    'date-selection.thursday_short',
    'date-selection.friday_short',
    'date-selection.saturday_short',
    'date-selection.sunday_short',
  ];
}

interface CalendarMonth {
  label: string;
  disabled: boolean;
}
