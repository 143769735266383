<div #popperToggle class="p-0">
  <ng-content />
</div>
<div
  #popper
  class="invisible absolute left-0 top-0 z-[2000] rounded-lg border border-neutral-200 bg-white text-sm shadow-sm"
  [ngStyle]="{ width: popperWidth + 'rem' }"
>
  <div>
    <div #subContent>
      @if (_showSubContent()) {
        <ng-content select="sub-content"></ng-content>
      }
    </div>
  </div>
  <div>
    <div #content>
      @if (_showContent() && !_showSubContent()) {
        <ng-content select="content"></ng-content>
      }
    </div>
  </div>
  @if (!_showContent() && !_showSubContent()) {
    <ul class="mb-0 border-b border-neutral-200 p-1 last:border-b-0">
      @for (item of items(); track item.label) {
        <li
          (click)="onItemClick(item, $event)"
          class="flex items-center gap-2 rounded-md px-2 py-1.5"
          [ngClass]="{
            'hover:bg-red-50': item.variant === 'danger' && !item.disabled,
            'cursor-not-allowed opacity-50': item.disabled,
            'cursor-pointer hover:bg-neutral-100': !item.disabled,
          }"
        >
          @if (item.icon) {
            <span
              [class]="item.icon + ' h-4 w-4 text-gray-600'"
              [ngClass]="{ 'text-red-500': item.variant === 'danger' }"
            ></span>
          }
          <span
            class="select-none"
            [ngClass]="{
              'text-red-500': item.variant === 'danger',
              'text-gray-800': item.variant !== 'danger',
            }"
            >{{ item.label }}</span
          >
        </li>
      }
    </ul>
  }
</div>
