// environment variables used on board-test
import { M19Environment } from "./environment.model";

export const environment: M19Environment = {
  production: false,
  environment: "dev",
  apiEndPoint: "/api",
  spOauthRedirectDomain: "https://board-test.m19.com",
  ga: "G-V4MRSXVCNY",
  ga_whitelabel: "G-V4MRSXVCNY",
  stripePublicKey: "pk_test_6h7RjJK5obxo0YnZwy8m9V5e00YpzVaeoe",
  releaseNotesUrl: "https://www.notion.so/Release-notes-candidate-0cff2cb43699477e828a85ac12c5bf4f",
  build_timestamp: "2025-02-14.16h50", // replaced at build time by set-build-timestamp.js
  commit_hash: "2811a745fb5e9ae93fd08fc94029cab413a75a8e", // replaced at build time by set-build-timestamp.js
  logSpinnerTime: true,
  languageServer: "board-i18n",
};
