import { Marketplace } from '@front/m19-api-client';
import { statGroupByKey } from '@front/m19-services';
import moment from 'moment-timezone';
import { catchError, forkJoin, map, Observable, of, ReplaySubject, take } from 'rxjs';
import { AdStatsEx } from '../models';
import { mergeSeveralDates } from './statsUtils';

export type ByMarketplace<T> = Map<Marketplace, T>;
export type ByAsin<T> = Map<string, T>;
export type BySearchTerm<T> = Map<string, T>;
export type ByAccountMarketplace<T> = Map<string, T>;
export type AccountMarketplaceKey = string;
export type AccountMarketplaceOrganizationIdKey = string;
export type CogsByAsin = ByAsin<Array<[string, number]>>;

export function accountMarketplaceKey(accountId: string, marketplace: Marketplace | string, useSourcing?: boolean) {
  return `${accountId}:${marketplace}` + (useSourcing ? ':src' : '');
}

export function accountMarketplaceOrganizationKey(
  accountId: string,
  marketplace: Marketplace | string,
  organizationId: number,
) {
  return `${accountId}:${marketplace}:${organizationId}`;
}

export type ByAccountMarketplaceOrganization<T> = Map<string, T>;

type AccountMarketplaceCacheKey = { accountId: string; marketplace: Marketplace; useSourcingMetrics?: boolean };
type AccountMarketplaceOrganizationCacheKey = {
  accountId: string;
  marketplace: Marketplace;
  organizationId: number;
};
type DspCacheKey = { accountId: string; marketplace: Marketplace; dspAdvertiserId: string };

/**
 * A class used to cache data per key
 * The cache is a map of ReplaySubject so that the recent value can be replayed to new subscribers.
 * The cache value can be updated, deleted or errored. Subscribers will be notified of the change.
 * If cacheSize = 0, the cache has an unlimited size
 * If cacheSize > 0, the cache is limited in size and will evict the oldest key when the limit is reached
 */
export abstract class WritableCache<K, V> {
  private readonly cache = new Map<string, ReplaySubject<V>>();

  private readonly keys: string[] = [];

  protected abstract serializeKey(key: K): string;

  protected abstract loadKey(key: K): Observable<V>;

  constructor(private readonly cacheSize = 0) {}

  public get(key: K): Observable<V> {
    const keyStr = this.serializeKey(key);
    if (this.cache.has(keyStr)) {
      return this.cache.get(keyStr)!;
    }
    const subject = new ReplaySubject<V>(1);
    this.setInCache(keyStr, subject);
    this.keys.push(keyStr);
    this.loadKey(key)
      .pipe(take(1))
      .subscribe({
        next: (value) => {
          subject.next(value);
        },
        error: (error) => {
          subject.error(error);
          // delete the subject from the cache to force a reload
          this.cache.delete(keyStr);
          this.keys.splice(this.keys.indexOf(keyStr), 1);
        },
      });
    return subject;
  }

  private setInCache(keyStr: string, value: ReplaySubject<V>) {
    this.cache.set(keyStr, value);
    const newLength = this.keys.push(keyStr);
    // eviction policy here: remove the oldest key
    if (this.cacheSize > 0 && newLength > this.cacheSize) {
      const keyToDelete = this.keys.shift();
      if (keyToDelete) {
        this.cache.get(keyToDelete)!.complete();
        this.cache.delete(keyToDelete);
      }
    }
  }

  update(key: K, updateFn: (previous: V) => V) {
    const keyStr = this.serializeKey(key);
    if (!this.cache.has(keyStr)) {
      // nothing to update
      return;
    }
    this.cache
      .get(keyStr)!
      .pipe(take(1))
      .subscribe((value) => {
        try {
          const newValue = updateFn(value);
          this.cache.get(keyStr)!.next(newValue);
        } catch (e) {
          this.cache.get(keyStr)!.error(e);
          // delete the subject from the cache to force a reload
          this.cache.delete(keyStr);
          this.keys.splice(this.keys.indexOf(keyStr), 1);
        }
      });
  }

  error(key: K, error: any) {
    const keyStr = this.serializeKey(key);
    if (this.cache.has(keyStr)) {
      this.cache.get(keyStr)!.error(error);
      // delete the subject from the cache to force a reload
      this.cache.delete(keyStr);
      this.keys.splice(this.keys.indexOf(keyStr), 1);
    }
  }

  delete(key: K): boolean {
    const keyStr = this.serializeKey(key);
    if (this.cache.has(keyStr)) {
      this.cache.get(keyStr)!.complete();
      this.keys.splice(this.keys.indexOf(keyStr), 1);
      return this.cache.delete(keyStr);
    }
    return false;
  }
}

export class AccountMarketplaceWritableCache<V> extends WritableCache<AccountMarketplaceCacheKey, V> {
  constructor(
    private readonly loader: (accountId: string, markeplace: Marketplace) => Observable<V>,
    cacheSize = 0,
  ) {
    super(cacheSize);
  }

  protected serializeKey(key: AccountMarketplaceCacheKey): string {
    return accountMarketplaceKey(key.accountId, key.marketplace);
  }

  protected loadKey(key: AccountMarketplaceCacheKey): Observable<V> {
    return this.loader(key.accountId, key.marketplace);
  }
}

export class AccountMarketplaceOrganizationWritableCache<V> extends WritableCache<
  AccountMarketplaceOrganizationCacheKey,
  V
> {
  constructor(
    private readonly loader: (accountId: string, markeplace: Marketplace, organizationId: number) => Observable<V>,
    cacheSize = 0,
  ) {
    super(cacheSize);
  }

  protected serializeKey(key: AccountMarketplaceOrganizationCacheKey): string {
    return accountMarketplaceOrganizationKey(key.accountId, key.marketplace, key.organizationId);
  }

  protected loadKey(key: AccountMarketplaceOrganizationCacheKey): Observable<V> {
    return this.loader(key.accountId, key.marketplace, key.organizationId);
  }
}

/**
 * A class used to cache data per key
 * When there is a cache miss, the loader function is called to load the data
 * A max size can be set to limit the number of keys in the cache.
 * The cache can also be preloaded with a list of key->value
 */
export class LazyReadOnlyCache<K, V> {
  private readonly cache = new Map<string, V>();
  private readonly keys: string[] = [];
  constructor(
    private readonly loader: (key: K) => V,
    private readonly keySerializer: (key: K) => string,
    private readonly size: number = 0,
    preload: (() => IterableIterator<[K, V]>) | undefined = undefined,
  ) {
    if (preload) {
      for (const [key, value] of preload()) {
        const keyStr = this.keySerializer(key);
        this.cache.set(keyStr, value);
        if (this.size > 0) {
          this.keys.push(keyStr);
          if (this.keys.length > this.size) {
            break;
          }
        }
      }
    }
  }

  public has(key: K): boolean {
    return this.cache.has(this.keySerializer(key));
  }

  public get(key: K): V {
    const keyStr = this.keySerializer(key);
    if (!this.cache.has(keyStr)) {
      this.cache.set(keyStr, this.loader(key));
      if (this.size > 0) {
        this.keys.push(keyStr);
        if (this.keys.length > this.size) {
          const keyToDelete = this.keys.shift();
          if (keyToDelete) {
            this.cache.delete(keyToDelete);
          }
        }
      }
    }
    return this.cache.get(keyStr)!;
  }

  public getCacheSize() {
    return this.cache.size;
  }
}

export class AccountMarketplaceLazyReadOnlyCache<V> extends LazyReadOnlyCache<AccountMarketplaceCacheKey, V> {
  constructor(
    loader: (accountId: string, markeplace: Marketplace, useSourcingMetrics?: boolean) => V,
    size = 0,
    preload: (() => IterableIterator<[AccountMarketplaceCacheKey, V]>) | undefined = undefined,
  ) {
    super(
      ({ accountId, marketplace, useSourcingMetrics }) => loader(accountId, marketplace, useSourcingMetrics),
      (key) => accountMarketplaceKey(key.accountId, key.marketplace, key.useSourcingMetrics),
      size,
      preload,
    );
  }

  public getValue(accountId: string, marketplace: Marketplace, useSourcingMetrics?: boolean): V {
    return this.get({ accountId, marketplace, useSourcingMetrics });
  }
}

export class DspLazyReadOnlyCache<V> extends LazyReadOnlyCache<DspCacheKey, V> {
  constructor(
    loader: (accountId: string, markeplace: Marketplace, dspAdvertiserId: string) => V,
    size = 0,
    preload: (() => IterableIterator<[DspCacheKey, V]>) | undefined = undefined,
  ) {
    super(
      ({ accountId, marketplace, dspAdvertiserId }) => loader(accountId, marketplace, dspAdvertiserId),
      (key) => key.dspAdvertiserId,
      size,
      preload,
    );
  }

  public getValue(accountId: string, marketplace: Marketplace, dspAdvertiserId: string): V {
    return this.get({ accountId, marketplace, dspAdvertiserId });
  }
}

export class AggregatedLazyReadOnlyCache<V> extends LazyReadOnlyCache<
  { accountId: string; marketplace: Marketplace; useSourcingMetrics?: boolean }[],
  V
> {
  constructor(
    loader: (accountMarketplaces: { accountId: string; marketplace: Marketplace; useSourcingMetrics?: boolean }[]) => V,
  ) {
    super(
      loader,
      (key) => {
        const keys = key.map((k) => accountMarketplaceKey(k.accountId, k.marketplace, k.useSourcingMetrics)).sort();
        return keys.join(',');
      },
      1, // cache only one value by default
      undefined, // no preload
    );
  }
}

type QueryStatsCacheKey = {
  accountId: string;
  marketplace: Marketplace;
  minDate: string;
  maxDate: string;
};

/**
 * A class to cache query stats data
 * It caches the data per account-marketplace and date range
 *
 * In the constructor, pass a loader function that will load the data for a given account/marketplace/date range
 * The cache will do optimizations to chunk data in smaller date ranges (one month) if the requested date range is too large
 *
 */
export class QueryStatsCache {
  private static readonly datesIndex: Map<string, number> = new Map(); // map a date to a one-month period on the last 1 years
  private static readonly last2YearsMonths: { maxDate: string; minDate: string }[] = [];

  private readonly cache: LazyReadOnlyCache<QueryStatsCacheKey, Observable<AdStatsEx[]>>;

  constructor(loader: (key: QueryStatsCacheKey) => Observable<AdStatsEx[]>) {
    this.cache = new LazyReadOnlyCache(
      loader,
      (key) => `${key.accountId}:${key.marketplace}:${key.minDate}:${key.maxDate}`,
      100, // cache up to 100 keys
    );
    // init datesIndex
    if (QueryStatsCache.datesIndex.size === 0) {
      const today = moment().startOf('day');
      const s = moment(today).subtract(1, 'years');
      let currentMonthIndex = 0;
      let currentMonth = s.clone().startOf('month').format('YYYY-MM-DD');
      QueryStatsCache.last2YearsMonths.push({
        minDate: currentMonth,
        maxDate: s.clone().endOf('month').format('YYYY-MM-DD'),
      });
      while (s.isSameOrBefore(today, 'day')) {
        const minDate = s.clone().startOf('month').format('YYYY-MM-DD');
        if (currentMonth === minDate) {
          QueryStatsCache.datesIndex.set(s.format('YYYY-MM-DD'), currentMonthIndex);
        } else {
          currentMonthIndex += 1;
          currentMonth = minDate;
          let maxDate = s.clone().endOf('month');
          if (maxDate.isAfter(today)) {
            maxDate = today;
          }
          QueryStatsCache.last2YearsMonths.push({ minDate, maxDate: maxDate.format('YYYY-MM-DD') });
          QueryStatsCache.datesIndex.set(s.format('YYYY-MM-DD'), currentMonthIndex);
        }
        s.add(1, 'day');
      }
    }
  }

  public get(accountId: string, marketplace: Marketplace, minDate: string, maxDate: string): Observable<AdStatsEx[]> {
    const key = { accountId, marketplace, minDate, maxDate };
    if (this.cache.has(key)) {
      return this.cache.get(key);
    }
    // query the cache on smaller date ranges
    const maxMonthIndex = QueryStatsCache.datesIndex.get(maxDate);
    const minMonthIndex = QueryStatsCache.datesIndex.get(minDate);
    if (maxMonthIndex === undefined || minMonthIndex === undefined) {
      // invalid date range
      return of([]);
    }
    if (maxMonthIndex == minMonthIndex) {
      return this.cache.get(key);
    }
    const ranges: QueryStatsCacheKey[] = [];
    ranges.unshift({
      accountId,
      marketplace,
      minDate,
      maxDate: QueryStatsCache.last2YearsMonths[minMonthIndex].maxDate,
    });
    for (let i = minMonthIndex + 1; i < maxMonthIndex; i++) {
      ranges.unshift({
        accountId,
        marketplace,
        minDate: QueryStatsCache.last2YearsMonths[i].minDate,
        maxDate: QueryStatsCache.last2YearsMonths[i].maxDate,
      });
    }
    ranges.unshift({
      accountId,
      marketplace,
      minDate: QueryStatsCache.last2YearsMonths[maxMonthIndex].minDate,
      maxDate,
    });
    return forkJoin(ranges.map((r) => this.cache.get(r))).pipe(
      map((data) =>
        statGroupByKey(data.flat(), (d) => `${d.asin}_${d.queryType}_${d.query}`, mergeSeveralDates, false),
      ),
    );
  }
}

type TimeSeriesCacheConfig = {
  maxTimeSpan: number;
  maxChunkSize: number;
};

const DefaultTimeSeriesCacheConfig: TimeSeriesCacheConfig = {
  maxTimeSpan: 365 + 366, // two years
  maxChunkSize: 15, // 15d chunks by default
};

export class TimeSeriesCache<V extends object> {
  private static datesIndex: Map<string, number> = new Map(); // map a date to an index in the cache
  private static dates: string[] = []; // cache the dates

  private cache: (V | null)[]; // null means there is no data for this date
  public readonly config: TimeSeriesCacheConfig;

  constructor(
    private readonly loader: (start: string, end: string) => Observable<Map<string, V>>, // load data for a date range and return a date->data map
    config: Partial<TimeSeriesCacheConfig> = DefaultTimeSeriesCacheConfig,
  ) {
    this.config = { ...DefaultTimeSeriesCacheConfig, ...config };
    this.cache = new Array(this.config.maxTimeSpan);
  }

  public static dateToIndex(date: string): number {
    if (!TimeSeriesCache.datesIndex.has(date)) {
      const s = moment(date);
      const today = moment().startOf('day');
      if (s.isAfter(today, 'day')) {
        return -1; // future date are not allowed for the moment
      }
      let diff = today.diff(s, 'day');
      while (s.isSameOrBefore(today, 'day')) {
        const formatted = s.format('YYYY-MM-DD');
        TimeSeriesCache.datesIndex.set(formatted, diff);
        TimeSeriesCache.dates[diff] = formatted;
        s.add(1, 'day');
        diff -= 1;
        if (TimeSeriesCache.datesIndex.has(s.format('YYYY-MM-DD'))) {
          break;
        }
      }
    }
    return TimeSeriesCache.datesIndex.get(date)!;
  }

  public clear() {
    this.cache = new Array(this.config.maxTimeSpan);
  }

  public get(start: string, end: string): Observable<V[]> {
    const startIndex = TimeSeriesCache.dateToIndex(end);
    let endIndex = TimeSeriesCache.dateToIndex(start);
    if (endIndex >= this.cache.length) {
      endIndex = this.cache.length - 1;
    }
    if (
      startIndex < 0 ||
      endIndex < 0 ||
      startIndex > endIndex ||
      startIndex >= this.cache.length ||
      endIndex >= this.cache.length
    ) {
      // invalid date range
      return of([]);
    }
    const aux: ({ start: number; end: number } | V[])[] = [];
    let missingStart: number | undefined = undefined;
    for (let i = startIndex; i <= endIndex; i++) {
      if (this.cache[i] === undefined) {
        if (missingStart === undefined) {
          missingStart = i;
        } else if (this.config.maxChunkSize > 0 && i - missingStart >= this.config.maxChunkSize) {
          aux.unshift({ start: missingStart, end: i - 1 });
          missingStart = i;
        }
      } else {
        if (missingStart !== undefined) {
          aux.unshift({ start: missingStart, end: i - 1 });
          missingStart = undefined;
          if (this.cache[i] !== null) {
            aux.unshift([this.cache[i]!]);
          } else {
            aux.unshift([]);
          }
        } else {
          if (aux.length === 0) {
            aux.unshift([]);
          }
          if (this.cache[i] !== null) {
            (aux[0] as V[]).unshift(this.cache[i]!); // add at the beginning of the array
          }
        }
      }
    }
    if (missingStart !== undefined) {
      aux.unshift({ start: missingStart, end: endIndex });
    }
    return forkJoin(
      aux.map((rangeOrData) => {
        if (Array.isArray(rangeOrData)) {
          return of(rangeOrData);
        } else {
          const startStr = TimeSeriesCache.dates[rangeOrData.end];
          const endStr = TimeSeriesCache.dates[rangeOrData.start];
          return this.loader(startStr, endStr).pipe(
            take(1),
            catchError((err) => {
              // on error, return an empty array, but do not cache the error result
              // let the cache as is on error
              // eslint-disable-next-line no-console
              console.error(`Error loading time series for data span: ${startStr}-${endStr} - ` + err);
              return of(null);
            }),
            map((data) => {
              if (!data) {
                return [];
              }
              for (let i = rangeOrData.start; i <= rangeOrData.end; i++) {
                this.cache[i] = null;
              }
              const res: V[] = [];
              for (const [date, d] of data.entries()) {
                this.cache[TimeSeriesCache.dateToIndex(date)] = d;
                res.push(d);
              }
              return res;
            }),
          );
        }
      }),
    ).pipe(map((data) => data.flat()));
  }
}
