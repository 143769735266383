import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IMeterComponent } from "../ui/imeter/imeter.component";
import { SpendProgressBarComponent } from "./spend-progress-bar.component";

@NgModule({
  declarations: [SpendProgressBarComponent],
  exports: [SpendProgressBarComponent],
  imports: [CommonModule, IMeterComponent],
})
export class SpendProgressBarModule {}
