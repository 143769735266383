<ng-container *transloco="let t">
  <div class="flex w-full">
    <form [formGroup]="form" class="flex w-full flex-col gap-4">
      @for (mode of displayedAlgoModes(); track mode) {
        @let isSelected = form.controls.algoMode.value === mode;
        <div
          class="group w-full rounded-lg border border-gray-200 p-4 duration-150"
          [ngClass]="{
            'border-main-300': isSelected && !_targetOnlyMode(),
            'hover:bg-main-50 hover:border-main-300 cursor-pointer ': !isSelected && !_disabled(),
          }"
          [attr.data-testid]="'algo-mode-selection-' + mode"
          (click)="!isSelected && !_disabled() ? changeAlgoMode(mode) : null"
        >
          <div class="flex w-full flex-row justify-between">
            <div>
              <span class="select-none text-lg text-gray-800">{{ t(AlgoModeStrKey[mode].title) }}</span>
              <p class="m-0 select-none text-sm text-zinc-500">{{ t(AlgoModeStrKey[mode].description) }}</p>
            </div>
            @if (!_targetOnlyMode()) {
              <div>
                <div
                  class="flex h-5 w-5 items-center justify-center rounded-full border border-gray-200 bg-white"
                  [class.border-main-400]="isSelected"
                >
                  @if (isSelected) {
                    <span class="bg-main-500 h-3 w-3 rounded-full duration-150"></span>
                  }
                </div>
              </div>
            }
          </div>

          <IAccordion [isOpen]="isSelected">
            <div class="mt-4 border-t border-gray-200 px-1 pb-1 pt-4">
              @if (mode === AlgoMode.ACOS_TARGET) {
                <div class="flex flex-col gap-4">
                  <IInput
                    [control]="form.controls.acosTarget"
                    [label]="t('algo-mode-selection.acos_target')"
                    testid="acos-target"
                    [autoFocus]="true"
                    type="number"
                    [min]="1"
                    [max]="200"
                    [error]="t(form.controls.acosTarget.errors?.['acosTarget'])"
                  >
                    <ng-template #trailingSlot>
                      <span class="text-gray-400">%</span>
                    </ng-template>
                  </IInput>

                  @if (_targetOnlyMode()) {
                    <ng-container *ngTemplateOutlet="dailyBudgetInput"></ng-container>
                  }
                </div>
              } @else if (mode === AlgoMode.MONTHLY_BUDGET_TARGET) {
                <div class="flex flex-col gap-4">
                  @if (_targetOnlyMode()) {
                    <span class="block text-xs font-medium text-gray-700 dark:text-gray-200">
                      {{ t("strategy-page.current_spend") }}</span
                    >
                    <app-spend-progress-bar
                      class="w-full"
                      [currentMonthSpend]="currentMonthSpend()!"
                      [monthlyBudget]="form.controls.monthlyBudget.value!"
                      [endOfCurrentMonth]="endOfCurrentMonth()!"
                      [currencyCode]="currencyCode()"
                      [locale]="locale()!"
                    />
                  }

                  <IInput
                    [control]="form.controls.monthlyBudget"
                    [label]="t('overview-grid.monthly_budget')"
                    testid="monthly-budget"
                    type="number"
                    [min]="1"
                    [error]="t(form.controls.monthlyBudget.errors?.['monthlyBudget'])"
                  >
                    <ng-template #trailingSlot>
                      <span class="text-gray-400">
                        <IBadge [label]="currencyCode()" color="gray" />
                      </span>
                    </ng-template>
                  </IInput>

                  @if (_targetOnlyMode()) {
                    <div class="flex items-end gap-2">
                      <IInput
                        class="flex-1"
                        [control]="form.controls.nextMonthlyBudget"
                        [label]="t('switch-target-algo-modal.next_monthly_budget')"
                        type="number"
                        [min]="1"
                        [description]="nextMonth()"
                      >
                        <ng-template #trailingSlot>
                          <span class="text-gray-400">
                            <IBadge [label]="currencyCode()" color="gray" />
                          </span>
                        </ng-template>
                      </IInput>

                      <IButton
                        icon="icon-[mdi--undo-variant]"
                        color="white"
                        (onClick)="form.controls.nextMonthlyBudget.setValue(form.controls.monthlyBudget.value)"
                        tooltipValue="{{ t('switch-target-algo-modal.keep_mothly_budget_tooltip') }}"
                      />
                    </div>
                  }
                </div>

                @if (form.controls.monthlyBudget.value && form.controls.monthlyBudget.value! > 0) {
                  <div class="mt-4">
                    <IAlert type="warning">
                      {{
                        t("algo-mode-selection.budget_for_month", [
                          form.controls.monthlyBudget.value! | currency: currencyCode() : "symbol" : "1.2-2" : locale(),
                          endOfCurrentMonth() | date: "MMMM y" : undefined : locale(),
                        ])
                      }}
                    </IAlert>
                  </div>
                }
              } @else if (mode === AlgoMode.PRODUCT_LAUNCH) {
                <IInput
                  [control]="form.controls.suggestedBid"
                  [label]="t('switch-target-algo-modal.suggested_bid')"
                  testid="product-launch-bid"
                  type="number"
                  [min]="0"
                  [error]="t(form.controls.suggestedBid.errors?.['suggestedBid'], [this.minAllowedBid()!])"
                >
                  <ng-template #trailingSlot>
                    <span class="text-gray-400">
                      <IBadge [label]="currencyCode()" color="gray" />
                    </span>
                  </ng-template>
                </IInput>

                <div class="mt-4">
                  <ng-container *ngTemplateOutlet="dailyBudgetInput"></ng-container>
                </div>
              }
            </div>
          </IAccordion>
        </div>
      }
    </form>
  </div>

  <ng-template #dailyBudgetInput>
    <div class="flex items-end gap-2">
      <IInput
        class="flex-1"
        [control]="form.controls.dailyBudget"
        [label]="
          t('switch-target-algo-modal.average_daily_budget', {
            avg: form.controls.algoMode.value === AlgoMode.ACOS_TARGET && _targetOnlyMode(),
          })
        "
        testid="product-launch-budget"
        type="number"
        [min]="0"
        [error]="t(form.controls.dailyBudget.errors?.['dailyBudget'], [this.dailyBudgetLowerBound()!])"
      >
        <ng-template #trailingSlot>
          <span class="text-gray-400">
            <IBadge [label]="currencyCode()" color="gray" />
          </span>
        </ng-template>
      </IInput>
      @if (form.controls.algoMode.value === AlgoMode.ACOS_TARGET && _targetOnlyMode()) {
        <IButton
          tooltipValue="{{ t('switch-target-algo-modal.delete_daily_budget') }}"
          [disabled]="!form.controls.dailyBudget.value"
          [icon]="ICON_TRASH"
          color="white"
          (onClick)="deleteDailyBudget()"
        />
      }
    </div>
  </ng-template>
</ng-container>
