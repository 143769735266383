<ng-container *transloco="let t">
  <button
    type="button"
    [attr.data-testid]="testid()"
    #popperToggle
    class="focus:ring-main-300 relative inline-flex w-full cursor-pointer items-center gap-x-1.5 truncate border-0 bg-white px-2.5 py-1.5 pe-9 text-left text-sm shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:opacity-75 dark:bg-gray-800"
    [ngClass]="rounded() ? 'rounded-full' : 'rounded-md'"
  >
    @if (leadingSlot) {
      <ng-container [ngTemplateOutlet]="leadingSlot"></ng-container>
    }
    @if (!hasSelectedOptions()) {
      <span class="block truncate text-gray-500">{{ placeholder() }}</span>
    } @else {
      @if (labelSlot) {
        <ng-container
          [ngTemplateOutlet]="labelSlot"
          [ngTemplateOutletContext]="{ $implicit: selected() }"
        ></ng-container>
      } @else {
        <span class="block truncate text-sm text-gray-900 dark:text-gray-200">{{ label() }}&nbsp;</span>
      }
    }
    <span class="pointer-events-none absolute inset-y-0 end-0 flex items-center px-2.5">
      <span
        class="flex-shrink-0 text-gray-500"
        [class]="trailingIcon()"
        [ngClass]="{
          'size-5': trailingIconSize() === 'sm',
          'size-6': trailingIconSize() === 'md',
        }"
      ></span>
    </span>
  </button>

  <div
    #popper
    class="invisible absolute z-[2000] max-h-60 scale-90 scroll-py-1 overflow-y-auto rounded-md bg-white p-1 opacity-0 shadow-lg ring-1 ring-gray-200 duration-100 ease-in-out focus:outline-none dark:bg-gray-800"
  >
    <ul
      class="m-0 list-none p-0 focus:outline-none"
      (keydown)="handleKeyDown($event)"
      tabindex="0"
      (click)="$event.stopPropagation()"
    >
      @if (headerSlot) {
        <ng-container [ngTemplateOutlet]="headerSlot"></ng-container>
      }
      @if (searchable()) {
        <input
          type="text"
          class="sticky -top-1.5 z-10 -ml-1 -mr-1 -mt-1.5 mb-1 w-[calc(100%+0.5rem)] truncate border-b px-3 py-1.5 text-gray-800 focus:outline-none focus:ring-0"
          [class.border-t]="headerSlot"
          [class.mt-0]="headerSlot"
          [placeholder]="searchPlaceholder()"
          (input)="setSearch($event)"
        />
      }
      @for (c of filteredOptionsWithCategories(); track c.category; let i = $index) {
        @if (c.category && withCategories()) {
          <li
            class="sticky left-0 top-8 z-10 my-1 cursor-default select-none rounded-sm bg-gray-100 px-1.5 py-1 text-sm font-semibold text-gray-700"
          >
            {{ c.category }}
          </li>
        }
        @for (option of c.options; track option[_by()]; let j = $index) {
          <ng-container
            [ngTemplateOutlet]="optionTemplate"
            [ngTemplateOutletContext]="{ option: option, index: { i, j } }"
          ></ng-container>
        }
      } @empty {
        @if (options().length === 0) {
          <p class="m-0 cursor-default select-none px-1.5 py-1.5">{{ t("iselect-menu.no_option_available") }}</p>
        } @else {
          <p class="m-0 cursor-default select-none px-1.5 py-1.5">
            {{ t("iselect.no_results_found_for") }} "{{ search() }}"
          </p>
        }
      }
    </ul>
  </div>

  <ng-template #optionTemplate let-option="option" let-index="index">
    @let isOptionSelected = isSelected(option);
    <li
      [attr.data-option]="index.i + '_' + index.j"
      (click)="selectOption(option)"
      (mouseenter)="activeIndex.set(index)"
      (mouseleave)="activeIndex.set({ i: -1, j: -1 })"
      class="relative flex min-w-0 cursor-pointer select-none scroll-mt-9 items-center gap-x-1.5 rounded-md px-1.5 py-1.5 text-gray-800 dark:text-gray-300"
      [class.pe-8]="isOptionSelected && selectedAppearance() === 'tick'"
      [ngClass]="{
        'text-main-500 bg-gray-50': selectedAppearance() === 'color' && isOptionSelected,
        '!cursor-not-allowed !select-none !opacity-50': option.disabled,
        'bg-gray-100 dark:bg-gray-700': activeIndex().i === index.i && activeIndex().j === index.j && !option.disabled,
      }"
      [attr.data-testid]="option.testid"
    >
      <div class="flex w-full min-w-0 items-center gap-x-1.5">
        @if (optionSlot) {
          <ng-container
            [ngTemplateOutlet]="optionSlot"
            [ngTemplateOutletContext]="{ $implicit: option }"
          ></ng-container>
        } @else {
          <span class="truncate text-sm">{{ option[optionAttribute()] }}</span>
        }
        @if (isOptionSelected && selectedAppearance() === "tick") {
          <span class="absolute inset-y-0 end-0 flex items-center p-1 pe-2"
            ><span class="icon-[mdi--check] h-5 w-5 shrink-0" aria-hidden="true"></span
          ></span>
        }
      </div>
    </li>
  </ng-template>
</ng-container>
