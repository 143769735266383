import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import {
  Configuration,
  type Response,
  TokenApi,
  TokenResponse,
  UpdateUserRequest,
  User,
  UserApi,
  UserBase,
} from '@front/m19-api-client';
import { inject, Injectable } from '@angular/core';
import { catchAjaxError } from '@front/m19-utils';
import { API_BASE_URL } from '@front/m19-injection-token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly apiBaseUrl = inject(API_BASE_URL);
  private readonly apiConfiguration: Configuration; // Api configuration needed for all swagger generated API class
  private readonly tokenApi: TokenApi; // class to make a token request
  private readonly userApi: UserApi;
  private static readonly ADMIN_STORAGE_KEY = 'adminLogin'; // transitional name
  private static readonly TOKEN_STORAGE_KEY = 'accessToken2'; // transitional name
  private static readonly TOKEN_STORAGE_KEY_OLD = 'accessToken';

  private accessToken?: string; // Adakazam Access token
  private adminLogin: boolean;
  private tokenExpiration?: number;

  private isLogged = new BehaviorSubject<boolean>(false);
  private user = new BehaviorSubject<User | undefined>(undefined);

  public isLogged$: Observable<boolean> = this.isLogged.asObservable();

  // Observable providing user profile of the logged user
  public loggedUser$: Observable<User> = this.user.asObservable().pipe(takeWhile((u): u is User => u != undefined));
  public user$: Observable<User | undefined> = this.user.asObservable();

  constructor() {
    this.adminLogin = false;
    this.apiConfiguration = new Configuration({
      basePath: this.apiBaseUrl,
      accessToken: () => (this.getAccessToken() ? 'Bearer ' + this.getAccessToken() : ''),
    });
    this.tokenApi = new TokenApi(this.apiConfiguration);
    this.userApi = new UserApi(this.apiConfiguration);
  }

  // Configuration for sawgger-generated API clients
  public getConfiguration(): Configuration {
    return this.apiConfiguration;
  }

  public doLoginV2(username: string, password: string): Observable<User> {
    return this.fetchTokenPassword(username, password).pipe(switchMap((token) => this.handleTokenResponseV2(token)));
  }

  public logout(): void {
    this.setAccessToken(undefined, 0, false);
    this.setUserInfo(undefined);
    this.isLogged.next(false);
  }

  public isLoggedIn(): Observable<boolean> {
    if (this.getAccessToken() && this.user.getValue() !== undefined) return of(true);
    else
      return this.userApi.getUser().pipe(
        map((user) => {
          this.setUserInfo(user);
          this.isLogged.next(true);
          return user !== undefined;
        }),
        catchError(() => of(false)),
      );
  }

  public updateUserV2(updateRequest: UpdateUserRequest): Observable<Response> {
    return this.userApi.updateUser(updateRequest).pipe(tap((response) => this.user.next(response.entity as User)));
  }

  public updatePassword(user: User, onSuccess?: () => void, onError?: (errorMessage: string) => void): void {
    this.updatePasswordAsync(user).subscribe({
      next: () => {
        if (onSuccess) onSuccess();
      },
      error: (error) => {
        if (onError) onError(error);
      },
    });
  }

  public updatePasswordAsync(user: UserBase): Observable<void> {
    return this.userApi.updatePassword({ userBase: user }).pipe(
      catchError((error) => {
        this.user.next(this.user.getValue()); // re-emit previous user
        return throwError(() => (error.response ? error.response.message : error.message));
      }),
      tap((response) => {
        this.user.next(response.entity as User);
      }),
      map(() => void 0),
    );
  }

  public doAdminLogin(username: string, password: string) {
    return new TokenApi(new Configuration({ basePath: this.apiBaseUrl, username: username, password: password }))
      .getToken()
      .pipe(
        map((tokenResponse) => {
          this.setAccessToken(tokenResponse.access_token, tokenResponse.expires_in!, true);
          return tokenResponse;
        }),
        catchAjaxError(),
      );
  }

  public getAdminToken(
    userId: number,
    onSuccess: (token: TokenResponse) => void,
    onError: (message: string) => void,
  ): void {
    this.tokenApi.getAdminToken({ userId: userId }).subscribe({
      next: (token: TokenResponse) => onSuccess(token),
      error: (error) => onError(error.response ? error.response.message : error.message),
    });
  }

  public getBearerToken(
    bearer: string,
    onSuccess: (TokenResponse: TokenResponse) => void,
    onError: (message: string) => void,
  ): void {
    this.fetchTokenBearer(bearer).subscribe({
      next: (token: TokenResponse) => onSuccess(token),
      error: (error) => onError(error.response ? error.response.message : error.message),
    });
  }

  public isAdminLogin(): boolean {
    return this.adminLogin;
  }

  private setUserInfo(user?: User) {
    if (user != undefined && !user.locale) user.locale = 'en-US';
    this.user.next(user);
  }

  public setAccessToken(token: string | undefined, expirationInSec: number | undefined, adminLogin: boolean): void {
    this.accessToken = token;
    this.tokenExpiration = Date.now() + (expirationInSec ?? 0) * 1000;
    this.adminLogin = adminLogin;
    if (this.accessToken) {
      localStorage.setItem(AuthService.TOKEN_STORAGE_KEY, this.accessToken);
      localStorage.setItem('tokenExpiration', String(this.tokenExpiration));
      localStorage.setItem(AuthService.ADMIN_STORAGE_KEY, adminLogin.toString());
      localStorage.removeItem(AuthService.TOKEN_STORAGE_KEY_OLD); // transitional cleaning to be removed after 2021-11
      this.isLogged.next(true);
    } else {
      localStorage.removeItem(AuthService.TOKEN_STORAGE_KEY);
      localStorage.removeItem(AuthService.ADMIN_STORAGE_KEY);
      localStorage.removeItem('tokenExpiration');
      this.isLogged.next(false);
    }
  }

  private getAccessToken(): string | undefined {
    if (!this.accessToken) {
      this.accessToken = localStorage.getItem(AuthService.TOKEN_STORAGE_KEY) ?? undefined;
      this.tokenExpiration = Number(localStorage.getItem('tokenExpiration'));
      this.adminLogin = localStorage.getItem(AuthService.ADMIN_STORAGE_KEY) == 'true';
    }
    return Date.now() < this.tokenExpiration! ? this.accessToken : undefined;
  }

  /**
   * @deprecated: use fetchUserV2 instead
   */
  private fetchUser(
    onSuccess?: (user: User) => void,
    onError?: (errorMessage: string) => void,
    onRegister?: (user: User) => void,
  ): void {
    this.userApi.getUser().subscribe({
      next: (user) => {
        this.setUserInfo(user);
        if (!user.registered && onRegister) onRegister(user);
        else if (onSuccess) onSuccess(user);
      },
      error: (error) => {
        if (onError) onError(error.response ? error.response.message : error.message);
      },
    });
  }

  private fetchUserV2(): Observable<User> {
    return this.userApi.getUser().pipe(
      tap((user) => {
        this.setUserInfo(user);
      }),
    );
  }

  public fetchTokenPassword(username: string, password: string): Observable<TokenResponse> {
    return new TokenApi(
      new Configuration({ basePath: this.apiBaseUrl, username: username, password: password }),
    ).getToken();
  }

  private fetchTokenBearer(access_token: string): Observable<TokenResponse> {
    return new TokenApi(
      new Configuration({ basePath: this.apiBaseUrl, accessToken: 'Bearer ' + access_token }),
    ).getToken();
  }

  /**
   * @deprecated use reloadUserV2 instead
   */
  public reloadUser(
    onSuccess?: (user: User) => void,
    onError?: (errorMessage: string) => void,
    viaAdminPage?: boolean,
  ) {
    this.handleTokenResponse(
      this.tokenApi.getToken(),
      !!viaAdminPage,
      onSuccess,
      onError,
      viaAdminPage
        ? (_) => {
            if (onError) onError('User is not registered');
          }
        : (_) => {
            // do nothing
          },
    );
  }

  public reloadUserv2(viaAdminPage?: boolean) {
    return this.tokenApi.getToken().pipe(switchMap((token) => this.handleTokenResponseV2(token, viaAdminPage)));
  }

  public registerV2(input: UserBase): Observable<Response> {
    const password = input.newPassword;
    const user: UserBase = {
      email: input.email,
      userName: input.userName,
      registered: input.registered,
      locale: input.locale,
      defaultCurrency: input.defaultCurrency,
    };
    return this.userApi.registerUser({ userBase: { ...user } }).pipe(
      switchMap((token) => this.handleTokenResponseV2(token)),
      switchMap((user) => this.userApi.updatePassword({ userBase: { ...user, newPassword: password } })),
    );
  }

  /**
   * @deprecated use handleTokenResponseV2 instead
   */
  private handleTokenResponse(
    tokenResponse: Observable<TokenResponse>,
    isAdminLogin: boolean,
    onSuccess?: (user: User) => void,
    onError?: (errorMessage: string) => void,
    onRegister?: (user: User) => void,
  ) {
    tokenResponse.subscribe({
      next: (tokenResponse: TokenResponse) => {
        this.setAccessToken(tokenResponse?.access_token, tokenResponse?.expires_in, isAdminLogin);
        this.fetchUser(onSuccess, onError, onRegister);
      },
      error: (error: AjaxError) => {
        if (onError) onError(error.response ? error.response.message : error.message);
      },
    });
  }

  private handleTokenResponseV2(tokenResponse: TokenResponse, isAdminLogin: boolean = false) {
    this.setAccessToken(tokenResponse?.access_token, tokenResponse?.expires_in, isAdminLogin);
    return this.fetchUserV2();
  }
}
