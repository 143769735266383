import { DspCampaign, DspCreative, Strategy } from "@front/m19-api-client";
import { PALETTE } from "@front/m19-metrics";
import { BrandingFilter, CatalogBrandEx, ProductGroupEx, SegmentEx, StrategyEx } from "@front/m19-models";
import { Option } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { Filter, getSelectedOptionsFromFilterType } from "@m19-board/shared/filter/filter.component";
import { StatsFilter } from "@m19-board/stats/stats.component";

export enum FilterCategory {
  KeywordSegment = "KeywordSegment",
  ProductSegment = "ProductSegment",
  ProductGroup = "ProductGroup",
  BrandTraffic = "BrandTraffic",
  Strategy = "Strategy",
  BrandProducts = "BrandProducts",
  Brand = "Brand",
  ProductTag1 = "ProductTag1",
  ProductTag2 = "ProductTag2",
  DspCampaign = "DspCampaign",
  DspCreative = "DspCreative",
  Bulk = "Bulk",
}

export type FilterCategoryType = {
  [FilterCategory.KeywordSegment]: SegmentEx;
  [FilterCategory.ProductSegment]: SegmentEx;
  [FilterCategory.ProductGroup]: ProductGroupEx;
  [FilterCategory.BrandTraffic]: BrandingFilter;
  [FilterCategory.Strategy]: Strategy;
  [FilterCategory.BrandProducts]: BrandingFilter;
  [FilterCategory.Brand]: CatalogBrandEx;
  [FilterCategory.ProductTag1]: string;
  [FilterCategory.ProductTag2]: string;
  [FilterCategory.DspCampaign]: DspCampaign;
  [FilterCategory.DspCreative]: DspCreative;
  [FilterCategory.Bulk]: string;
};

export class AsinFilter {
  filters: any;

  productGroups: ProductGroupEx[] = [];
  strategies: StrategyEx[] = [];
  productTag1Values: Option<string>[] = [];
  productTag2Values: Option<string>[] = [];
  brandTrafficOptions: Option<BrandingFilter>[] = [];

  constructor(private translocoService: TranslocoService) {}

  setProductGroups(productGroups: ProductGroupEx[]) {
    this.productGroups = productGroups;
  }

  setStrategies(strategies: StrategyEx[]) {
    this.strategies = strategies;
  }

  setProductTag1Values(productTag1Values: Option<string>[]) {
    this.productTag1Values = productTag1Values;
  }

  setProductTag2Values(productTag2Values: Option<string>[]) {
    this.productTag2Values = productTag2Values;
  }

  setBrandTrafficOptions(brandTrafficOptions: Option<BrandingFilter>[]) {
    this.brandTrafficOptions = brandTrafficOptions;
  }

  static filterChanged(
    filters: Filter<FilterCategory>[],
    stratAsinMap: Map<number, string[]>,
    productTag1Map: Map<string, string[]>,
    productTag2Map: Map<string, string[]>,
  ) {
    if (filters.length === 0 || filters.every((f) => f.selectedOptions.length === 0)) {
      return null;
    }
    let asins: Set<string> = new Set();
    for (const filter of filters) {
      const values = filter.selectedOptions.map((v) => v.value);
      // do not consider empty filters
      if (filter.selectedOptions.length === 0) continue;
      if (filter.type === FilterCategory.ProductGroup) {
        const productGroupAsins = new Set((values as ProductGroupEx[]).flatMap((pg) => pg.items));
        asins = !asins.size ? productGroupAsins : new Set([...asins].filter((x) => productGroupAsins.has(x)));
      } else if (filter.type === FilterCategory.Strategy) {
        const strategyAsins = new Set((values as Strategy[]).flatMap((s) => stratAsinMap.get(s.strategyId!)!));
        asins = !asins.size ? strategyAsins : new Set([...asins].filter((x) => strategyAsins.has(x)));
      } else if (filter.type === FilterCategory.BrandProducts) {
        const brandAsins = new Set((values as BrandingFilter[]).flatMap((b) => b.asins));
        asins = !asins.size ? brandAsins : new Set([...asins].filter((x) => brandAsins.has(x)));
      } else if (filter.type === FilterCategory.ProductTag1) {
        const asinsWithProductTag = new Set((values as string[]).flatMap((v) => productTag1Map.get(v)!));
        asins = !asins.size ? asinsWithProductTag : new Set([...asins].filter((x) => asinsWithProductTag.has(x)));
      } else if (filter.type === FilterCategory.ProductTag2) {
        const asinsWithProductTag = new Set((values as string[]).flatMap((v) => productTag2Map.get(v)!));
        asins = !asins.size ? asinsWithProductTag : new Set([...asins].filter((x) => asinsWithProductTag.has(x)));
      }
    }
    return asins;
  }

  static asinMatchesFilter(asin: string, filter: Set<string> | null): boolean {
    if (!filter) return true;
    return filter.has(asin);
  }

  getFilterTags(
    selectedFilters: Filter<StatsFilter>[] = [],
  ): Filter<
    | FilterCategory.ProductGroup
    | FilterCategory.Strategy
    | FilterCategory.BrandProducts
    | FilterCategory.ProductTag1
    | FilterCategory.ProductTag2
  >[] {
    return [
      {
        type: FilterCategory.ProductGroup,
        label: this.translocoService.translate("v2-sidebar.product_groups"),
        tooltip: this.translocoService.translate("sales-advertising.filter_stats_on_products_of_a_product_group"),
        noValuePlaceholder: "No product group available",
        options: () => this.productGroups.map((pg) => ({ label: pg.name, value: pg })),
        color: PALETTE[0],
        unique: true,
        excludeModeAllowed: false,
        selectedOptions: getSelectedOptionsFromFilterType(selectedFilters, FilterCategory.ProductGroup),
      },
      {
        type: FilterCategory.Strategy,
        label: this.translocoService.translate("sales-advertising.strategy_products"),
        tooltip: "Filter stats on products advertised by a strategy",
        noValuePlaceholder: "No strategy available",
        options: () => this.strategies.map((s) => ({ label: s.name ?? "", value: s })),
        color: PALETTE[1],
        unique: true,
        excludeModeAllowed: false,
        selectedOptions: getSelectedOptionsFromFilterType(selectedFilters, FilterCategory.Strategy),
      },
      {
        type: FilterCategory.BrandProducts,
        label: this.translocoService.translate("sov-main-table.brands"),
        tooltip: "Filter stats on products with a specific brand",
        noValuePlaceholder: "No brands available",
        options: () => this.brandTrafficOptions,
        color: PALETTE[2],
        unique: true,
        excludeModeAllowed: false,
        selectedOptions: getSelectedOptionsFromFilterType(selectedFilters, FilterCategory.BrandProducts),
      },
      {
        type: FilterCategory.ProductTag1,
        label: this.translocoService.translate("custom-field-edition.custom_field_1"),
        tooltip: "Filter stats on product with a specific value in Custom Field 1",
        noValuePlaceholder: "No custom field value available",
        options: () => this.productTag1Values,
        color: PALETTE[3],
        unique: true,
        excludeModeAllowed: false,
        selectedOptions: getSelectedOptionsFromFilterType(selectedFilters, FilterCategory.ProductTag1),
      },
      {
        type: FilterCategory.ProductTag2,
        label: this.translocoService.translate("custom-field-edition.custom_field_2"),
        tooltip: "Filter stats on product with a specific value in Custom Field 2",
        noValuePlaceholder: "No custom field value available",
        options: () => this.productTag2Values,
        color: PALETTE[4],
        unique: true,
        excludeModeAllowed: false,
        selectedOptions: getSelectedOptionsFromFilterType(selectedFilters, FilterCategory.ProductTag2),
      },
    ];
  }
}
