import { Currency, DspStats, Marketplace, Order } from '../api-client';
import { Currencies } from '../models/CurrencyEx';
import { Marketplaces } from '../models/MarketplaceEx';
import { AdStatsEx, VendorInventoryAdStats } from '@front/m19-models/AdStatsEx';
import { OrderStats } from '@front/m19-models/OrderStats';

export function currencyRateToEuro(currency: Currency): number {
  return Currencies[currency]?.currencyRateToEuro;
}

export function marketplaceCurrency(marketplace: Marketplace) {
  return Marketplaces[marketplace]?.currency;
}

export function currencyRate(data: AdStatsEx | OrderStats, currency: Currency, dataMarketplace?: Marketplace): number {
  let fromCurrency = data.currency;
  if (fromCurrency == undefined) {
    fromCurrency = marketplaceCurrency(dataMarketplace ?? data.marketplace!);
  }
  return currencyRateToEuro(fromCurrency) / currencyRateToEuro(currency);
}

export function currencyRateForOrder(data: Order, currency: Currency): number {
  const fromCurrency = marketplaceCurrency(data.marketplace!);
  return currencyRateToEuro(fromCurrency) / currencyRateToEuro(currency);
}

export function marketplaceToCurrencyRate(marketplace: Marketplace, currency: Currency): number {
  if (!currency) return 1;

  return currencyRateToEuro(marketplaceCurrency(marketplace)) / currencyRateToEuro(currency);
}

export function multiply(a: VendorInventoryAdStats, rate: number) {
  if (a.inventoryUnsellableCost) a.inventoryUnsellableCost *= rate;
  if (a.inventorySellableCost) a.inventorySellableCost *= rate;
  if (a.inventoryAged90pSellableCost) a.inventoryAged90pSellableCost *= rate;
}

export function convertToCurrency(
  data: AdStatsEx[],
  selectedCurrency: Currency,
  dataMarketplace?: Marketplace,
): AdStatsEx[] | undefined {
  if (!data) return undefined;

  for (let i = 0; i < data.length; i++) {
    if (data[i].currency != selectedCurrency) {
      const rate = currencyRate(data[i], selectedCurrency, dataMarketplace ?? data[i].marketplace!);
      if (data[i].cost) data[i].cost! *= rate;
      if (data[i].vendorShippedCogs) data[i].vendorShippedCogs! *= rate;

      if (data[i].allSalesExTax) data[i].allSalesExTax! *= rate;
      if (data[i].adSales) data[i].adSales! *= rate;
      if (data[i].m19AdSales) data[i].m19AdSales! *= rate;
      if (data[i].unmanagedAdSales) data[i].unmanagedAdSales! *= rate;
      if (data[i].m19Cost) data[i].m19Cost! *= rate;
      if (data[i].unmanagedCost) data[i].unmanagedCost! *= rate;
      if (data[i].m19AdConversions) data[i].m19AdConversions! *= rate;
      if (data[i].unmanagedAdConversions) data[i].unmanagedAdConversions! *= rate;
      if (data[i].m19Clicks) data[i].m19Clicks! *= rate;
      if (data[i].unmanagedClicks) data[i].unmanagedClicks! *= rate;
      if (data[i].m19Impressions) data[i].m19Impressions! *= rate;
      if (data[i].unmanagedImpressions) data[i].unmanagedImpressions! *= rate;

      if (data[i].vendorInventoryAdStats) multiply(data[i].vendorInventoryAdStats!, rate);
      data[i].currency = selectedCurrency;
    }
  }
  return data;
}
