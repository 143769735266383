import { Component, computed, DestroyRef, inject, input, model, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopperComponent } from '../popper/popper.component';
import {
  addDays,
  addMonths,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameOrAfter,
  isSameOrBefore,
  setMonth,
  setYear,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subYears,
} from './date.utils';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { filter, map, startWith } from 'rxjs';
import { IButtonComponent } from '../ibutton/ibutton.component';
import { IDatepickerCalendarComponent } from './idatepicker-calendar.component';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import moment from 'moment-timezone';

export interface DatePickerDay {
  date: Date;
  isInRange: boolean;
  isStartDate: boolean;
  isEndDate: boolean;
  isInCurrentMonth: boolean;
  isDisabled?: boolean;
  isHovered?: boolean;
  isToday?: boolean;
}

interface DatePickerShortcut {
  label: string;
  start?: Date;
  end?: Date;
}

export interface IDateRange {
  start: Date;
  end: Date | null;
}

@Component({
  selector: 'IDatePicker',
  standalone: true,
  imports: [CommonModule, IButtonComponent, IDatepickerCalendarComponent, TranslocoDirective],
  templateUrl: './idatepicker.component.html',
})
export class IDatepickerComponent extends PopperComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly translocoService = inject(TranslocoService);

  readonly WEEKS_TO_GENERATE = 6;
  readonly today = signal<Date>(new Date());
  override parentWidthAsDefault = signal(false);

  range = input<boolean>(true);
  minDate = input<Date>(subYears(this.today(), 5));
  maxDate = input<Date>(subDays(this.today(), 1));
  double = input<boolean>(true);
  withShortcuts = input<boolean>(true);
  disabled = input<boolean>(false);
  dateFormat = input.required<string>();

  protected minYear = computed(() => this.minDate().getFullYear());
  protected maxYear = computed(() => this.maxDate().getFullYear());

  rightMonthDate = signal<Date>(startOfMonth(new Date()));
  leftMonthDate = computed<Date>(() => startOfMonth(subMonths(this.rightMonthDate(), 1)));
  monthBeforeLeftMonth = computed(() => subMonths(this.leftMonthDate(), 1));
  monthAfterRightMonth = computed(() => addMonths(this.rightMonthDate(), 1));
  canNavigateToPreviousMonth = computed(() => this.monthBeforeLeftMonth().getFullYear() < this.minYear());
  canNavigateToNextMonth = computed(() => {
    const maxVisibleMonth = this.maxDate();
    return (
      (maxVisibleMonth && this.monthAfterRightMonth() > maxVisibleMonth) ||
      this.monthAfterRightMonth().getFullYear() > this.maxYear()
    );
  });

  readonly years = computed(() => this.getYears());

  selectedDateRange = model<IDateRange | null>(null);
  selectedStartDate = computed(() => this.selectedDateRange()?.start ?? null);
  selectedEndDate = computed(() => this.selectedDateRange()?.end ?? null);

  showShortcuts = computed(() => this.range() && this.withShortcuts());
  readonly SHORTCUTS = computed<DatePickerShortcut[]>(() => {
    const referenceDate = this.maxDate() ?? new Date();
    return [
      {
        label: this.translocoService.translate('date-range-selection.last_7_days'),
        start: subDays(referenceDate, 6),
        end: referenceDate,
      },
      {
        label: this.translocoService.translate('date-range-selection.last_14_days'),
        start: subDays(referenceDate, 13),
        end: referenceDate,
      },
      {
        label: this.translocoService.translate('date-range-selection.last_30_days'),
        start: subDays(referenceDate, 30),
        end: referenceDate,
      },
      {
        label: this.translocoService.translate('date-range-selection.last_60_days'),
        start: subDays(referenceDate, 60),
        end: referenceDate,
      },
      {
        label: this.translocoService.translate('date-range-selection.last_90_days'),
        start: subDays(referenceDate, 90),
        end: referenceDate,
      },
      {
        label: this.translocoService.translate('date-range-selection.last_12_months'),
        start: subYears(referenceDate, 1),
        end: referenceDate,
      },
    ];
  });

  selectedShortcut = computed<DatePickerShortcut | undefined>(() => {
    return this.SHORTCUTS().find(
      (shortcut) =>
        isSameDay(this.selectedStartDate() ?? new Date(), shortcut.start ?? '') &&
        isSameDay(this.selectedEndDate() ?? new Date(), shortcut.end ?? ''),
    );
  });
  shortcutLabel$ = toObservable(this.selectedDateRange).pipe(
    filter((dateRange: IDateRange | null) => !!dateRange && !!dateRange.end),
    map(() => {
      if (this.selectedShortcut()) return this.selectedShortcut()?.label;
      return this.translocoService.translate('common.custom');
    }),
    startWith(this.translocoService.translate('common.custom')),
  );

  currentWeeks = computed(() => this.generateDays());

  private maxDate$ = toObservable(this.maxDate);

  ngOnInit() {
    this.maxDate$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((maxDate) => {
      if (maxDate && isBefore(maxDate, this.rightMonthDate())) {
        this.goToMaxDate();
      }
    });
  }

  private generateDays() {
    const weeks: DatePickerDay[][] = [];
    const nbMonthsToGenerate = 2;
    const monthBeingGenerated = subMonths(this.rightMonthDate(), 1);
    for (let month = 0; month < nbMonthsToGenerate; month++) {
      const firstDayOfMonth = startOfMonth(addMonths(monthBeingGenerated, month));
      const firstDayOfWeek = startOfWeek(firstDayOfMonth);

      // Generate 6 weeks to ensure we have enough rows
      for (let week = 0; week < this.WEEKS_TO_GENERATE; week++) {
        weeks.push(this.generateWeekFromOffset(firstDayOfWeek, firstDayOfMonth, week));
      }
    }
    return weeks;
  }

  private generateWeekFromOffset(firstDayOfWeek: Date, firstDayOfMonth: Date, weekOffset: number) {
    const weekDates: DatePickerDay[] = [];

    for (let day = 0; day < 7; day++) {
      weekDates.push(this.generateDayFromOffset(firstDayOfWeek, firstDayOfMonth, weekOffset, day));
    }
    return weekDates;
  }

  private generateDayFromOffset(
    firstDayOfWeek: Date,
    firstDayOfMonth: Date,
    weekOffset: number,
    dayOffset: number,
  ): DatePickerDay {
    const date = addDays(firstDayOfWeek, weekOffset * 7 + dayOffset);

    const isBetweenMinAndMaxDate =
      (!this.maxDate() || isSameOrBefore(date, this.maxDate() ?? '')) &&
      (!this.minDate() || isSameOrAfter(date, this.minDate() ?? ''));

    return {
      date,
      isInRange: this.isDateInRange(date, this.hoveredDate()),
      isStartDate: !!this.selectedStartDate() && isSameDay(date, this.selectedStartDate() ?? ''),
      isEndDate: !!this.selectedEndDate() && isSameDay(date, this.selectedEndDate() ?? ''),
      isInCurrentMonth: isSameMonth(date, firstDayOfMonth),
      isDisabled: !isBetweenMinAndMaxDate,
      isHovered: !!this.hoveredDate() && isSameDay(date, this.hoveredDate() ?? ''),
      isToday: isSameDay(date, this.today()!),
    };
  }

  leftMonth = computed(() => {
    const first6WeeksOfPreviousMonth = this.currentWeeks().slice(0, 6);
    return first6WeeksOfPreviousMonth.filter(this.weekHasDaysInCurrentMonth);
  });

  rightMonth = computed(() => {
    const first6WeeksOfCurrentMonth = this.currentWeeks().slice(6, 12);
    return first6WeeksOfCurrentMonth.filter(this.weekHasDaysInCurrentMonth);
  });

  hoveredDate = signal<Date | null>(null);

  formattedDate = computed(() => {
    const selectedStartDate = this.selectedStartDate();
    const selectedEndDate = this.double() ? this.selectedEndDate() : undefined;

    const separator = this.double() ? ' - ' : '';

    const endDateFormatted = selectedEndDate
      ? `${moment.utc(selectedEndDate.toISOString()).format(this.dateFormat())}`
      : '';
    return selectedStartDate
      ? `${moment.utc(selectedStartDate.toISOString()).format(this.dateFormat())} ${separator} ${endDateFormatted}`
      : this.translocoService.translate('common.select');
  });

  selectDate(date: Date): void {
    const selectedStartDate = this.selectedStartDate();
    const selectedEndDate = this.selectedEndDate();

    const isDateRangeSelected = selectedStartDate && selectedEndDate;
    if (isDateRangeSelected || !selectedStartDate) {
      this.setDateRange(date, null);
    } else {
      if (isBefore(date, selectedStartDate)) {
        this.setDateRange(date, selectedStartDate);
      } else {
        this.setDateRange(selectedStartDate, date);
      }

      this.hoveredDate.set(null);
    }
  }

  onCellHover(date: Date): void {
    if (!this.selectedStartDate() || this.selectedEndDate()) return;
    this.hoveredDate.set(date);
  }

  goToMaxDate() {
    this.rightMonthDate.set(this.maxDate());
  }

  goToToday() {
    this.rightMonthDate.set(this.today());
  }

  /**
   * Checks if date is in range between start and end date
   * If hovered date is provided, it checks if date is in range between start and hovered date
   * @param date
   * @param hoveredDate
   * @private
   */
  private isDateInRange(date: Date, hoveredDate: Date | null): boolean {
    const start = this.selectedStartDate();
    const end = this.selectedEndDate() || hoveredDate;

    if (!start || !end) return false;
    if (isSameDay(date, end) || isSameDay(date, start)) return false;
    if (isAfter(date, start) && isBefore(date, end)) return true;
    return !!hoveredDate && isBefore(date, start) && isAfter(date, hoveredDate);
  }

  private setDateRange(startDate: Date, endDate: Date | null): void {
    if (!this.double()) {
      endDate = startDate;
      this.hidePopper();
    }

    this.selectedDateRange.set({
      start: startDate,
      end: endDate,
    });
  }

  private weekHasDaysInCurrentMonth(week: DatePickerDay[]): boolean {
    return week.some((day) => day.isInCurrentMonth);
  }

  onMonthChange(month: number, isLeft: boolean = false): void {
    const leftDateYear = this.leftMonthDate().getFullYear();
    const leftYearBeforeRightYear = leftDateYear < this.rightMonthDate().getFullYear();
    if (isLeft && leftYearBeforeRightYear) {
      this.rightMonthDate.set(setYear(this.rightMonthDate(), leftDateYear));
    }

    this.rightMonthDate.set(setMonth(this.rightMonthDate(), isLeft ? month : month - 1));
  }

  onYearChange(year: number): void {
    this.rightMonthDate.set(setYear(this.rightMonthDate(), year));
  }

  nextMonth(): void {
    this.rightMonthDate.update((date) => addMonths(date, 1));
  }

  previousMonth(): void {
    this.rightMonthDate.update((date) => subMonths(date, 1));
  }

  onShortcutClick(shortcut: DatePickerShortcut): void {
    if (shortcut.start && shortcut.end) {
      this.setDateRange(shortcut.start, shortcut.end);
    }
    this.moveViewToSelectedDates();
    this.hidePopper();
  }

  private getYears(): number[] {
    const years: number[] = [];
    for (let year = this.minYear(); year <= this.maxYear(); year++) {
      years.push(year);
    }
    return years;
  }

  private moveViewToSelectedDates() {
    if (this.selectedDateRange()?.end) {
      this.rightMonthDate.set(this.selectedDateRange()!.end!);
    }
  }
}
