<div
  class="modal-outer fixed left-1/2 z-[60] h-auto max-h-[95vh] w-auto origin-top-left -translate-x-1/2 -translate-y-1/2 rounded-lg duration-300 ease-out"
  [ngClass]="{
    'top-1/2': !!position || position === 'center',
    'top-[25%]': position === 'center-top',
  }"
  #modalContent
>
  <div
    class="modal-content h-full rounded-lg border border-gray-200 bg-white shadow-xl dark:border-gray-700 dark:bg-gray-800"
  >
    @if (title) {
      <div class="flex w-full items-center justify-between gap-4 border-b border-gray-200 p-3 dark:border-gray-700">
        <div class="flex items-center gap-1">
          @if (titleIcon) {
            <span [class]="titleIcon + ' mr-2 text-3xl text-gray-600'"></span>
          }
          <span class="text-xl font-medium text-gray-700 dark:text-white">{{ title }}</span>
        </div>
        <IButton icon="icon-[mdi--close]" size="sm" variant="ghost" color="gray" (click)="closeModal(true)" />
      </div>
    }
    <ng-container>
      <div #element class="max-h-[80vh] overflow-y-auto p-4">
        <ng-content></ng-content>
      </div>
    </ng-container>
  </div>
</div>

@if (!fullScreen) {
  <div
    class="fixed inset-0 left-0 top-0 z-50 h-screen w-screen bg-gray-200/75 dark:bg-gray-800/75"
    #modalOverlay
    [@overlayAnimation]
  ></div>
}
