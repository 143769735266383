import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { PopperComponent } from '../popper/popper.component';

@Component({
  selector: 'IMenu',
  standalone: true,
  imports: [CommonModule, RouterLink, NgTemplateOutlet],
  templateUrl: './imenu.component.html',
})
export class ImenuComponent extends PopperComponent {
  @Input() items: MenuItem[] = [];
  @Input() override popperWidth: string | undefined;

  onItemClick(item: MenuItem) {
    if (item.onClick) {
      item.onClick();
    }
    this.hidePopper();
  }
}

export interface MenuItem {
  label: string;
  icon: string;
  routerLink?: string;
  href?: string;
  onClick?: () => void;
}
