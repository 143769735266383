import { DatePipe } from "@angular/common";
import { Component, computed, inject, OnInit, signal } from "@angular/core";
import { AlgoMode, Strategy } from "@front/m19-api-client";
import { StrategyEx, StrategyUpdateParams } from "@front/m19-models";
import { StrategyService, TacosStrategiesService } from "@front/m19-services";
import { MODAL_DATA, ModalRef } from "@front/m19-ui";
import { Utils } from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { AlgoModeConfig } from "@m19-board/shared/algo-mode-selection/algo-mode-selection.component";
import { ICON_SYNC, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";
import moment from "moment-timezone";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { tap } from "rxjs";

@Component({
  selector: "app-switch-target-algo-modal",
  templateUrl: "./switch-target-algo-modal.component.html",
  styleUrls: ["./switch-target-algo-modal.component.scss"],
  providers: [DatePipe],
})
export class SwitchTargetAlgoModalComponent implements OnInit {
  protected readonly AlgoMode = AlgoMode;
  protected readonly Math = Math;

  readonly ICON_TRASH = ICON_TRASH_O;
  readonly ICON_SYNC = ICON_SYNC;

  readonly data = inject(MODAL_DATA) as TargetAlgoModeSelectionData;
  readonly ref = inject(ModalRef);

  readonly algoModeConfig = signal<Partial<AlgoModeConfig> | undefined>(undefined);
  readonly disabled = computed(() => !this.algoModeConfig() || !this.algoModeConfig()?.isValid);

  updateLoading = false;

  nextMonth?: Date;
  endOfCurrentMonth?: Date;
  currentMonthSpend?: number;

  constructor(
    public bsModalRef: BsModalRef,
    private toasterService: ToastrService,
    private translocoService: TranslocoService,
    private strategyService: StrategyService,
    private tacosStrategyService: TacosStrategiesService,
  ) {}

  ngOnInit(): void {
    this.algoModeConfig.set({
      ...this.data.strategy,
      acosTarget: this.data.strategy.acosTarget ? Math.round(this.data.strategy.acosTarget * 100) : undefined,
      tacosTarget: this.data.strategy.tacosTarget ? Math.round(this.data.strategy.tacosTarget * 100) : undefined,
    });

    const today = moment(this.data.strategy.today, "YYYY-MM-DD");
    this.endOfCurrentMonth = today.endOf("month").toDate();
    this.nextMonth = today.startOf("month").add(1, "M").toDate();
  }

  getTargetName(): string {
    switch (this.algoModeConfig()!.algoMode) {
      case AlgoMode.ACOS_TARGET:
        return this.translocoService.translate("algo-target-renderer.acos_target");
      case AlgoMode.TACOS_TARGET:
        return this.translocoService.translate("algo-target-renderer.tacos_target");
      case AlgoMode.PRODUCT_LAUNCH:
        return this.translocoService.translate("algo-target-renderer.suggested_bid");
      case AlgoMode.MONTHLY_BUDGET_TARGET:
        return this.translocoService.translate("metrics.MONTHLY_BUDGET_tooltip");
      default:
        return "";
    }
  }

  deleteDailyBudget() {
    if (this.disabled() || !this.algoModeConfig()) return;
    this.algoModeConfig()!.dailyBudget = undefined;
    this.updateLoading = true;

    this.strategyService
      .updateStrategyDailyBudget(
        this.data.strategy.accountId,
        this.data.strategy.marketplace,
        this.data.organizationId,
        this.data.strategy.strategyId,
        undefined,
      )
      .subscribe({
        next: (_) => {
          this.toasterService.success(`Daily budget deleted`);
          this.updateLoading = false;
        },
        error: (e) => {
          this.toasterService.error(`Error deleting daily budget: ${e}`);
          this.updateLoading = false;
        },
      });
  }

  update(): void {
    if (this.disabled()) return;
    this.updateLoading = true;
    const updateParams: StrategyUpdateParams = {
      accountId: this.data.strategy.accountId,
      marketplace: this.data.strategy.marketplace,
      organizationId: this.data.organizationId,
      strategyId: this.data.strategy.strategyId,
      asinsToAdd: [],
      asinsToDelete: [],
    };

    if (this.algoModeConfig()!.algoMode === AlgoMode.ACOS_TARGET) {
      updateParams.acosTarget = this.algoModeConfig()!.acosTarget! / 100;
      updateParams.dailyBudget = this.algoModeConfig()!.dailyBudget;
      this.updateStrategy(updateParams);
    } else if (this.algoModeConfig()!.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET) {
      updateParams.monthlyBudget = this.algoModeConfig()!.monthlyBudget;
      updateParams.nextMonthlyBudget = this.algoModeConfig()!.nextMonthlyBudget;
      this.updateMonthlyBudget(updateParams);
    } else if (this.algoModeConfig()!.algoMode === AlgoMode.PRODUCT_LAUNCH) {
      updateParams.suggestedBid = this.algoModeConfig()!.suggestedBid;
      updateParams.dailyBudget = this.algoModeConfig()!.dailyBudget;
      this.updateStrategy(updateParams);
    } else if (this.algoModeConfig()!.algoMode === AlgoMode.TACOS_TARGET) {
      this.updateTacosTarget(this.algoModeConfig()!.tacosTarget! * 100);
    }
  }

  updateTacosTarget(tacosTarget: number) {
    this.tacosStrategyService
      .updateTacosStrategyGroup({
        accountId: this.data.strategy.accountId,
        marketplace: this.data.strategy.marketplace,
        tacosTarget,
        tacosStrategyGroupId: this.data.strategy.strategyId,
      })
      .subscribe({
        next: (_) => {
          this.toasterService.success(`TACOS target updated`);
          this.ref.close();
          this.updateLoading = false;
        },
        error: (e) => {
          this.toasterService.error(`Error updating TACOS target: ${e}`);
          this.ref.close();
          this.updateLoading = false;
        },
      });
  }

  updateStrategy(updateParams: StrategyUpdateParams) {
    this.strategyService
      .updateStrategy(updateParams)
      .pipe(tap(() => (this.updateLoading = true)))
      .subscribe({
        next: (strategy: Strategy) => {
          this.toasterService.success(`${this.getTargetName()} updated for strategy ${strategy.name}`);

          // TODO: prevent close on daily budget deletion
          this.updateLoading = false;
          this.ref.close();
        },
        error: (e) => {
          this.toasterService.error(`Error updating ${this.getTargetName()}: ${e}`);
          this.ref.close();
          this.updateLoading = false;
        },
      });
  }

  updateMonthlyBudget(params: StrategyUpdateParams) {
    this.strategyService
      .updateStrategyMonthlyBudget(
        params.accountId,
        params.marketplace,
        params.strategyId,
        params.monthlyBudget!,
        params.nextMonthlyBudget!,
        Utils.formatMonthForApi(this.endOfCurrentMonth!),
      )
      .subscribe({
        next: (strategy: Strategy) => {
          this.toasterService.success(`Monthly budget updated for strategy ${strategy.name}`, "Strategy updated");
          this.ref.close();
          this.updateLoading = false;
        },
        error: (e) => {
          this.toasterService.error(`Error when updating Monthly budget: ${e}`, "Strategy update error");
          this.ref.close();
          this.updateLoading = false;
        },
      });
  }
}

export interface TargetAlgoModeSelectionData {
  strategy: StrategyEx;
  organizationId: number;
  readonly?: boolean;
}
