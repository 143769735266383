<div class="flew-row relative flex w-full max-w-full gap-2 overflow-x-auto px-2 py-1.5">
  <span class="z-5 absolute bottom-0 left-0 h-[1px] w-full bg-gray-200"></span>
  <span
    class="absolute bottom-0 z-10 flex h-0.5 transition-all duration-200"
    [style.left.px]="underlineLeft()"
    [style.width.px]="underlineWidth()"
  >
    <span class="bg-main-500 h-full w-full"></span>
  </span>

  @for (tab of tabs(); track tab.label) {
    <button
      [disabled]="tab.disabled"
      class="focus-visible:ring-main-300 my-auto shrink-0 cursor-pointer select-none rounded-md px-2 py-1 text-center text-sm font-medium text-gray-500 duration-100 focus-visible:outline-none focus-visible:ring-2 disabled:cursor-not-allowed disabled:opacity-50"
      [ngClass]="{
        'hover:bg-main-400/10': $index === activeTab() && !tab.disabled,
        'hover:bg-gray-100 hover:text-gray-700': $index !== activeTab() && !tab.disabled,
      }"
      (click)="onTabClick($index)"
      #tab
    >
      <div class="flex items-center gap-x-2" [ngClass]="{ 'text-main-500': $index === activeTab() }">
        @if (tab.icon) {
          <span [class]="tab.icon + ' h-4 w-4'"></span>
        }
        {{ tab.label }}
        @if (tab.badge) {
          <span class="text-xs text-gray-400">
            {{ tab.badge }}
          </span>
        }
      </div>
    </button>
  }
</div>
