import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { AccountMarketplace, CampaignType } from "@front/m19-api-client";
import {
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  IMPRESSIONS,
  Metric,
  MetricType,
  ROAS,
} from "@front/m19-metrics";
import { AdStatsEx, StrategyEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AdStatsData,
  AuthService,
  DataSet,
  DataSetEventAnnotation,
  groupBy,
  MetricsSelectorLocalStorageKey,
  StatsService,
  StrategyService,
  UserSelectionService,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import {
  addAdStats,
  AggregationFunction,
  convertToCurrency,
  DateAggregation,
  mergeSeveralDates,
} from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { ActivityEventType, ActivityService } from "@m19-board/activities/activity.service";
import { getCsvFileName } from "@m19-board/grid-config/grid-config";
import { DonutDataSet } from "@m19-board/models/DonutDataSet";
import { SwitchButtonType } from "@m19-board/shared/switch-button/switch-button.component";
import { ICON_CHART_LINE, ICON_CLOSE } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ChartOptions } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { map, share, shareReplay, switchMap } from "rxjs/operators";
import { PALETTE, StrategyStats, toStrategyStats } from "../../models/Metric";
import { AdvertisingStatsGridComponent } from "./advertising-stats-grid.component";

export const CAMPAIGN_TYPE_NAME = {
  [CampaignType.SP]: "v2-sidebar.sponsored_products",
  [CampaignType.SB]: "v2-sidebar.sponsored_brand",
  [CampaignType.SD]: "v2-sidebar.sponsored_display",
  [CampaignType.SDR]: "hourly-table.sponsored_display_remarketing",
};

export const CAMPAIGN_TYPE_COLOR = {
  [CampaignType.SP]: PALETTE[0],
  [CampaignType.SB]: PALETTE[1],
  [CampaignType.SD]: PALETTE[3],
  [CampaignType.SDR]: PALETTE[4],
};

export const CAMPAIGN_TYPE_BADGE_COLOR = {
  [CampaignType.SP]: "black",
  [CampaignType.SB]: "green",
  [CampaignType.SD]: "orange",
};

@UntilDestroy()
@Component({
  templateUrl: "./advertising-stats.component.html",
})
export class AdvertisingStatsComponent implements OnInit {
  readonly RATIO = MetricType.RATIO;
  readonly STRATEGY_GRID_KEY = "overviewGrid";

  readonly localStorageKey = MetricsSelectorLocalStorageKey.overview;
  readonly CampaignTypes = [CampaignType.SP, CampaignType.SB, CampaignType.SD, CampaignType.SDR];
  readonly SwitchButtonType = SwitchButtonType;
  readonly chartDisplayModeOptions: ("lineChart" | "donuts")[] = ["donuts", "lineChart"];
  readonly ICON_CLOSE = ICON_CLOSE;
  readonly ICON_CHART_LINE = ICON_CHART_LINE;
  chartDisplayMode: "lineChart" | "donuts";
  dateAggregation$: BehaviorSubject<DateAggregation> = new BehaviorSubject<DateAggregation>(DateAggregation.daily);
  displayEventAnnotation$ = new BehaviorSubject(false);
  disableEventAnnotation = false;
  allEventAnnotationTypes: Option<ActivityEventType>[] = this.activityService.allActivityEventTypesOptions;
  allUsers$: Observable<Option<string>[]> = this.activityService.allUsersOptions$;
  allStrategies$: Observable<Option<StrategyEx>[]> = this.activityService.allStrategiesOptions$;

  @ViewChild("strategyChart")
  strategyChart!: BaseChartDirective;

  @ViewChild(AdvertisingStatsGridComponent) overviewGrid!: AdvertisingStatsGridComponent;

  accountMarketplace?: AccountMarketplace;

  selectedMetric$: BehaviorSubject<Metric<AdStatsEx>[]>;
  selectedMetrics: Metric<StrategyStats>[] = [];
  mainMetric!: Metric<AdStatsEx>;
  globalData: AdStatsEx = {};
  previousPeriodGlobalData: AdStatsEx = {};

  chartDisplayed = true;

  /** CampaignType pieChart data **/
  campaignTypeDonutDataSet = new DonutDataSet<AdStatsEx>(
    addAdStats,
    (x) => ({
      // merge SD and SDR in the donut chart
      key: x.campaignType === CampaignType.SDR ? CampaignType.SD : x.campaignType!,
      label: this.translocoService.translate(CAMPAIGN_TYPE_NAME[x.campaignType!]),
      color: CAMPAIGN_TYPE_COLOR[x.campaignType!],
    }),
    (donutChartOptions: ChartOptions) => {
      if (donutChartOptions.plugins?.legend) donutChartOptions.plugins.legend.position = "bottom";
    },
    undefined,
    this.translocoService.translate("dsp-stats.dspCampaignDeliveryStatus_OTHER"),
  );

  /** Strategy pieChart data **/
  strategyDonutDataSet = new DonutDataSet<StrategyStats>(
    this.addStrategyStats,
    (x) => ({
      key: x.strategyName,
      label: x.strategyName,
    }),
    (donutChartOptions: ChartOptions) => {
      if (donutChartOptions.plugins?.legend) donutChartOptions.plugins.legend.position = "bottom";
    },
    8,
    this.translocoService.translate("overview.other_strategies"),
  );

  // line chart configuration
  globalDataset: DataSet<AdStatsEx>;

  readonly CHART_METRICS: Metric<StrategyStats>[] = [
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CLICK_THROUGH_RATE,
    CONVERSION_RATE,
    CPC,
    ROAS,
  ];

  constructor(
    private authService: AuthService,
    private userSelectionService: UserSelectionService,
    private accountSelectionService: AccountSelectionService,
    private activityService: ActivityService,
    private translocoService: TranslocoService,
    private strategyService: StrategyService,
    private statsService: StatsService,
  ) {
    this.selectedMetric$ = new BehaviorSubject<Metric<AdStatsEx>[]>([AD_SALES, COST]);
    this.globalDataset = new DataSet<AdStatsEx>(
      4,
      this.selectedMetric$.getValue(),
      [AggregationFunction.mergeSeveralDates],
      this.translocoService,
    );
    this.chartDisplayMode = this.userSelectionService.getUserOverviewChartTypePreference();
  }

  ngOnInit(): void {
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountMarketplace = am;
    });
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.campaignTypeDonutDataSet.locale = user.locale;
      this.strategyDonutDataSet.locale = user.locale;
      this.globalDataset.locale = user.locale;
    });
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency) => {
      this.campaignTypeDonutDataSet.currency = currency;
      this.strategyDonutDataSet.currency = currency;
      this.globalDataset.currency = currency;
    });

    const mainMetric$ = this.selectedMetric$.pipe(map((m) => (m.length === 1 ? m[0] : m[1])));
    mainMetric$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.mainMetric = x;
    });

    this.chartDisplayed = this.userSelectionService.getUserChartDisplayedPreference(this.localStorageKey);
    const dailyPlacementStats$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.userSelectionService.dateRange$,
    ]).pipe(
      switchMap(([am, dr]) =>
        combineLatest([
          this.userSelectionService.selectedCurrency$,
          this.statsService.getDailyPlacementStats(am.accountId, am.marketplace, dr[0], dr[1]),
        ]),
      ),
      map(([currency, data]) => convertToCurrency(data, currency)!),
      shareReplay(1),
    );
    const previousPeriodPlacementStats$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.userSelectionService.periodComparison$,
    ]).pipe(
      switchMap(([am, pc]) => {
        if (!pc?.period) {
          return of([]);
        }
        return combineLatest([
          this.userSelectionService.selectedCurrency$,
          this.statsService.getDailyPlacementStats(am.accountId, am.marketplace, pc.period[0], pc.period[1]),
        ]).pipe(map(([currency, data]) => convertToCurrency(data, currency)!));
      }),
      shareReplay(1),
    );

    const campaignTypeStats$ = dailyPlacementStats$.pipe(
      map((data: AdStatsEx[]) => groupBy<CampaignType>(data, (x) => x.campaignType!, this.CampaignTypes)),
    );
    const previousPeriodCampaignTypeStats$: Observable<Map<CampaignType, AdStatsEx>> =
      previousPeriodPlacementStats$.pipe(
        map((data) => groupBy<CampaignType>(data, (x) => x.campaignType!, this.CampaignTypes)),
      );
    campaignTypeStats$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.globalData = {};
      for (const d of data.values()) mergeSeveralDates(this.globalData, d);
    });
    previousPeriodCampaignTypeStats$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.previousPeriodGlobalData = {};

      for (const d of data.values()) {
        if (Object.keys(d).length != 0) mergeSeveralDates(this.previousPeriodGlobalData, d);
      }
    });
    const strategyStats$ = dailyPlacementStats$.pipe(
      map((data) =>
        Array.from(groupBy<number>(data, (x) => x.strategyId!).values()).map((x) => {
          x.placement = undefined;
          return x;
        }),
      ),
    );

    const sortedStrategyStats$ = combineLatest([
      strategyStats$,
      this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
        switchMap((am) => this.strategyService.getStrategyIndex(am.accountId, am.marketplace)),
      ),
    ]).pipe(
      map(([data, strategyIndex]) => data.map((x) => toStrategyStats(x, strategyIndex))),
      share(),
    );

    combineLatest([dailyPlacementStats$, mainMetric$])
      .pipe(untilDestroyed(this))
      .subscribe(([data, metric]) => this.buildCampainTypeDataSet(data, metric));

    combineLatest([
      dailyPlacementStats$,
      this.userSelectionService.dateRange$, // TODO: not optimal to do this
      this.selectedMetric$,
      this.dateAggregation$,
      this.displayEventAnnotation$.pipe(
        switchMap((displayEventAnnotation) =>
          displayEventAnnotation ? this.activityService.activityEventsAnnotations$ : of([]),
        ),
      ),
      previousPeriodPlacementStats$,
      this.userSelectionService.periodComparison$, // TODO: not optimal to do this
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([data, dateRange, metrics, aggregation, activityEventsAnnotations, previousData, previousPeriod]) =>
        this.buildLineChartDataSet(
          { data, dateRange },
          metrics,
          aggregation,
          { data: previousData, dateRange: previousPeriod?.period ?? [] },
          activityEventsAnnotations,
        ),
      );

    combineLatest([sortedStrategyStats$, mainMetric$])
      .pipe(untilDestroyed(this))
      .subscribe(([data, metric]) => this.buildStrategyPieChartDataSet(data, metric));

    this.dateAggregation$.pipe(untilDestroyed(this)).subscribe((aggregation) => {
      this.disableEventAnnotation = aggregation !== DateAggregation.daily;
    });
  }

  buildCampainTypeDataSet(data: AdStatsEx[], metric: Metric<AdStatsEx>): void {
    this.campaignTypeDonutDataSet.buildDataSet(data, metric);
  }

  buildStrategyPieChartDataSet(data: StrategyStats[], metric: Metric<StrategyStats>): void {
    const sorted = [...data].sort((a, b) => metric.compare(a, b));

    this.strategyDonutDataSet.buildDataSet(sorted, metric);
  }

  buildLineChartDataSet(
    data: AdStatsData,
    metrics: Metric<AdStatsEx>[],
    aggregation: DateAggregation,
    previousData: AdStatsData,
    annotations: DataSetEventAnnotation[],
  ): void {
    this.globalDataset.buildDataSet(
      data.data,
      metrics,
      aggregation,
      {
        minDate: data.dateRange[0],
        maxDate: data.dateRange[1],
      },
      previousData?.dateRange?.length > 1
        ? {
            data: previousData.data,
            period: previousData.dateRange,
          }
        : undefined,
      annotations,
    );
  }

  addStrategyStats(stats1: StrategyStats, stats2: StrategyStats): StrategyStats {
    return {
      ...stats1,
      ...addAdStats(stats1, stats2),
    };
  }

  exportGridCsv(): void {
    const fileName = getCsvFileName(
      "strategy_stats",
      this.accountMarketplace?.accountGroupName,
      this.accountMarketplace?.marketplace,
      this.userSelectionService.getDateRangeStr(),
    );
    this.overviewGrid.exportGridCsv(fileName);
  }

  restoreDefaultColumns(): void {
    this.overviewGrid.restoreDefaultColumns();
  }

  selectMetrics(metrics: Metric<AdStatsEx>[]): void {
    this.selectedMetric$.next(metrics);
  }

  toggleChartDisplay(displayed: boolean): void {
    this.chartDisplayed = displayed;
    this.userSelectionService.setUserChartDisplayedPreference(this.localStorageKey, displayed);
  }

  toggleChartDisplayMode(chartDisplayMode: "lineChart" | "donuts"): void {
    this.chartDisplayMode = chartDisplayMode;
    this.userSelectionService.setUserOverviewChartTypePreference(chartDisplayMode);
  }

  selectAggregation(aggregation: DateAggregation): void {
    this.dateAggregation$.next(aggregation);
  }

  toggleEventAnnotation(change: MatSlideToggleChange): void {
    this.displayEventAnnotation$.next(change.checked);
  }
}
